import { Button, Dialog, Stack, Typography } from "@mui/material";
import { Backdrop } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { LabelDropdownTextField } from "../fields/LabelDropdownTextField";
import { LabelTextField } from "../fields/LabelTextField";
import { useAPI } from "../../hooks";
import { useToast } from "../../context";

export const EditUserAlert = (props) => {
  const { open, data, onClose, userUpdated } = props;

  const { api, urls, isLoading } = useAPI();
  const { showToast } = useToast();

  const [menuItems, setMenuItems] = useState([]);
  const [formData, setFormData] = useState({
    id: data.id,
    role_id: data.role.uuid,
    name: data.first_name,
    email: data.email,
    is_active: data.is_active,
    errors: { role_id: false, name: false, email: false },
  });

  const getRoleList = async () => {
    api
      .get(urls.roleList)
      .then((res) => {
        setMenuItems(res.data.data.data);
      })
      .catch((err) => {
        showToast(err.response?.data?.data?.message, "error");
      });
  };

  useEffect(() => {
    if (open) {
      getRoleList();
    }
  }, [open]);

  const handleSaveData = () => {
    let invalid = false;

    if (!formData.role_id) {
      setFormData((prev) => {
        const curr = { ...prev };
        prev.errors.role_id = true;
        return curr;
      });
      invalid = true;
    }

    if (!formData.name) {
      setFormData((prev) => {
        const curr = { ...prev };
        prev.errors.name = true;
        return curr;
      });
      invalid = true;
    }

    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      setFormData((prev) => {
        const curr = { ...prev };
        curr.errors.email = true; // Use curr instead of prev to update the state correctly
        return curr;
      });
      invalid = true;
    }

    if (!invalid) {
      const params = { role: formData.role_id, name: formData.name };

      api
        .put(`${urls.activateDeactivateUser}/${formData.id}`, params)
        .then((res) => {
          showToast("User updated successfully", "success");
          userUpdated();
        })
        .catch((err) => {
          showToast(err.response?.data?.message, "error");
        });
    }
  };

  const handleClose = () => {
    setFormData({
      role_id: "",
      name: "",
      email: "",
      errors: { role_id: false, name: false, email: false },
    });
    onClose();
  };

  const updateFormData = (key, value) => {
    setFormData((prev) => {
      const curr = { ...prev };
      curr[key] = value;
      curr.errors[key] = false;
      return curr;
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Stack
        direction={"column"}
        sx={{ width: 400, pt: 3, pl: 4, pr: 4, pb: 4, gap: 3 }}
      >
        <Typography
          variant="h1"
          sx={{
            mb: 2,
            textAlign: "center",
          }}
        >
          Edit User
        </Typography>
        <LabelDropdownTextField
          label={"Role"}
          textInput={formData.role_id}
          onTextChange={(event) => {
            updateFormData("role_id", event.target.value);
          }}
          menuItems={menuItems}
          error={formData.errors.role_id}
          helperText={formData.errors.role_id ? "Please select a role" : null}
        />
        <LabelTextField
          label={"Name"}
          textInput={formData.name}
          onTextChange={(event) => {
            updateFormData("name", event.target.value);
          }}
          error={formData.errors.name}
          helperText={formData.errors.name ? "Please enter your name" : null}
        />
        <LabelTextField
          label={"Email"}
          textInput={formData.email}
          onTextChange={(event) => {
            updateFormData("email", event.target.value);
          }}
          error={formData.errors.email}
          helperText={
            formData.errors.email ? "Please enter a valid email" : null
          }
          disabled
        />
        <Button
          variant="contained"
          disableElevation
          color="theme"
          onClick={handleSaveData}
          sx={{
            height: 50,
            fontSize: 16,
            fontWeight: 700,
            borderRadius: 2,
            textTransform: "none",
          }}
          disabled={isLoading}
        >
          Update
        </Button>
      </Stack>
    </Dialog>
  );
};

EditUserAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userUpdated: PropTypes.func.isRequired,
};
