import { Grid } from "@mui/material";
import { DeviceDetails } from "./DeviceDetails";
import { AccountDetails } from "./AccountDetails";
import { InfoImage } from "./InfoImage";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAPI } from "../../../hooks";
import { useToast, useAuth } from "../../../context";
import LoaderCard from "../../../components/cards/LoaderCard";
import NoDataCard from "../../../components/cards/NoDataCard";
import { useNavigate } from "react-router-dom";

export const UserDetailsTab = (props) => {
  const { id, onSetUserDetails } = props;
  const navigate = useNavigate();
  const { uid } = useParams();
  const { showToast } = useToast();
  const { logout } = useAuth();
  const { api, urls, isLoading } = useAPI();
  const [showLoader, setShowLoader] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [deviceDetails, setDeviceDetails] = useState([
    { title: "Device Model", value: "" },
    { title: "Device Brand", value: "" },
    { title: "Device Status", value: "" },
    { title: "ID", value: "" },
    { title: "UUID", value: "" },
    { title: "Device Binding Date", value: "" },
    { title: "Device OS", value: "" },
    { title: "Device OS Version", value: "" },
    { title: "Device App Version", value: "" },
  ]);
  const [userImages, setUserImages] = useState({
    userImage: "",
    idImage1: "",
    idImage2: "",
  });

  const getFirstDocUrl = (data, type) => {
    const item = data.find((item) => item.doc_type === type);
    return item ? item.doc_url : null;
  };

  const convertUTCtoLocalDateTime = (utcDateTimeString) => {
    const utcDateTime = new Date(utcDateTimeString);
    return utcDateTime.toLocaleString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: true, // specify AM/PM
    });
  };

  const getUserDetails = async (loader) => {
    if (loader) {
      setShowLoader(true);
    }
    api
      .get(`${urls.customerDetails}${uid}/`)
      .then((res) => {
        let data = res.data.data;
        let userFiles = data.user_files?.new_data;
        if (userFiles) {
          const frontImageUrl = getFirstDocUrl(userFiles, "front_image");
          const backImageUrl = getFirstDocUrl(userFiles, "back_image");
          setUserImages({
            userImage: data.image_url,
            idImage1: frontImageUrl,
            idImage2: backImageUrl,
          });
        }

        let deviceData = data.device;
        setDeviceDetails([
          { title: "Device Model", value: deviceData.device_model },
          { title: "Device Brand", value: deviceData.device_brand },
          { title: "Device Status", value: deviceData.device_status },
          { title: "ID", value: deviceData.iden_key_id },
          { title: "UUID", value: deviceData.iden_key_user_id },
          {
            title: "Device Binding Date",
            value: deviceData.created_at
              ? convertUTCtoLocalDateTime(deviceData.created_at)
              : "",
          },
          { title: "Device OS", value: deviceData.device_os },
          { title: "Device OS Version", value: deviceData.device_os_version },
          { title: "Device App Version", value: deviceData.device_app_version },
        ]);

        setUserDetails(data);
        onSetUserDetails(data);
        if (loader) {
          setShowLoader(false);
        }
      })

      .catch(handleApiError);
  };

  const onConfirmSendVerification = () => {
    api
      .put(urls.unVerifiyCustomer, { uuid: uid })
      .then((res) => {
        showToast("Customer Unverified", "success");
        getUserDetails(true);
      })
      .catch(handleApiError);
  };

  const onConfirmUserDelete = () => {
    api
      .post(urls.deleteCustomer, { customer_id: uid })
      .then((res) => {
        showToast("Customer Deleted", "success");
        navigate(-1);
      })
      .catch(handleApiError);
  };

  //error handling
  const handleApiError = (err) => {
    if (err.response?.status === 401) {
      logout();
    } else {
      console.log("err", err);
      showToast(err.response?.data?.message, "error");
    }
    setShowLoader(false);
  };

  useEffect(() => {
    if (uid) {
      getUserDetails(true);
    }
  }, []);

  return (
    <Grid container direction={"row"} sx={{ gap: 2 }}>
      {showLoader && (
        <Grid item xs={12}>
          <LoaderCard />
        </Grid>
      )}
      {userDetails && !showLoader && (
        <Grid item xs={2}>
          <InfoImage
            profileImage={userImages.userImage ?? ""}
            IdImage1={userImages.idImage1}
            IdImage2={userImages.idImage2}
          />
        </Grid>
      )}
      {userDetails && !showLoader && (
        <Grid item xs={6}>
          <AccountDetails
            title="ACCOUNT DETAILS"
            data={userDetails}
            onConfirmSendVerification={onConfirmSendVerification}
            onConfirmUserDelete={onConfirmUserDelete}
            isDeletedUser={false}
          />
        </Grid>
      )}
      {userDetails && !showLoader && (
        <Grid item xs>
          <DeviceDetails
            pageTitle={"Device Binding Details"}
            data={deviceDetails}
          />
        </Grid>
      )}
      {userDetails === null && !showLoader && (
        <Grid item xs={12}>
          <NoDataCard title={"No user found"} />
        </Grid>
      )}
    </Grid>
  );
};
