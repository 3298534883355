import { Divider, Stack, Typography, useTheme } from "@mui/material";
import { LabelPriceTextField } from "../fields/LabelPriceTextField";
import { LabelCheckbox } from "../checkbox/LabelCheckbox";
import { useState } from "react";

export const AuthRiskCard = (props) => {
  const {
    priceLimit,
    riskType,
    symbol,
    settingsData,
    isEditMode,
    checkBoxUpdated,
    inputUpdated,
    index,
  } = props;

  const theme = useTheme();
  return (
    <Stack
      direction={"column"}
      sx={{
        gap: 2,
        bgcolor: theme.palette.popupBlue.main,
        borderRadius: 3,
        pt: 2,
        pb: 2,
      }}
    >
      <Stack direction={"row"} sx={{ gap: "4px", pl: 3, pr: 3 }}>
        <Typography variant="c2">{priceLimit}</Typography>
        <Typography
          variant="c3"
          sx={{ color: theme.palette.secondary.main }}
        >{`(${riskType})`}</Typography>
      </Stack>
      <Divider />
      <Stack direction={"row"}>
        <Stack direction={"row"} sx={{ width: "100%", pl: 3, pr: 3, gap: 3 }}>
          <LabelPriceTextField
            label="Amount From"
            symbol={symbol}
            textInput={settingsData?.min_amount ?? ""}
            onTextChange={(e) => {
              // inputUpdated("min", e.target.value);

              const inputValue = e.target.value;
              // Regex to allow only numbers with up to two decimal places
              const regex = /^\d*\.?\d{0,2}$/;
              // Check if the input matches the regex pattern
              if (inputValue === "" || regex.test(inputValue)) {
                inputUpdated("min", inputValue);
              }
            }}
            disabled={true}
            error={settingsData?.min_amount === ""}
            errorMessage={"Please input valid Minimum amount"}
          />
          <LabelPriceTextField
            label="Amount Up To"
            symbol={symbol}
            textInput={index === 3 ? "∞" : settingsData?.max_amount ?? ""}
            onTextChange={(e) => {
              // inputUpdated("max", e.target.value);

              const inputValue = e.target.value;
              // Regex to allow only numbers with up to two decimal places
              const regex = /^\d*\.?\d{0,2}$/;
              // Check if the input matches the regex pattern
              if (inputValue === "" || regex.test(inputValue)) {
                inputUpdated("max", inputValue);
              }
            }}
            disabled={!isEditMode || index === 3}
            error={
              settingsData?.max_amount === "" ||
              (index !== 3 &&
                +settingsData?.max_amount < +settingsData?.min_amount)
            }
            errorMessage={"Please input valid Maximum amount"}
          />
        </Stack>
        <Divider orientation="vertical" flexItem sx={{ mt: 1, mb: 1 }} />
        <Stack direction={"row"} sx={{ width: "100%", pl: 3, pr: 3, gap: 1 }}>
          <LabelCheckbox
            label="Pin"
            checked={settingsData?.user_pin ?? false}
            // onChange={() => setPinIsChecked(!pinIsChecked)}
            onChange={() => checkBoxUpdated("pin")}
            disabled={!isEditMode}
          />
          <Divider
            orientation="vertical"
            flexItem
            sx={{ mt: 2, mr: 1, mb: 2 }}
          />
          <LabelCheckbox
            label="Pattern"
            checked={settingsData?.user_pattern ?? false}
            // onChange={() => setPatternIsChecked(!patternIsChecked)}
            onChange={() => checkBoxUpdated("pattern")}
            disabled={!isEditMode}
          />
          <Divider
            orientation="vertical"
            flexItem
            sx={{ mt: 2, mr: 1, mb: 2 }}
          />
          <LabelCheckbox
            label="Biometric"
            checked={settingsData?.user_biometric ?? false}
            // onChange={() => setBiometricIsChecked(!biometricIsChecked)}
            onChange={() => checkBoxUpdated("biometric")}
            disabled={!isEditMode}
          />
          <Divider
            orientation="vertical"
            flexItem
            sx={{ mt: 2, mr: 1, mb: 2 }}
          />
          <LabelCheckbox
            label="Face Regonition"
            checked={settingsData?.user_face ?? false}
            // onChange={() =>
            //   setFaceRecognitionIsChecked(!faceRecognitionIsChecked)
            // }
            onChange={() => checkBoxUpdated("face")}
            disabled={!isEditMode}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
