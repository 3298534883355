import { Typography } from "@mui/material";

export const DynamicKeys = ({ jsonObject }) => {
  const renderValue = (value) => {
    if (Array.isArray(value)) {
      return (
        <ul>
          {value.map((item, index) => (
            <li key={index}>{renderValue(item)}</li>
          ))}
        </ul>
      );
    } else if (typeof value === "object" && value !== null) {
      return (
        <div style={{ marginLeft: 20 }}>
          {Object.keys(value).map((subKey) => (
            <div key={subKey}>
              <Typography
                variant="subtitle2"
                style={{ display: "inline-block", marginRight: 5 }}
              >
                {subKey.replace("_", " ")}:{" "}
              </Typography>
              {renderValue(value[subKey])}
            </div>
          ))}
        </div>
      );
    } else {
      return <Typography variant="p2">{value}</Typography>;
    }
  };

  return (
    <div>
      {Object.keys(jsonObject).map((key) => (
        <div key={key}>
          <Typography variant="h2" sx={{ marginTop: 1, marginBottom: 1 }}>
            {key.replace("_", " ")}
          </Typography>
          {renderValue(jsonObject[key])}
        </div>
      ))}
    </div>
  );
};
