import { Fragment, useState, useEffect } from "react";
import { ActionBar } from "../../components/headers/ActionBar";
import { PageStackLayout } from "../../components/layout/PageStackLayout";
import { InfoTab } from "../../components/tabs/InfoTab";
import { PageDetailsTab } from "./PageDetails/PageDetailsTab";
import { AddPageAlert } from "../../components/alert/AddPageAlert";
import { useAPI, useModal } from "../../hooks";
import { useToast, useAuth } from "../../context";
import { AddPageModal } from "../../components/modals/AddPageModal";
import { Typography } from "@mui/material";
import { getEditPermissionStatus } from "../../utilities/get-permission";

export const DigitalOnboardingSettingsScreen = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { showToast } = useToast();
  const { adminRole, isAdmin } = useAuth();
  const { api, urls, isLoading } = useAPI();
  const [dynamicForms, setDynamicForms] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [selFormID, setSelFormID] = useState(null);
  const [selFormTitle, setSelFormTitle] = useState(null);
  const [isOpenAddModal, openAddModal, closeAddModal] = useModal();

  const getDynamicForms = () => {
    api
      .get(urls.dynamicFormsList)
      .then((res) => {
        setDynamicForms(res.data.data);
        let list = res.data.data.map((x) => ({
          id: x.id,
          title: x.page_title,
        }));
        setTabs(list);
        if (list.length > 0) {
          if (list.length > selectedTab) {
            setSelFormID(list[selectedTab].id);
            setSelFormTitle(list[selectedTab].title);
          } else {
            setSelFormID(list[0].id);
            setSelFormTitle(list[0].title);
            setSelectedTab(0);
          }
        }
      })
      .catch((err) => {
        showToast(err.response?.data?.data?.message, "error");
      });
  };

  useEffect(() => {
    getDynamicForms();
  }, []);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    setSelFormID(tabs[tab].id);
    setSelFormTitle(tabs[tab].title);
  };

  return (
    <Fragment>
      <ActionBar header={"Digital Onboarding Settings"} />
      <PageStackLayout>
        <Typography variant="h1">Digital Onboarding Settings</Typography>
        <InfoTab
          selectedTab={selectedTab}
          onSetSelectedTab={handleTabChange}
          showAddPage={
            isAdmin ||
            getEditPermissionStatus(
              adminRole.permissions,
              "Digital Onboarding Settings"
            )
          }
          onAddPage={openAddModal}
          tabs={tabs}
        />
        {selFormID && (
          <PageDetailsTab
            formID={selFormID}
            editAllowed={
              isAdmin ||
              getEditPermissionStatus(
                adminRole.permissions,
                "Digital Onboarding Settings"
              )
            }
            formTitle={selFormTitle}
            onTitleChange={(data) => {
              getDynamicForms();
            }}
          />
        )}
      </PageStackLayout>
      <AddPageModal
        open={isOpenAddModal}
        onClose={closeAddModal}
        onSubmit={() => {
          closeAddModal();
          getDynamicForms();
        }}
      />
    </Fragment>
  );
};
