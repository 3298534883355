import {
  Typography,
  Stack,
  Table,
  Box,
  Link,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
  useMediaQuery,
} from "@mui/material";

export const DynamicKeyTableWithTitle = ({ title, jsonObject }) => {
  // Rename prop to 'data'
  const isMobile = useMediaQuery("(max-width: 600px)"); // Mobile breakpoint
  const theme = useTheme();

  const renderValue = (value) => {
    if (Array.isArray(value)) {
      return (
        <TableCell align="left">
          <Stack direction={"column"} spacing={1}>
            {value.map((item, index) => (
              <Typography key={index} variant="p1" alignItems={"left"}>
                {item}
              </Typography>
            ))}
          </Stack>
        </TableCell>
      );
    } else if (typeof value === "object" && value !== null) {
      return (
        <TableBody>
          {Object.keys(value).map((subKey) => (
            <TableRow key={subKey}>
              <TableCell>
                <Typography
                  variant="subtitle2"
                  style={{ display: "inline-block", marginRight: 5 }}
                >
                  {subKey.replaceAll("_", " ")}:{" "}
                </Typography>
              </TableCell>
              <TableCell>{renderValue(value[subKey])}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      );
    } else {
      return (
        <Stack direction={"column"} spacing={1}>
          {isWebLink(value) ? (
            <Link
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              underline="always"
              sx={{ fontSize: 16 }}
            >
              {value}
            </Link>
          ) : (
            <Typography variant="p1" alignItems={"left"}>
              {value}
            </Typography>
          )}
        </Stack>
      );
    }
  };

  const isWebLink = (value) => {
    return typeof value === "string" && value.startsWith("http");
  };

  // Extract table headers from the first object in jsonObject (assuming consistent structure)
  const tableHeaders = jsonObject.length > 0 ? Object.keys(jsonObject[0]) : []; // Handle empty data gracefully

  return (
    <Stack
      direction={"column"}
      spacing={2}
      mt={2}
      sx={{
        p: 2,
        bgcolor: theme.palette.common.white,
        borderRadius: 2,
      }}
    >
      {title && (
        <Box sx={{ bgcolor: "#e9f1fd", borderRadius: 2 }}>
          <Typography
            sx={{
              pl: 2,
              py: 1,
              fontWeight: 700,
              fontSize: 22,
              color: theme.palette.badgeTheme.main,
            }}
          >
            {title}
          </Typography>
        </Box>
      )}
      <Table style={{ width: "100%" }}>
        {/* Table header row */}
        <TableRow>
          {tableHeaders.map((header) => (
            <TableCell key={header}>
              <Typography variant="h2">
                {header.replaceAll("_", " ") === "no"
                  ? "#"
                  : header.replaceAll("_", " ")}
              </Typography>
            </TableCell>
          ))}
        </TableRow>

        {/* Table body rows */}
        {jsonObject.map((row) => (
          <TableRow key={row.no ? row.no : Math.random()}>
            {tableHeaders.map((header) => (
              <TableCell key={header}>{renderValue(row[header])}</TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
    </Stack>
  );
};
