import { Fragment } from "react";
import PropTypes from "prop-types";
import {
  InfoCircle,
  Profile2User,
  UserAdd,
  Logout,
  Home,
  Note,
  KeySquare,
  ShieldCross,
  DocumentCloud,
  TableDocument,
  MoneySend,
  SecurityUser,
  TagUser,
  DollarCircle,
} from "iconsax-react";
import { useTheme } from "@mui/material";

export const SideMenuIcon = (props) => {
  const { type, selected } = props;

  const theme = useTheme();

  const getIcon = () => {
    switch (type) {
      case 1:
        return (
          <Home
            variant={selected ? "Bold" : "Outline"}
            size={30}
            color={
              selected
                ? theme.palette.common.white
                : theme.palette.sideMenuGreen.main
            }
          />
        );
      case 2:
        return (
          <Note
            variant={selected ? "Bold" : "Outline"}
            size={30}
            color={
              selected
                ? theme.palette.common.white
                : theme.palette.sideMenuGreen.main
            }
          />
        );
      case 3:
        return (
          <KeySquare
            variant={selected ? "Bold" : "Outline"}
            size={30}
            color={
              selected
                ? theme.palette.common.white
                : theme.palette.sideMenuGreen.main
            }
          />
        );
      case 4:
        return (
          <ShieldCross
            variant={selected ? "Bold" : "Outline"}
            size={30}
            color={
              selected
                ? theme.palette.common.white
                : theme.palette.sideMenuGreen.main
            }
          />
        );
      case 5:
        return (
          <DocumentCloud
            variant={selected ? "Bold" : "Outline"}
            size={30}
            color={
              selected
                ? theme.palette.common.white
                : theme.palette.sideMenuGreen.main
            }
          />
        );
      case 6:
        return (
          <UserAdd
            variant={selected ? "Bold" : "Outline"}
            size={30}
            color={
              selected
                ? theme.palette.common.white
                : theme.palette.sideMenuGreen.main
            }
          />
        );
      case 7:
        return (
          <TableDocument
            variant={selected ? "Bold" : "Outline"}
            size={30}
            color={
              selected
                ? theme.palette.common.white
                : theme.palette.sideMenuGreen.main
            }
          />
        );
      case 8:
        return (
          <MoneySend
            variant={selected ? "Bold" : "Outline"}
            size={30}
            color={
              selected
                ? theme.palette.common.white
                : theme.palette.sideMenuGreen.main
            }
          />
        );
      case 9:
        return (
          <Profile2User
            variant={selected ? "Bold" : "Outline"}
            size={30}
            color={
              selected
                ? theme.palette.common.white
                : theme.palette.sideMenuGreen.main
            }
          />
        );
      case 10:
        return (
          <SecurityUser
            variant={selected ? "Bold" : "Outline"}
            size={30}
            color={
              selected
                ? theme.palette.common.white
                : theme.palette.sideMenuGreen.main
            }
          />
        );
      case 11:
        return (
          <TagUser
            variant={selected ? "Bold" : "Outline"}
            size={30}
            color={
              selected
                ? theme.palette.common.white
                : theme.palette.sideMenuGreen.main
            }
          />
        );
      case 12:
        return (
          <DollarCircle
            variant={selected ? "Bold" : "Outline"}
            size={30}
            color={
              selected
                ? theme.palette.common.white
                : theme.palette.sideMenuGreen.main
            }
          />
        );
      case 13:
        return (
          <Logout
            variant={selected ? "Bold" : "Outline"}
            size={30}
            color={
              selected
                ? theme.palette.common.white
                : theme.palette.sideMenuGreen.main
            }
          />
        );
      default:
        return (
          <InfoCircle
            variant={selected ? "Bold" : "Outline"}
            size={30}
            color={
              selected
                ? theme.palette.common.white
                : theme.palette.sideMenuGreen.main
            }
          />
        );
    }
  };

  return <Fragment>{getIcon()}</Fragment>;
};

SideMenuIcon.propTypes = {
  type: PropTypes.number,
  selected: PropTypes.bool,
};
