import { Backdrop, Button, Dialog, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

export const DeleteAdminErrorAlert = (props) => {
  const { open, onClose } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Stack
        direction={"column"}
        sx={{ width: 400, pt: 3, pl: 4, pr: 4, pb: 4, gap: 3 }}
      >
        <Typography
          variant="h1"
          sx={{
            textAlign: "center",
          }}
        >
          Deletion Error
        </Typography>
        <Typography
          variant="p1"
          sx={{
            textAlign: "center",
          }}
        >
          Sorry, This role can not be deleted since it's being assigned to one
          or more admins.
        </Typography>
        <Stack direction={"row"} sx={{ gap: 2 }}>
          <Button
            onClick={() => onClose()}
            variant="contained"
            disableElevation
            color={"theme"}
            sx={{
              width: "100%",
              height: 50,
              fontSize: 16,
              fontWeight: 700,
              textTransform: "none",
              borderRadius: 2,
            }}
          >
            OK
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

DeleteAdminErrorAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
