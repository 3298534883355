import { Stack, Typography, useTheme } from "@mui/material";
import { TickCircle } from "iconsax-react";
import PropTypes from "prop-types";

export const PasswordValidationCard = (props) => {
  const { description, selected } = props;

  const theme = useTheme();

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      sx={{ gap: "12px", pt: "2px", pb: "2px" }}
    >
      <TickCircle
        size="18"
        color={
          selected ? theme.palette.baseError.main : theme.palette.secondary.main
        }
        variant="Bold"
      />
      <Typography
        variant="p2"
        sx={{
          color: selected
            ? theme.palette.baseError.main
            : theme.palette.secondary.main,
        }}
      >
        {description}
      </Typography>
    </Stack>
  );
};

PasswordValidationCard.propTypes = {
  description: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
};
