export const getViewPermissionStatus = (rolesArray, text) => {
  const roleItems = rolesArray?.filter((role) => role.title === text);
  if (roleItems?.length > 0) {
    return roleItems[0].view === 1;
  } else {
    return false;
  }
};

export const getEditPermissionStatus = (rolesArray, text) => {
  const roleItems = rolesArray?.filter((role) => role.title === text);
  if (roleItems?.length > 0) {
    return roleItems[0].change === 1;
  } else {
    return false;
  }
};
