import { Fragment, useState, useEffect } from "react";
import { Grid, Button, Stack, Box, Typography, useTheme } from "@mui/material";
import { ActionBar } from "../../components/headers/ActionBar";
import { PageStackLayout } from "../../components/layout/PageStackLayout";
import { MemberDetailsTab } from "../../components/tabs/MemberDetailsTab";
import LoaderCard from "../../components/cards/LoaderCard";
import NoDataCard from "../../components/cards/NoDataCard";

import { useParams } from "react-router-dom";
import { useAPI } from "../../hooks";
import { useToast, useAuth } from "../../context";
import { ReviewCard } from "../../components/cards/ReviewCard";
import { userPl } from "../../assets";
import ZoomableImage from "../../components/image-viewer/ZoomableImage";
import ApproveRejectPopup from "./UserDetails/ApproveRejectPopup";
import { UnverifiedAccountDetails } from "./UserDetails/UnverifiedAccountDetails";
import { getEditPermissionStatus } from "../../utilities/get-permission";

export const DeletedUserUnverifiedMemberDetailsScreen = () => {
  const theme = useTheme();
  const { uid } = useParams();
  const { showToast } = useToast();
  const { logout, adminRole } = useAuth();
  const { api, urls, isLoading } = useAPI();
  const [selectedTab, setSelectedTab] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isApprove, setIsApprove] = useState(null);
  const [userOldDetails, setUserOldDetails] = useState(null);
  const [userNewDetails, setUserNewDetails] = useState(null);
  const [userImagesOld, setUserImagesOld] = useState({
    userImage: "",
    idImage1: "",
    idImage2: "",
  });
  const [userImagesNew, setUserImagesNew] = useState({
    userImage: "",
    idImage1: "",
    idImage2: "",
  });
  const [editorReview, setEditorReview] = useState(null);
  const [managerReview, setManagerReview] = useState(null);
  const [apiCalling, setApiCalling] = useState(false);

  const onClickApprove = () => {
    setIsApprove(true);
    setShowConfirmation(true);
  };
  const onClickReject = () => {
    setIsApprove(false);
    setShowConfirmation(true);
  };

  const getFirstDocUrl = (data, type) => {
    const item = data.find((item) => item.doc_type === type);
    return item ? item.doc_url : null;
  };

  const getUserDetails = async () => {
    setApiCalling(true);
    api
      .get(`${urls.deletedCustomerUnverifiedRequestDetails}${uid}`)
      .then((res) => {
        setApiCalling(false);
        let data = res.data.data;
        let userFilesNew = data.new_doc;
        let userFilesOld = data.old_doc;
        if (userFilesNew) {
          const frontImageUrl = getFirstDocUrl(userFilesNew, "front_image");
          const backImageUrl = getFirstDocUrl(userFilesNew, "back_image");
          const newSelfieUrl = getFirstDocUrl(userFilesNew, "profile_image");
          setUserImagesNew({
            userImage: newSelfieUrl,
            idImage1: frontImageUrl,
            idImage2: backImageUrl,
          });
        }
        if (userFilesOld) {
          const frontImageUrl = getFirstDocUrl(userFilesOld, "front_image");
          const backImageUrl = getFirstDocUrl(userFilesOld, "back_image");
          const oldSelfieUrl = getFirstDocUrl(userFilesOld, "profile_image");
          setUserImagesOld({
            userImage: oldSelfieUrl,
            idImage1: frontImageUrl,
            idImage2: backImageUrl,
          });
        }
        setUserNewDetails(data.new_value);
        setUserOldDetails(data.old_value);
        setEditorReview(data.editor_review);
        setManagerReview(data.manager_review);
      })

      .catch(handleApiError);
  };

  const saveVerifyStatus = (params) => {
    api
      .post(`${urls.verifyCustomerRequest}${uid}/`, params)
      .then((res) => {
        showToast(res.data.message, "success");
        setShowConfirmation(false);
        getUserDetails();
      })
      .catch(handleApiError);
  };

  const onConfirmApprove = (file, text) => {
    if (file) {
      //upload file
      const formData = new FormData();
      formData.append("file", file);
      api
        .post(`${urls.upload}`, formData)
        .then((res) => {
          let url = res.data.data;
          let params = {
            status: "Approved",
            file: url,
          };
          if (text.trim() !== "") {
            params.description = text;
          }

          saveVerifyStatus(params);
        })
        .catch(handleApiError);
    } else {
      let params = {
        status: "Approved",
      };
      if (text.trim() !== "") {
        params.description = text;
      }
      saveVerifyStatus(params);
    }
  };

  const onConfirmReject = (file, text) => {
    if (file) {
      //upload file
      const formData = new FormData();
      formData.append("file", file);
      api
        .post(`${urls.upload}`, formData)
        .then((res) => {
          let url = res.data.data;
          let params = {
            status: "Rejected",
            file: url,
          };
          if (text.trim() !== "") {
            params.description = text;
          }
          saveVerifyStatus(params);
        })
        .catch(handleApiError);
    } else {
      let params = {
        status: "Rejected",
      };
      if (text.trim() !== "") {
        params.description = text;
      }
      saveVerifyStatus(params);
    }
  };

  //error handling
  const handleApiError = (err) => {
    if (err.response?.status === 401) {
      logout();
    } else {
      console.log("err", err);
      setApiCalling(false);
      showToast(err.response?.data?.message, "error");
    }
  };

  useEffect(() => {
    if (uid) {
      getUserDetails();
    }
  }, []);

  return (
    <Fragment>
      <ActionBar header={"Customer Details"} showBackButton={true} />
      <PageStackLayout>
        <Typography variant="h1">Customer Details</Typography>
        <MemberDetailsTab
          selectedTab={selectedTab}
          onSetSelectedTab={setSelectedTab}
          isUnverifiedMember={true}
        />
        <Grid container direction={"row"} spacing={2}>
          {apiCalling && (
            <Grid item xs={12}>
              <LoaderCard />
            </Grid>
          )}
          {!apiCalling && (
            <Grid item md={6} xs={12}>
              <UnverifiedAccountDetails
                data={userNewDetails}
                title="NEW ACCOUNT DETAILS"
              />
            </Grid>
          )}
          {!apiCalling && (
            <Grid item md={6} xs={12}>
              <UnverifiedAccountDetails
                data={userOldDetails}
                title="OLD ACCOUNT DETAILS"
              />
            </Grid>
          )}
          {!apiCalling && (
            <Grid item md={6} xs={12}>
              <Stack
                direction={"column"}
                sx={{
                  bgcolor: theme.palette.common.white,
                  borderRadius: 3,
                  p: 2,
                }}
                spacing={1}
              >
                <Typography
                  variant="h2"
                  sx={{ color: theme.palette.secondary.main }}
                >
                  NEW DOCUMENTS
                </Typography>
                <Grid container alignItems="stretch">
                  <Grid item md={4}>
                    <Box padding={2}>
                      <ZoomableImage
                        border={"4px"}
                        src={userImagesNew.userImage ?? ""}
                        customPlaceholder={userPl}
                      />
                    </Box>
                  </Grid>
                  {(userImagesNew.idImage1 ?? "") !== "" && (
                    <Grid item md={4}>
                      <Box padding={2}>
                        <ZoomableImage
                          border={"4px"}
                          src={userImagesNew.idImage1}
                        />
                      </Box>
                    </Grid>
                  )}
                  {(userImagesNew.idImage2 ?? "") !== "" && (
                    <Grid item md={4}>
                      <Box padding={2}>
                        <ZoomableImage
                          border={"4px"}
                          src={userImagesNew.idImage2}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Stack>
            </Grid>
          )}
          {!apiCalling && (
            <Grid item md={6} xs={12}>
              <Stack
                direction={"column"}
                sx={{
                  bgcolor: theme.palette.common.white,
                  borderRadius: 3,
                  p: 2,
                }}
                spacing={1}
              >
                <Typography
                  variant="h2"
                  sx={{ color: theme.palette.secondary.main }}
                >
                  OLD DOCUMENTS
                </Typography>
                <Grid container>
                  <Grid item md={4}>
                    <Box padding={2}>
                      <ZoomableImage
                        border={"4px"}
                        src={userImagesOld.userImage ?? ""}
                        customPlaceholder={userPl}
                      />
                    </Box>
                  </Grid>
                  {(userImagesOld.idImage1 ?? "") !== "" && (
                    <Grid item md={4}>
                      <Box padding={2}>
                        <ZoomableImage
                          border={"4px"}
                          src={userImagesOld.idImage1}
                        />
                      </Box>
                    </Grid>
                  )}
                  {(userImagesOld.idImage2 ?? "") !== "" && (
                    <Grid item md={4}>
                      <Box padding={2}>
                        <ZoomableImage
                          border={"4px"}
                          src={userImagesOld.idImage2}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Stack>
            </Grid>
          )}
          {!apiCalling && (
            <Grid item md={6} xs={12}>
              {(editorReview !== null || managerReview !== null) && (
                <Stack
                  direction={"column"}
                  sx={{
                    bgcolor: theme.palette.common.white,
                    borderRadius: 3,
                    pt: 1,
                    pl: 2,
                    pr: 2,
                    pb: 1,
                  }}
                >
                  {editorReview !== null && (
                    <ReviewCard
                      title={"Maker Review"}
                      reviewData={editorReview}
                      showDivider={managerReview !== null}
                    />
                  )}
                  {managerReview !== null && (
                    <ReviewCard
                      title={"Checker Review"}
                      reviewData={managerReview}
                      showDivider={false}
                    />
                  )}
                </Stack>
              )}
            </Grid>
          )}
          {userNewDetails === null &&
            userOldDetails === null &&
            !apiCalling && (
              <Grid item xs={12}>
                <NoDataCard title={"No user found"} />
              </Grid>
            )}
        </Grid>
      </PageStackLayout>
    </Fragment>
  );
};
