import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
} from "@mui/material";
import { PageFieldCard } from "../cards/PageFieldCard";
import { useAPI } from "../../hooks";
import { useToast } from "../../context";

export const AddPageFieldModal = (props) => {
  const { open, onClose, onSubmit, formID, field, isEdit } = props;
  const [formField, setFormField] = useState({
    type: "text",
    label: "",
    validation: {
      required: false,
      min_length: 20,
      max_length: 55,
    },
    status: true,
  });
  const { api, urls, isLoading } = useAPI();
  const { showToast } = useToast();

  useEffect(() => {
    if (isEdit) {
      setFormField({
        type: field?.type ?? "text",
        label: field?.label ?? "",
        validation: {
          required: field?.validation?.required ?? false,
          min_length: 20,
          max_length: 55,
        },
        status: field?.status ?? true,
      });
    }
  }, [field]);

  const handleSubmit = () => {
    let params = { ...formField };

    if (!isEdit) {
      api
        .post(urls.addFormField + formID, params)
        .then((res) => {
          showToast("New filed added", "success");
          onSubmit();
        })
        .catch((err) => {
          showToast(err.response?.data?.data?.message, "error");
        });
    } else {
      api
        .put(urls.editFormField + field.id + "/", params)
        .then((res) => {
          showToast("Field updated", "success");
          onSubmit();
        })
        .catch((err) => {
          showToast(err.response?.data?.data?.message, "error");
        });
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose} sx={{ p: 2 }}>
      <DialogTitle textAlign={"center"} variant="h1">
        {isEdit ? "Edit Field" : "Add Field"}
      </DialogTitle>
      <DialogContent sx={{ mt: 2, mb: 2 }}>
        <PageFieldCard
          field={formField}
          onFieldChange={(data) => setFormField(data)}
          isPopup={true}
          isEdit={isEdit}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Stack direction={"row"} sx={{ gap: 2, pb: 2, width: 450 }}>
          <Button
            onClick={onClose}
            variant="contained"
            color="lightTheme"
            sx={{
              width: "100%",
              height: 50,
              fontSize: 16,
              fontWeight: 700,
              textTransform: "none",
              borderRadius: 2,
            }}
            disableElevation
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            color="theme"
            sx={{
              width: "100%",
              height: 50,
              fontSize: 16,
              fontWeight: 700,
              textTransform: "none",
              borderRadius: 2,
            }}
            disabled={!formField.label.trim() || isLoading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

AddPageFieldModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  formID: PropTypes.string,
  field: PropTypes.object,
  isEdit: PropTypes.bool,
};
