export const isCharacterInRange = (str, min, max) => {
  const length = str.length;
  return length >= min && length <= max;
};

export const hasUpperAndLowerCase = (str) => {
  const hasUpperCase = /[A-Z]/.test(str);
  const hasLowerCase = /[a-z]/.test(str);
  return hasUpperCase && hasLowerCase;
};

export const hasNumber = (str) => {
  return /\d/.test(str);
};

export const hasSpecialCharacter = (str) => {
  return /[!@#$%^&*(),.?":{}|<>]/.test(str);
};

export const addCommasToNumber = (number) => {
  // Check if the input is a valid number
  if (isNaN(number)) {
    return "Invalid input";
  }

  // Convert number to locale string with commas
  return number.toLocaleString();
};

export const convertUtcToLocalTime = (
  utcDateString,
  format = "YYYY-MM-DD HH:mm:ss"
) => {
  const moment = require("moment");
  return moment.utc(utcDateString).local().format(format);
};

export const getCurrentDateTime = () => {
  const now = new Date();
  const formattedDate = `${now.getFullYear()}-${(now.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}`;
  const formattedTime = `${now.getHours().toString().padStart(2, "0")}:${now
    .getMinutes()
    .toString()
    .padStart(2, "0")}:${now.getSeconds().toString().padStart(2, "0")}`;
  return `${formattedDate} ${formattedTime}`;
};

export const getCurrentDateTimeInUTC = () => {
  const now = new Date();
  const formattedDate = `${now.getUTCFullYear()}-${(now.getUTCMonth() + 1)
    .toString()
    .padStart(2, "0")}-${now.getUTCDate().toString().padStart(2, "0")}`;
  const formattedTime = `${now.getUTCHours().toString().padStart(2, "0")}:${now
    .getUTCMinutes()
    .toString()
    .padStart(2, "0")}:${now.getUTCSeconds().toString().padStart(2, "0")}`;
  return `${formattedDate} ${formattedTime}`;
};

export const getRemainingSeconds = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const difference = end - start;
  return Math.floor(difference / 1000);
};

export function jsonToBase64(object) {
  const json = JSON.stringify(object);
  return Buffer.from(json).toString("base64");
}

export function base64ToJson(base64String) {
  const json = Buffer.from(base64String, "base64").toString();
  return JSON.parse(json);
}
