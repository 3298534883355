import { Fragment, useEffect, useState } from "react";
import { ActionBar } from "../../components/headers/ActionBar";
import { AdminUsersAUTable } from "../../components/table/AdminUsersAUTable";
import { PageStackLayout } from "../../components/layout/PageStackLayout";
import { useAPI, useModal } from "../../hooks";
import { useToast, useAuth } from "../../context";
import LoaderCard from "../../components/cards/LoaderCard";
import { SetPasswordAlert } from "../../components/alert/SetPasswordAlert";
import { AddUserAlert } from "../../components/alert/AddUserAlert";
import { EditUserAlert } from "../../components/alert/EditUserAlert";
import { SetPasswordLinkSplash } from "../../components/backdrop/SetPasswordLinkSplash";
import { Stack, Typography } from "@mui/material";
import { Button, useTheme } from "@mui/material";
import { Add } from "iconsax-react";

export const AdminUsersScreen = () => {
  const { api, urls, isLoading } = useAPI();
  const theme = useTheme();
  const { showToast } = useToast();
  const [adminUsers, setAdminUsers] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [editAdmin, setEditAdmin] = useState(null);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    limit: 0,
    total_count: 0,
  });
  const [requestData, setRequestData] = useState({
    itemsPerPage: 25,
    currentPage: 1,
    searchText: "",
    sortData: {
      sort_field: null,
      sort_order: "asc",
    },
  });
  const [timer, setTimer] = useState(null);

  const [isListLoading, setIsListLoading] = useState(false);
  const [isOpenAddModal, openAddModal, closeAddModal] = useModal();
  const [isOpenSplash, openSplash, closeSplash] = useModal();
  const [addedEmail, setAddedEmail] = useState("");
  const [resetingPassword, setResetingPassword] = useState(false);

  const { logout } = useAuth();

  const getAdminUsers = async () => {
    const { itemsPerPage, currentPage, searchText, sortData } = requestData;
    setIsListLoading(true);
    api
      .get(
        `${urls.adminUsers}?page=${currentPage}&limit=${itemsPerPage}&search=${searchText}&sort_field=${sortData.sort_field}&sort_order=${sortData.sort_order}`
      )
      .then((res) => {
        setAdminUsers(res.data.data.data);
        setPaginationData(res.data.data.pagination);
        setIsListLoading(false);
      })
      .catch(handleApiError);
  };

  const requestPasswordLink = (user) => {
    setResetingPassword(true);
    const requestData = { user_id: user.id };
    api
      .post(urls.setPassword, requestData)
      .then((res) => {
        setResetingPassword(false);
        showToast(
          "A password setup link has been sent to the email address.",
          "success"
        );
      })
      .catch(handleApiError);
  };

  const activateDeactivateUser = ({ id, checked }) => {
    api
      .put(`${urls.activateDeactivateUser}/${id}`, { active: checked })
      .then((res) => {
        getAdminUsers();
      })
      .catch(handleApiError);
  };

  //error handling
  const handleApiError = (err) => {
    setIsListLoading(false);
    setResetingPassword(false);
    if (err.response?.status === 401) {
      logout();
    } else {
      console.log("err", err);
      showToast(err.response?.data?.message, "error");
    }
  };

  useEffect(() => {
    getAdminUsers();
  }, [requestData]);

  const updateRequestData = (newData) => {
    setRequestData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  // const onSearchTextChange = (event) => {
  //   updateRequestData({
  //     searchText: event.target.value,
  //     currentPage: 1,
  //     sortData: {
  //       sort_field: null,
  //       sort_order: "asc",
  //     },
  //   });
  // };
  const onSearchTextChange = (event) => {
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      updateRequestData({
        searchText: event.target.value,
        currentPage: 1,
        sortData: {
          sort_field: null,
          sort_order: "asc",
        },
      });
    }, 500);

    setTimer(newTimer);
  };

  return (
    <Fragment>
      <ActionBar
        header={"Admin Users"}
        onSearchTextChange={onSearchTextChange}
        onAddClick={openAddModal}
      />
      <PageStackLayout>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant="h1">Admin Users</Typography>
          <Button
            variant="contained"
            disableElevation
            color="theme"
            startIcon={<Add size="25" color={theme.palette.common.white} />}
            onClick={openAddModal}
            sx={{
              height: 40,
              pl: 2,
              pr: 2,
              borderRadius: 2,
              fontSize: 16,
              fontWeight: 700,
              textTransform: "none",
            }}
          >
            Add User
          </Button>
        </Stack>

        {isListLoading && <LoaderCard />}
        {!isListLoading && (
          <AdminUsersAUTable
            users={adminUsers}
            onAccessChange={(user, checked) => {
              activateDeactivateUser({ id: user.id, checked: checked });
            }}
            onSetPassword={(user) => {
              console.log("🚀 ~ AdminUsersScreen ~ user:", user);
              requestPasswordLink(user);
              // setSelectedAdmin(user);
            }}
            onEditUser={(user) => setEditAdmin(user)}
            paginationData={paginationData}
            itemsPerPage={requestData.itemsPerPage}
            setItemsPerPage={(count) =>
              updateRequestData({ itemsPerPage: count, currentPage: 1 })
            }
            setPageNumber={(page) => updateRequestData({ currentPage: page })}
            sortData={requestData.sortData}
            setSortData={(data) => updateRequestData({ sortData: data })}
            resetingPassword={resetingPassword}
          />
        )}
      </PageStackLayout>
      <SetPasswordLinkSplash
        open={isOpenSplash}
        onClose={() => {
          closeSplash();
          setAddedEmail("");
        }}
        email={addedEmail}
      />
      {selectedAdmin !== null && (
        <SetPasswordAlert
          open={selectedAdmin !== null}
          user={selectedAdmin}
          onClose={() => setSelectedAdmin(null)}
          onSubmit={() => setSelectedAdmin(null)}
        />
      )}
      <AddUserAlert
        open={isOpenAddModal}
        onClose={closeAddModal}
        onConfirm={(user) => {
          console.log("🚀 ~ AdminUsersScreen ~ user:", user);
          // setSelectedAdmin(user);
          closeAddModal();
          getAdminUsers();
        }}
      />
      {editAdmin !== null && (
        <EditUserAlert
          open={editAdmin !== null}
          onClose={() => setEditAdmin(null)}
          data={editAdmin}
          userUpdated={() => {
            setEditAdmin(null);
            getAdminUsers();
          }}
        />
      )}
    </Fragment>
  );
};
