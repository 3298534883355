import { Fragment, useEffect, useState } from "react";
import { ActionBar } from "../../components/headers/ActionBar";
import { UnregisteredUsersULTable } from "../../components/table/UnregisteredUsersULTable";
import { PageStackLayout } from "../../components/layout/PageStackLayout";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks";
import LoaderCard from "../../components/cards/LoaderCard";
import { Typography } from "@mui/material";

export const UnRegisteredListScreen = () => {
  const { showToast } = useToast();
  const { logout } = useAuth();
  const { api, urls, isLoading } = useAPI();

  const [requestData, setRequestData] = useState({
    itemsPerPage: 25,
    currentPage: 1,
    searchText: "",
    sortData: {
      sort_field: null,
      sort_order: "asc",
    },
  });

  const [customersList, setCustomersList] = useState([]);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    limit: 0,
    total_count: 0,
  });

  //api
  const getCustomersList = async () => {
    const { itemsPerPage, currentPage, searchText, sortData } = requestData;
    api
      .get(
        `${urls.unRegisteredCustomers}?type=Digital&page=${currentPage}&limit=${itemsPerPage}&search=${searchText}&sort_field=${sortData.sort_field}&sort_order=${sortData.sort_order}`
      )
      .then((res) => {
        let data = res.data.data;
        setCustomersList(data.data);
        setPaginationData(data.pagination);
      })
      .catch(handleApiError);
  };

  //error handling
  const handleApiError = (err) => {
    if (err.response?.status === 401) {
      logout();
    } else {
      console.log("err", err);
      showToast(err.response?.data?.message, "error");
    }
  };

  //useEffect
  useEffect(() => {
    getCustomersList();
  }, [requestData]);

  const updateRequestData = (newData) => {
    setRequestData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  const onSearchTextChange = (event) => {
    updateRequestData({
      searchText: event.target.value,
      currentPage: 1,
      sortData: {
        sort_field: null,
        sort_order: "asc",
      },
    });
  };

  return (
    <Fragment>
      <ActionBar
        header={"Unregistered List"}
        onSearchTextChange={onSearchTextChange}
      />
      <PageStackLayout>
        <Typography variant="h1">Unregistered List</Typography>
        {isLoading && <LoaderCard />}
        {!isLoading && (
          <UnregisteredUsersULTable
            data={customersList}
            paginationData={paginationData}
            itemsPerPage={requestData.itemsPerPage}
            setItemsPerPage={(count) =>
              updateRequestData({ itemsPerPage: count, currentPage: 1 })
            }
            setPageNumber={(page) => updateRequestData({ currentPage: page })}
            sortData={requestData.sortData}
            setSortData={(data) => updateRequestData({ sortData: data })}
          />
        )}
      </PageStackLayout>
    </Fragment>
  );
};
