import {
  Typography,
  Stack,
  Table,
  Box,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
  useMediaQuery,
} from "@mui/material";

export const DynamicKeyTable = ({ title, jsonObject }) => {
  const isMobile = useMediaQuery("(max-width: 600px)"); // Mobile breakpoint
  const theme = useTheme();

  const decodeHtmlEntities = (encodedString) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = encodedString;
    return textArea.value;
  };

  const renderValue = (value) => {
    if (Array.isArray(value)) {
      return (
        <TableCell align="left">
          <Stack direction={"column"} spacing={1}>
            {value.map((item, index) => (
              <Typography key={index} variant="p1" alignItems={"left"}>
                {item}
              </Typography>
            ))}
          </Stack>
        </TableCell>
      );
    } else if (typeof value === "object" && value !== null) {
      return (
        <TableBody>
          {Object.keys(value).map((subKey) => (
            <TableRow key={subKey}>
              <TableCell>
                <Typography
                  variant="subtitle2"
                  style={{ display: "inline-block", marginRight: 5 }}
                >
                  {subKey.replaceAll("_", " ")}:{" "}
                </Typography>
              </TableCell>
              <TableCell>{renderValue(value[subKey])}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      );
    } else {
      return (
        <TableCell align="left">
          <Stack direction={"column"} spacing={1}>
            {/* <Typography variant="p1" alignItems={"left"}>
              {value}
            </Typography> */}

            <div
              dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(value) }}
              style={{ fontSize: 16 }}
            />
          </Stack>
        </TableCell>
      );
    }
  };

  return (
    <Stack
      direction={"column"}
      spacing={2}
      mt={2}
      sx={{
        p: 2,
        bgcolor: theme.palette.common.white,
        borderRadius: 2,
      }}
    >
      {title && (
        <Box sx={{ bgcolor: "#e9f1fd", borderRadius: 2 }}>
          <Typography
            sx={{
              pl: 2,
              py: 1,
              fontWeight: 700,
              fontSize: 22,
              color: theme.palette.badgeTheme.main,
            }}
          >
            {title}
          </Typography>
        </Box>
      )}
      <Table style={{ width: "100%" }}>
        {Object.keys(jsonObject).map((key) => (
          <TableRow key={key}>
            {/* Set table width to 100% */}
            <TableCell sx={{ width: 200, verticalAlign: "top" }}>
              <Typography variant="h2">
                {key.replace("D_", "").replaceAll("_", " ")}
              </Typography>
            </TableCell>
            {renderValue(jsonObject[key])}
          </TableRow>
        ))}
      </Table>
    </Stack>
  );
};
