import { Fragment, useState, useEffect } from "react";
import { ActionBar } from "../../components/headers/ActionBar";
import { PageStackLayout } from "../../components/layout/PageStackLayout";
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  useTheme,
} from "@mui/material";
import { LabelTextField } from "../../components/fields/LabelTextField";
import { AuthRiskCard } from "../../components/cards/AuthRiskCard";
import { useAPI } from "../../hooks";
import { useToast, useAuth } from "../../context";
import LoaderCard from "../../components/cards/LoaderCard";
import EditIcon from "@mui/icons-material/Edit";
import { getEditPermissionStatus } from "../../utilities/get-permission";

export const RiskBasedAuthSettingsScreen = () => {
  const theme = useTheme();

  const [currencySymbol, setCurrencySymbol] = useState("");

  const { showToast } = useToast();
  const { logout, adminRole, isAdmin } = useAuth();
  const { api, urls, isLoading } = useAPI();
  const currencies = ["$", "€", "£", "¥", "₣"];

  const [listLoading, setListLoading] = useState(false);
  const [priceList, setPriceList] = useState([
    {
      title: `Price Limit 1`,
      riskText: "Low Risk",
      settings: {
        min_amount: "1",
        max_amount: "0",
        currency: "",
        user_pin: false,
        user_pattern: false,
        user_biometric: false,
        user_face: false,
        order: 1,
      },
    },
    {
      title: `Price Limit 2`,
      riskText: "Medium Risk",
      settings: {
        min_amount: "0",
        max_amount: "0",
        currency: "",
        user_pin: false,
        user_pattern: false,
        user_biometric: false,
        user_face: false,
        order: 2,
      },
    },
    {
      title: `Price Limit 3`,
      riskText: "High Risk",
      settings: {
        min_amount: "0",
        max_amount: "0",
        currency: "",
        user_pin: false,
        user_pattern: false,
        user_biometric: false,
        user_face: false,
        order: 3,
      },
    },
    {
      title: `Price Limit 4`,
      riskText: "Very High Risk",
      settings: {
        min_amount: "0",
        max_amount: "0",
        currency: "",
        user_pin: false,
        user_pattern: false,
        user_biometric: false,
        user_face: true,
        order: 4,
      },
    },
  ]);
  const [isEditMode, setIsEditMode] = useState(false);

  //api
  const getPriceSettingsList = async (showLoader) => {
    if (showLoader) {
      setListLoading(true);
    }

    api
      .get(`${urls.priceSettings}?limit=100&page=1`)
      .then((res) => {
        let data = res.data.data;
        if (data.data.length >= 3) {
          let currency = "";
          const updatedPriceList = priceList.map((item) => {
            const order = item.settings.order; // Get the order of the item
            const matchingData = data.data.find(
              (apiItem) => apiItem.order === order
            ); // Find matching data from API response based on order
            if (matchingData) {
              currency = matchingData.currency;
              return {
                ...item,
                settings: matchingData, // Update settings with data from API response based on order
              };
            } else {
              return item; // If no matching data found, return original item
            }
          });
          setCurrencySymbol(currency ?? "$");
          setPriceList(updatedPriceList);
        }
        //check loader
        if (showLoader) {
          setListLoading(false);
        }
      })
      .catch(handleApiError);
  };

  const savePriceSettings = async () => {
    let validationFlag = true;
    let checkBoxNotEmpty = true;

    priceList.forEach((item, index) => {
      if (item.settings.min_amount === "" || item.settings.max_amount === "") {
        validationFlag = false;
      } else if (
        index !== 3 &&
        +item.settings.min_amount > +item.settings.max_amount
      ) {
        validationFlag = false;
      }
    });

    priceList.forEach((item) => {
      if (
        item.settings.user_biometric === false &&
        item.settings.user_face === false &&
        item.settings.user_pattern === false &&
        item.settings.user_pin === false
      ) {
        checkBoxNotEmpty = false;
      }
    });

    // If any empty values are found, set the validation flag to false
    if (!validationFlag) {
      // Handle validation error
      showToast("Invalid Min and Max Amount", "error");
      return;
    }

    // if (!checkBoxNotEmpty) {
    //   // Handle validation error
    //   showToast(
    //     "Please select atleast one authentication method for each price limit",
    //     "error"
    //   );
    //   return;
    // }

    const requestArray = priceList.map((item, index) => ({
      min_amount: item.settings.min_amount,
      max_amount: item.settings.max_amount,
      user_pin: item.settings.user_pin,
      user_pattern: item.settings.user_pattern,
      user_biometric: item.settings.user_biometric,
      user_face: item.settings.user_face,
      order: index + 1,
    }));
    api
      .post(urls.updateCurrencySettings, {
        key: "currency",
        value: currencySymbol,
      })
      .then((res) => {
        api
          .post(urls.updatePriceSettings, requestArray)
          .then((res) => {
            showToast("Transaction Settings updated successfully", "success");
            setIsEditMode(false);
            getPriceSettingsList(false);
          })
          .catch(handleApiError);
      })
      .catch(handleApiError);
  };

  //error handling
  const handleApiError = (err) => {
    if (err.response?.status === 401) {
      logout();
    } else {
      console.log("err", err);
      showToast(err.response?.data?.message, "error");
      setListLoading(false);
    }
  };

  const checkBoxUpdated = (field, index) => {
    if (priceList.length > index) {
      setPriceList((prevPriceList) => {
        const updatedPriceList = [...prevPriceList];
        const updatedItem = { ...updatedPriceList[index] };

        if (field === "pin") {
          updatedItem.settings.user_pin = !updatedItem.settings.user_pin;
        } else if (field === "pattern") {
          updatedItem.settings.user_pattern =
            !updatedItem.settings.user_pattern;
        } else if (field === "biometric") {
          updatedItem.settings.user_biometric =
            !updatedItem.settings.user_biometric;
        } else {
          // Assuming field is "face" for user_face
          if (index === 3) {
            // For very high risk, face ID is mandatory
            updatedItem.settings.user_face = true;
          } else {
            updatedItem.settings.user_face = !updatedItem.settings.user_face;
          }
        }

        updatedPriceList[index] = updatedItem;
        return updatedPriceList;
      });
    }
  };

  const priceUpdated = (type, text, index) => {
    if ((!isNaN(text) || text === "") && priceList.length > index) {
      setPriceList((prevPriceList) => {
        const updatedPriceList = [...prevPriceList];
        const updatedItem = { ...updatedPriceList[index] };

        if (type === "min") {
          updatedItem.settings.min_amount = text;
        } else {
          // Assuming field is "max" for user_face
          updatedItem.settings.max_amount = text;

          if (index !== 3) {
            const nextItem = { ...updatedPriceList[index + 1] };
            nextItem.settings.min_amount = `${+text + 0.01}`;
            updatedPriceList[index + 1] = nextItem;
          }
        }

        updatedPriceList[index] = updatedItem;
        return updatedPriceList;
      });
    }
  };

  const handleCurrencySymbolChange = (event) => {
    let value = event.target.value;

    // Limit the input to maximum 3 characters
    if (value.length > 3) {
      value = value.slice(0, 3);
    }

    setCurrencySymbol(value);
  };

  const cancelEdit = () => {
    setIsEditMode(false);
    getPriceSettingsList(false);
  };

  //useEffect
  useEffect(() => {
    getPriceSettingsList(true);
  }, []);

  return (
    <Fragment>
      <ActionBar header={"Authentication Settings"} />
      <PageStackLayout>
        <Typography variant="h1">Authentication Settings</Typography>
        <Stack
          direction={"column"}
          sx={{
            gap: 2,
            p: 3,
            bgcolor: theme.palette.common.white,
            borderRadius: 3,
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h1">Transaction Value</Typography>

            {!listLoading && (
              <Stack direction={"row"} spacing={2}>
                {!isEditMode &&
                  (isAdmin ||
                    getEditPermissionStatus(
                      adminRole.permissions,
                      "Authentication Settings"
                    )) && (
                    <Button
                      variant="outlined"
                      disableElevation
                      color="theme"
                      sx={{
                        width: 120,
                        height: 55,
                        fontSize: 16,
                        fontWeight: 700,
                        textTransform: "none",
                        borderRadius: 2,
                      }}
                      startIcon={<EditIcon />}
                      onClick={() => setIsEditMode(true)}
                    >
                      Edit
                    </Button>
                  )}
                <Box sx={{ width: 80 }}>
                  <FormControl>
                    <InputLabel id="currency-label">Currency</InputLabel>
                    <Select
                      labelId="currency-label"
                      label="Currency"
                      value={currencySymbol}
                      onChange={handleCurrencySymbolChange}
                      sx={{ width: 80, borderRadius: 2 }}
                      disabled={!isEditMode}
                    >
                      {currencies.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Stack>
            )}
          </Stack>
          <Divider />
          {listLoading && <LoaderCard />}
          {!listLoading &&
            priceList.map((priceDetail, index) => {
              return (
                <AuthRiskCard
                  key={index}
                  priceLimit={priceDetail.title}
                  riskType={priceDetail.riskText}
                  symbol={currencySymbol}
                  settingsData={priceDetail.settings}
                  isEditMode={isEditMode}
                  checkBoxUpdated={(field) => checkBoxUpdated(field, index)}
                  inputUpdated={(type, text) => priceUpdated(type, text, index)}
                  index={index}
                />
              );
            })}
          {isEditMode && (
            <Stack direction={"row"} spacing={2}>
              <Button
                variant="contained"
                disableElevation
                color="theme"
                sx={{
                  width: 300,
                  height: 50,
                  fontSize: 16,
                  fontWeight: 700,
                  textTransform: "none",
                  borderRadius: 2,
                }}
                onClick={savePriceSettings}
                disabled={currencySymbol.trim() === "" || isLoading}
              >
                Update
              </Button>
              <Button
                variant="outlined"
                disableElevation
                color="theme"
                sx={{
                  width: 300,
                  height: 50,
                  fontSize: 16,
                  fontWeight: 700,
                  textTransform: "none",
                  borderRadius: 2,
                }}
                onClick={cancelEdit}
              >
                Cancel
              </Button>
            </Stack>
          )}
        </Stack>
      </PageStackLayout>
    </Fragment>
  );
};
