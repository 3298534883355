import React, { Fragment, useState, useEffect } from "react";
import { Stack, width } from "@mui/system";
import { ActionBar } from "../../components/headers/ActionBar";
import { BigTile } from "../../components/basic/BigTile";
import { BigTileWide } from "../../components/basic/BigTileWide";
import { Profile2User, Personalcard } from "iconsax-react";
import { Divider, Box, Grid, Typography, useTheme } from "@mui/material";
import { PageStackLayout } from "../../components/layout/PageStackLayout";
import { useNavigate } from "react-router-dom";
import { useAPI } from "../../hooks";
import { useToast, useAuth } from "../../context";
import { addCommasToNumber } from "../../utilities/extensions";
import LoaderCard from "../../components/cards/LoaderCard";
import NoDataCard from "../../components/cards/NoDataCard";
import WorldMap from "react-svg-worldmap";
import { Done, DoneAll } from "@mui/icons-material";

import { PieChart, Pie, Label, Cell, ResponsiveContainer } from "recharts";

export function DashboardScreen() {
  const theme = useTheme();
  const navigate = useNavigate();

  const { showToast } = useToast();
  const { logout } = useAuth();
  const { api, urls, isLoading } = useAPI();
  const [listingApiLoading, setListingApiLoading] = useState(false);

  const COLORS = ["#14B8A6", "#EC4899", "#6366F1", "#F59E0B"];

  const [dashboardData, setDashboardData] = useState({
    checker_pending_verifications: 0,
    maker_pending_verifications: 0,
    customers_registered_all: 0,
    face_verification: {
      success: 0,
      total: 0,
      success_percentage: 0,
    },
    id_verification: {
      success: 0,
      total: 0,
      success_percentage: 0,
      fraud_percentage: 0,
    },
    login_verification: [],
    transaction_verification: [],
    locale: [],
  });

  function toCamelCase(str) {
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  }

  function convertToPercentageFormat(data, decimalPlaces) {
    // Step 1: Merge percentages for keys with the same name
    const mergedData = {};
    data.forEach(({ auth_type, percentage }) => {
      const key = auth_type.toLowerCase(); // Consider case-insensitivity
      mergedData[key] = (mergedData[key] || 0) + percentage;
    });

    // Step 2: Calculate the total sum of percentages
    const totalSum = Object.values(mergedData).reduce(
      (sum, percentage) => sum + percentage,
      0
    );

    // Step 3: Convert each percentage to a percentage out of 100 and round
    const formattedData = Object.entries(mergedData)
      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) // Sort keys alphabetically
      .map(([key, percentage], index) => ({
        id: index, // Auto-incrementing ID
        label:
          key.toLowerCase() === "idenface"
            ? "Face Recognition"
            : toCamelCase(key), // Convert key to camel case
        value: Number(((percentage / totalSum) * 100).toFixed(decimalPlaces)), // Round the percentage
      }));

    return formattedData;
  }

  //api
  const getDashboardDetails = async () => {
    setListingApiLoading(true);
    api
      .get(urls.dashboard)
      .then((res) => {
        const data = res.data.data;
        const updatedData = data;

        const formattedLoginVerificationData = convertToPercentageFormat(
          data.login_verification,
          2
        );

        const formattedTransactionVerificationData = convertToPercentageFormat(
          data.transaction_verification,
          2
        );

        updatedData.login_verification = formattedLoginVerificationData;
        updatedData.transaction_verification =
          formattedTransactionVerificationData;

        setDashboardData(updatedData);
        setListingApiLoading(false);
      })
      .catch(handleApiError);
  };

  //error handling
  const handleApiError = (err) => {
    if (err.response?.status === 401) {
      logout();
    } else {
      showToast(err.response?.data?.message, "error");
      setListingApiLoading(false);
    }
  };

  //useEffect
  useEffect(() => {
    getDashboardDetails();
  }, []);

  return (
    <Fragment>
      <ActionBar header={"Dashboard"} />
      {listingApiLoading && <LoaderCard height={"300px"} />}
      {!listingApiLoading && (
        <PageStackLayout>
          <Typography variant="h1">Dashboard</Typography>
          <Stack direction={"row"} sx={{ gap: 2 }}>
            <BigTile
              icon={<Profile2User size="48" color="#005EAD" />}
              iconBgColor={theme.palette.common.hightlighterOne}
              value={addCommasToNumber(dashboardData.customers_registered_all)}
              description={
                dashboardData.customers_registered_all > 1
                  ? "Registered Customers"
                  : "Registered Customer"
              }
              onClick={() => {}}
            />
            <BigTile
              icon={<Personalcard size="48" color="#FF7447" />}
              iconBgColor={theme.palette.common.hightlighterOne}
              value={addCommasToNumber(
                dashboardData.maker_pending_verifications
              )}
              description={
                dashboardData.maker_pending_verifications > 1
                  ? "Maker Verifications"
                  : "Maker Verification"
              }
              onClick={() => {}}
              tickIcon={
                <Done
                  fontSize="small"
                  sx={{
                    color: "passGreen.main",
                    background: "white",
                    borderRadius: "50%",
                    p: 0.2,
                  }}
                />
              }
            />
            <BigTile
              icon={<Personalcard size="48" color="#37d67a" />}
              iconBgColor={theme.palette.common.hightlighterOne}
              value={addCommasToNumber(
                dashboardData.checker_pending_verifications
              )}
              description={
                dashboardData.checker_pending_verifications > 1
                  ? "Checker Verifications"
                  : "Checker Verification"
              }
              onClick={() => {}}
              tickIcon={
                <DoneAll
                  fontSize="small"
                  sx={{
                    color: "passGreen.main",
                    background: "white",
                    borderRadius: "50%",
                    p: 0.2,
                  }}
                />
              }
            />
            {/* <BigTileWide
              icon={<Personalcard size="48" color="#FF7447" />}
              iconBgColor={theme.palette.common.hightlighterTwo}
              value={addCommasToNumber(
                dashboardData.maker_pending_verifications
              )}
              description={
                dashboardData.maker_pending_verifications > 1
                  ? "Maker Pending Verifications"
                  : "Maker Pending Verification"
              }
              value2={addCommasToNumber(
                dashboardData.checker_pending_verifications
              )}
              description2={
                dashboardData.checker_pending_verifications > 1
                  ? "Checker Verifications"
                  : "Checker Verification"
              }
              onClick={() => {}}
            /> */}
          </Stack>
          <Stack direction={"row"} sx={{ gap: 2 }}>
            <Stack
              width={"100%"}
              direction={"row"}
              sx={{
                p: 2,
                bgcolor: theme.palette.common.white,
                borderRadius: 3,
              }}
            >
              <Grid container spacing={2} direction={"row"}>
                <Grid item md={3}>
                  <Stack
                    direction={"column"}
                    sx={{
                      p: 2,
                      bgcolor: theme.palette.background.main,
                      borderRadius: 2,
                      position: "relative",
                    }}
                    height={"90%"}
                    spacing={2}
                  >
                    <Stack direction={"column"} spacing={2}>
                      <Typography sx={{ fontSize: 20, fontWeight: 700 }}>
                        Login Verification
                      </Typography>
                      <Divider />
                      {dashboardData.login_verification.length === 0 && (
                        <NoDataCard
                          bgColor={theme.palette.common.lightGreyBg}
                        />
                      )}
                      {dashboardData.login_verification.length > 0 && (
                        <ResponsiveContainer width="100%" height={200}>
                          <PieChart width={400} height={400}>
                            <Pie
                              data={dashboardData.login_verification}
                              cx="50%"
                              cy="50%"
                              labelLine={false}
                              outerRadius={80}
                              fill="#8884d8"
                              dataKey="value"
                              startAngle={90}
                              endAngle={460}
                            >
                              {dashboardData.login_verification.map(
                                (entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                  />
                                )
                              )}
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer>
                      )}
                      <Stack direction={"column"} spacing={1} pb={1}>
                        {dashboardData.login_verification.map(
                          (rowItem, index) => {
                            return (
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                spacing={2}
                                key={index}
                              >
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  spacing={1}
                                >
                                  <Box
                                    sx={{
                                      bgcolor: COLORS[index % COLORS.length],
                                      width: 12,
                                      height: 12,
                                      borderRadius: "50%",
                                    }}
                                  />
                                  <Typography
                                    sx={{ fontSize: 15, fontWeight: 700 }}
                                  >
                                    {rowItem.label}
                                  </Typography>
                                </Stack>
                                <Typography
                                  sx={{ fontSize: 15, fontWeight: 700 }}
                                >
                                  {rowItem.value}%
                                </Typography>
                              </Stack>
                            );
                          }
                        )}
                      </Stack>
                    </Stack>
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(255, 255, 255, 0)", // Semi-transparent black
                      }}
                    ></Box>
                  </Stack>
                </Grid>
                <Grid item md={3}>
                  <Stack
                    direction={"column"}
                    sx={{
                      p: 2,
                      bgcolor: theme.palette.background.main,
                      borderRadius: 2,
                      position: "relative",
                    }}
                    height={"90%"}
                    spacing={2}
                  >
                    <Stack direction={"column"} spacing={2}>
                      <Typography sx={{ fontSize: 20, fontWeight: 700 }}>
                        Transaction Verification
                      </Typography>
                      <Divider />
                      {dashboardData.transaction_verification.length === 0 && (
                        <NoDataCard
                          bgColor={theme.palette.common.lightGreyBg}
                        />
                      )}
                      {dashboardData.transaction_verification.length > 0 && (
                        <ResponsiveContainer width="100%" height={200}>
                          <PieChart width={400} height={400}>
                            <Pie
                              data={dashboardData.transaction_verification}
                              cx="50%"
                              cy="50%"
                              labelLine={false}
                              outerRadius={80}
                              fill="#8884d8"
                              dataKey="value"
                              startAngle={90}
                              endAngle={460}
                            >
                              {dashboardData.transaction_verification.map(
                                (entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                  />
                                )
                              )}
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer>
                      )}
                      <Stack direction={"column"} spacing={1} pb={1}>
                        {dashboardData.transaction_verification.map(
                          (rowItem, index) => {
                            return (
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                spacing={2}
                                key={index}
                              >
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  spacing={1}
                                >
                                  <Box
                                    sx={{
                                      bgcolor: COLORS[index % COLORS.length],
                                      width: 12,
                                      height: 12,
                                      borderRadius: "50%",
                                    }}
                                  />
                                  <Typography
                                    sx={{ fontSize: 15, fontWeight: 700 }}
                                  >
                                    {rowItem.label}
                                  </Typography>
                                </Stack>
                                <Typography
                                  sx={{ fontSize: 15, fontWeight: 700 }}
                                >
                                  {rowItem.value}%
                                </Typography>
                              </Stack>
                            );
                          }
                        )}
                      </Stack>
                    </Stack>
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(255, 255, 255, 0)", // Semi-transparent black
                      }}
                    ></Box>
                  </Stack>
                </Grid>
                <Grid item md={3}>
                  <Stack
                    direction={"column"}
                    sx={{
                      p: 2,
                      bgcolor: theme.palette.background.main,
                      borderRadius: 2,
                      position: "relative",
                    }}
                    spacing={2}
                    height={"90%"}
                  >
                    <Stack direction={"column"} height={"10%"} spacing={2}>
                      <Typography sx={{ fontSize: 20, fontWeight: 700 }}>
                        Face Verification
                      </Typography>
                      <Divider />
                      <Stack direction={"column"}>
                        <Typography
                          sx={{
                            fontSize: 18,
                            fontWeight: 700,
                            color: theme.palette.graphCountBlack.main,
                          }}
                        >
                          Total Successful Scans
                        </Typography>
                        <Stack direction={"row"} alignItems={"baseline"}>
                          <Typography
                            sx={{
                              fontSize: 24,
                              fontWeight: 700,
                            }}
                          >
                            {dashboardData.face_verification.success}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 16,
                              fontWeight: 700,
                              color: theme.palette.graphCountGray.main,
                            }}
                          >
                            /{dashboardData.face_verification.total}
                          </Typography>
                        </Stack>
                      </Stack>
                      <ResponsiveContainer width="100%" height={200}>
                        <PieChart width={400} height={400}>
                          <Pie
                            data={[
                              {
                                name: "Remaining",
                                value:
                                  100 -
                                  dashboardData.face_verification
                                    .success_percentage,
                              },
                              {
                                name: "Progress",
                                value:
                                  dashboardData.face_verification
                                    .success_percentage,
                              },
                            ]}
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={80}
                            paddingAngle={-10}
                            cornerRadius={30}
                            dataKey="value"
                            startAngle={90}
                            endAngle={460}
                          >
                            {[
                              {
                                name: "Progress",
                                value:
                                  100 -
                                  dashboardData.face_verification
                                    .success_percentage,
                              },
                              {
                                name: "Remaining",
                                value:
                                  dashboardData.face_verification
                                    .success_percentage,
                              },
                            ].map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={index !== 0 ? "#2ADEC0" : "#2ADEC040"}
                              />
                            ))}
                          </Pie>
                          <Pie
                            data={[{ name: "Label", value: 1 }]}
                            cx="50%"
                            cy="50%"
                            innerRadius={0}
                            outerRadius={60} // Adjust the outer radius to match the inner radius of the outer Pie chart
                            paddingAngle={0}
                            cornerRadius={30}
                            dataKey="value"
                            className={`pie-chart-1`}
                          >
                            {/* Add label inside the inner circle */}
                            <Label
                              value={`${dashboardData.face_verification.success_percentage}%`}
                              position="center"
                              fill="#000"
                              fontSize={22}
                              fontWeight={700}
                            />
                            <Cell fill={"#EEFDFB"} />
                          </Pie>
                          <style>{`
                          .pie-chart-1 .recharts-sector {
                            stroke: #EEFDFB; // Change the color of the separator lines here
                          }
                        `}</style>
                        </PieChart>
                      </ResponsiveContainer>
                    </Stack>
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(255, 255, 255, 0)", // Semi-transparent black
                      }}
                    ></Box>
                  </Stack>
                </Grid>
                <Grid item md={3}>
                  <Stack
                    direction={"column"}
                    sx={{
                      p: 2,
                      bgcolor: theme.palette.background.main,
                      borderRadius: 2,
                      position: "relative",
                    }}
                    spacing={2}
                    height={"90%"}
                  >
                    <Stack direction={"column"} height={"10%"} spacing={2}>
                      <Typography sx={{ fontSize: 20, fontWeight: 700 }}>
                        ID Verification
                      </Typography>
                      <Divider />
                      <Stack direction={"column"}>
                        <Typography
                          sx={{
                            fontSize: 18,
                            fontWeight: 700,
                            color: theme.palette.graphCountBlack.main,
                          }}
                        >
                          Total Scans
                        </Typography>
                        <Stack direction={"row"} alignItems={"baseline"}>
                          <Typography
                            sx={{
                              fontSize: 24,
                              fontWeight: 700,
                            }}
                          >
                            {dashboardData.id_verification.success}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 16,
                              fontWeight: 700,
                              color: theme.palette.graphCountGray.main,
                            }}
                          >
                            /{dashboardData.id_verification.total}
                          </Typography>
                        </Stack>
                      </Stack>
                      <ResponsiveContainer width="100%" height={200}>
                        <PieChart width={400} height={400}>
                          <Pie
                            data={[
                              {
                                name: "Remaining",
                                value:
                                  100 -
                                  dashboardData.id_verification
                                    .success_percentage,
                              },
                              {
                                name: "Progress",
                                value:
                                  dashboardData.id_verification
                                    .success_percentage,
                              },
                            ]}
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={80}
                            paddingAngle={-10}
                            cornerRadius={30}
                            dataKey="value"
                            startAngle={90}
                            endAngle={460}
                          >
                            {[
                              {
                                name: "Remaining",
                                value:
                                  100 -
                                  dashboardData.id_verification
                                    .success_percentage,
                              },
                              {
                                name: "Progress",
                                value:
                                  dashboardData.id_verification
                                    .success_percentage,
                              },
                            ].map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={index !== 0 ? "#0874EA" : "#0874EA40"}
                              />
                            ))}
                          </Pie>
                          <Pie
                            data={[{ name: "Label", value: 1 }]}
                            cx="50%"
                            cy="50%"
                            innerRadius={0}
                            outerRadius={60} // Adjust the outer radius to match the inner radius of the outer Pie chart
                            paddingAngle={0}
                            cornerRadius={30}
                            dataKey="value"
                            startAngle={0}
                            endAngle={360}
                            className={`pie-chart-2`}
                          >
                            {/* Add label inside the inner circle */}
                            <Label
                              value={`${dashboardData.id_verification.success_percentage}%`}
                              position="center"
                              fill="#000"
                              fontSize={22}
                              fontWeight={700}
                            />
                            <Cell fill={"#E7F3FF"} />
                          </Pie>
                          <style>{`
                          .pie-chart-2 .recharts-sector {
                            stroke: #E7F3FF; // Change the color of the separator lines here
                          }
                        `}</style>
                        </PieChart>
                      </ResponsiveContainer>
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography
                          sx={{
                            fontSize: 18,
                            fontWeight: 700,
                            color: theme.palette.graphCountBlack.main,
                          }}
                        >
                          Fraud
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 18,
                            fontWeight: 700,
                            color: theme.palette.graphCountBlack.main,
                          }}
                        >
                          {`${dashboardData.id_verification.fraud_percentage}%`}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(255, 255, 255, 0)", // Semi-transparent black
                      }}
                    ></Box>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
          <Stack
            sx={{
              p: 2,
              bgcolor: theme.palette.common.white,
              borderRadius: 3,
              gap: 2,
            }}
          >
            <Typography sx={{ fontSize: 20, fontWeight: 700 }}>
              Locale
            </Typography>
            <Divider />
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Stack maxWidth={"md"}>
                <WorldMap
                  color="#2ADEC0"
                  title=""
                  value-suffix="Customers"
                  valueSuffix="%"
                  data={dashboardData.locale}
                  size={"responsive"}
                />
              </Stack>
            </Stack>
          </Stack>
        </PageStackLayout>
      )}
    </Fragment>
  );
}
