import {
  Box,
  Button,
  Stack,
  Typography,
  useTheme,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Add, Edit2 } from "iconsax-react";
import { PageFieldCard } from "../../../components/cards/PageFieldCard";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useAPI, useModal } from "../../../hooks";
import { useToast } from "../../../context";
import { PlaceholderTextField } from "../../../components/fields/PlaceholderTextField";
import { AddPageFieldModal } from "../../../components/modals/AddPageFieldModal";
import { Fragment } from "react";
import { EditPageAlert } from "../../../components/alert/EditPageAlert";

export const PageDetailsTab = (props) => {
  const { formID, isNew, onPageChange, editAllowed, formTitle, onTitleChange } =
    props;
  const theme = useTheme();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  const [formDetails, setFormDetails] = useState(null);
  const [isOpenAddModal, openAddModal, closeAddModal] = useModal();
  const [isOpenEditModal, openEditModal, closeEditModal] = useModal();
  const [isOpenEditTitleModal, openEditTitleModal, closeEditTitleModal] =
    useModal();
  const [editField, setEditField] = useState(null);
  const [fetchingFormDetails, setFetchingFromDetails] = useState(false);

  const getFormDetails = () => {
    setFetchingFromDetails(true);
    api
      .get(urls.dynamicFormDetails + formID + "/")
      .then((res) => {
        setFetchingFromDetails(false);
        setFormDetails(res.data.data);
      })
      .catch((err) => {
        setFetchingFromDetails(false);
        showToast(err.response?.data?.data?.message, "error");
      });
  };

  useEffect(() => {
    if (formID) {
      getFormDetails();
    } else if (isNew) {
      setFormDetails({
        page_title: "",
        form_field: [
          {
            id: `uid-${formDetails?.form_field?.length ?? 0 + 1}`,
            type: "text",
            label: "",
            status: true,
            validation: {
              required: false,
              min_length: 20,
              max_length: 55,
            },
          },
        ],
      });
    }
  }, [formID]);

  useEffect(() => {
    if (isNew) {
      onPageChange(formDetails);
    }
  }, [formDetails]);

  const handleFieldChange = (data, index) => {
    setFormDetails((prev) => {
      let curr = { ...prev };
      curr.form_field[index] = data;
      return curr;
    });
  };

  const handleAddField = () => {
    if (isNew) {
      let newField = {
        id: `uid-${formDetails?.form_field?.length ?? 0 + 1}`,
        type: "text",
        label: "",
        status: true,
        validation: {
          required: false,
          min_length: 20,
          max_length: 55,
        },
      };
      setFormDetails((prev) => {
        let curr = { ...prev };
        curr.form_field.push(newField);
        return curr;
      });
    } else {
      openAddModal();
    }
  };

  const handlePageTitleChange = (e) => {
    setFormDetails((prev) => {
      let curr = { ...prev };
      curr.page_title = e.target.value;
      return curr;
    });
  };

  const handleEditClick = (field) => {
    setEditField(field);
    openEditModal();
  };

  return (
    <Fragment>
      <Box
        justifyContent={"center"}
        alignItems={"center"}
        display={"flex"}
        sx={{
          p: 4,
          bgcolor: theme.palette.common.white,
          borderRadius: 3,
        }}
      >
        {fetchingFormDetails ? (
          <CircularProgress color="theme" />
        ) : (
          <Stack
            direction={"column"}
            sx={{
              width: "100%",
              borderRadius: 3,
              gap: 2,
            }}
          >
            <Stack
              direction={"row"}
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Stack direction={"row"}>
                {isNew ? (
                  <PlaceholderTextField
                    placeholder="Enter Page Title"
                    width="400px"
                    textInput={formDetails?.page_title ?? ""}
                    onTextChange={handlePageTitleChange}
                  />
                ) : (
                  <Stack direction={"row"} alignItems={"center"}>
                    <Typography
                      variant="h1"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      {formDetails?.page_title ?? ""}
                    </Typography>
                    <IconButton onClick={() => openEditTitleModal()}>
                      <Edit2 variant="Bold" color={theme.palette.theme.black} />
                    </IconButton>
                  </Stack>
                )}
              </Stack>
              {editAllowed && (
                <Button
                  variant="outlined"
                  color="theme"
                  startIcon={
                    <Add size="30" color={theme.palette.theme.white} />
                  }
                  sx={{
                    width: 150,
                    height: 50,
                    borderRadius: 2,
                    fontSize: 16,
                    fontWeight: 700,
                    textTransform: "none",
                  }}
                  onClick={handleAddField}
                >
                  Add Field
                </Button>
              )}
            </Stack>
            {formDetails?.form_field?.map((form, ind) => (
              <PageFieldCard
                key={ind}
                field={form}
                onFieldChange={(data) => {
                  handleFieldChange(data, ind);
                }}
                showEdit={!isNew && editAllowed}
                onEditClick={handleEditClick}
                readOnly={!isNew}
                isPopup={isNew}
              />
            ))}
            {/* <Box
            sx={{
              bgcolor: theme.palette.background.main,
              p: 3,
              borderRadius: 3,
            }}
          >
            <Button
              variant="outlined"
              color="theme"
              startIcon={<Add size="30" color={theme.palette.theme.main} />}
              sx={{
                width: "100%",
                height: 50,
                fontSize: 16,
                fontWeight: 700,
                textTransform: "none",
                borderRadius: 2,
              }}
              onClick={handleAddField}
            >
              Add Field
            </Button>
          </Box> */}
          </Stack>
        )}
      </Box>
      {isOpenAddModal && (
        <AddPageFieldModal
          open={isOpenAddModal}
          onClose={closeAddModal}
          formID={formID}
          onSubmit={() => {
            closeAddModal();
            getFormDetails();
          }}
        />
      )}
      {isOpenEditModal && (
        <AddPageFieldModal
          open={isOpenEditModal}
          onClose={closeEditModal}
          formID={formID}
          field={editField}
          isEdit={true}
          onSubmit={() => {
            closeEditModal();
            setEditField(null);
            getFormDetails();
          }}
        />
      )}
      {isOpenEditTitleModal && (
        <EditPageAlert
          open={isOpenEditTitleModal}
          onClose={closeEditTitleModal}
          pageUpdated={(data) => {
            onTitleChange(data);
            setFormDetails({ ...formDetails, page_title: data.page_title });
          }}
          data={{ id: formID, page_title: formTitle }}
        />
      )}
    </Fragment>
  );
};

PageDetailsTab.propTypes = {
  formID: PropTypes.string,
  isNew: PropTypes.bool,
  onPageChange: PropTypes.func,
  formTitle: PropTypes.string,
};
