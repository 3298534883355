// API URLs
const urls = {
  // base url
  baseURL: {
    // main org
    dev: "https://devtoppanapi.hashinclude.io/api/v1/",
    prod: "https://toppanapi.hashinclude.io/api/v1/",
    ekyc: "https://ekyc-api.demo.toppangravity.com/api/v1/",
    // organisation 1 (zynk)
    // dev: "https://devtoppanapi.hashinclude.io/zynk/api/v1/",
    // prod: "https://toppanapi.hashinclude.io/zynk/api/v1/",
    // // organisation 2 (quixo)
    // dev: "https://devtoppanapi.hashinclude.io/quixo/api/v1/",
    // prod: "https://toppanapi.hashinclude.io/quixo/api/v1/",
    // // organisation 3 (pylo)
    // dev: "https://devtoppanapi.hashinclude.io/pylo/api/v1/",
    // prod: "https://toppanapi.hashinclude.io/pylo/api/v1/",
    // // organisation 4 (vynx)
    // dev: "https://devtoppanapi.hashinclude.io/vynx/api/v1/",
    // prod: "https://toppanapi.hashinclude.io/vynx/api/v1/",
    // // organisation 5 (wixy)
    // dev: "https://devtoppanapi.hashinclude.io/wixy/api/v1/",
    // prod: "https://toppanapi.hashinclude.io/wixy/api/v1/",
  },

  // auth apis
  login: "topan-admin/login/",
  forgotPassword: "",
  resetPassword: "",

  //org path
  orgPath: "customers/get-organisation-path",

  // upload
  upload: "topan-admin/upload-file/",

  // dynamic forms
  dynamicFormsList: "dynamic-form/view-forms/",
  dynamicFormDetails: "dynamic-form/view-form/",
  addFormPage: "dynamic-form/add-form-page/",
  addFormField: "dynamic-form/add-field/",
  editFormField: "dynamic-form/edit-field/",
  editPageTitle: "dynamic-form/page-edit/",

  // dashboard
  dashboard: "topan-admin/admin-dashboard-new",
  graph: "topan-admin/get-dashboard-graph",

  // customers
  customers: "topan-admin/customers/",
  unVerifiedCustomers: "topan-admin/unverified-request-list",
  unRegisteredCustomers: "customers/unregistered-user/",
  customerDetails: "topan-admin/view-customer/",
  deletedCustomerDetails: "topan-admin/archive-details/",
  customerMoreDetails: "topan-admin/get-user-more-details/",
  deletedCustomerCustomerMoreDetails:
    "topan-admin/archive-get-user-more-details/",
  customerEditHistory: "topan-admin/admin-customer-edits/",
  transactionLogs: "topan-admin/transaction-list/",
  deletedCustomerTransactionLogs: "topan-admin/archive-transaction-list/",
  unverifiedRequestDetails: "topan-admin/unverified-request-details/",
  deletedCustomerUnverifiedRequestDetails:
    "topan-admin/archive-unverified-request-details/",
  verifyCustomerRequest: "topan-admin/approve-customer-details/",
  editHistory: "topan-admin/admin-customer-edits/",
  deletedCustomerEditHistory: "topan-admin/archive-unverified-request-list/",
  customerArchives: "/topan-admin/archive-list",
  authLog: "/topan-admin/auth-log-list/",
  deletedCustomerAuthLog: "/topan-admin/archive-auth-log-list/",

  // transactions
  transactions: "topan-admin/get-transactions",

  // member details
  screeningStatus: "topan-admin/aml-list/",
  idVerificationList: "topan-admin/document-verification-list",
  deletedCustomerIdVerificationList:
    "topan-admin/archive-document-verification-list/",
  idVerificationDetail: "topan-admin/document-verification-details",
  deletedCustomerIdVerificationDetail:
    "topan-admin/archive-document-verification-details",
  triggerDocVerification: "topan-admin/trigger-document-verification",
  unVerifiyCustomer: "topan-admin/unverify-customer",
  deleteCustomer: "topan-admin/delete-customer",

  // admin users
  adminUsers: "topan-admin/admin-user-list",
  activateDeactivateUser: "topan-admin/admin-user-update",
  roleList: "topan-admin/admin-role-list",
  addUser: "topan-admin/create-admin-user",
  setPassword: "topan-admin/update-password",
  changePassword: "topan-admin/profile-update",
  permissionsList: "topan-admin/permission-list",
  addNewRole: "topan-admin/create-admin-role",
  editRole: "topan-admin/admin-role-update/",
  getMyRole: "topan-admin/get-user-permission",
  checkIdenFace: "topan-admin/request-idenface",
  deleteRole: "topan-admin/admin-role-delete/",

  // aml data
  aml_list: "topan-admin/aml-list-view",
  deletedCustomerAmlList: "topan-admin/archive-aml-list",
  aml_details: "topan-admin/aml-detail-view",
  deletedCustomerAmlDetails: "topan-admin/archive-aml-details",
  refresh_aml: "topan-admin/trigger-aml",

  //price settings
  priceSettings: "topan-admin/view-price-limit",
  updatePriceSettings: "topan-admin/update-price-list/",
  updateCurrencySettings: "topan-admin/create-settings",

  //organisations
  organisations: "topan-admin/org-list",
  orgPathList: "topan-admin/organisation-path-list",
  addOrganisation: "topan-admin/create-org",
  editOrganisation: "topan-admin/org-edit/",

  //app version
  getAppVersion: "topan-admin/get-app-versions",
  editAppVersion: "topan-admin/update-app-version",
};

export default urls;
