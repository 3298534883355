import { Box, Stack, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";

export const BigTile = (props) => {
  const { icon, iconBgColor, value, description, tickIcon, ...other } = props;

  const theme = useTheme();

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      sx={{
        width: "100%",
        p: 5,
        gap: 2,
        bgcolor: theme.palette.common.white,
        borderRadius: 3,
        // cursor: "pointer",
      }}
      {...other}
    >
      <Box
        justifyContent={"center"}
        alignItems={"center"}
        display={"flex"}
        sx={{ width: 90, height: 90, bgcolor: iconBgColor, borderRadius: 12 }}
      >
        <Stack
          sx={{ alignItems: "flex-end", mb: tickIcon ? 0 : 0 }}
          spacing={tickIcon ? -3 : 0}
        >
          {icon}
          {tickIcon}
        </Stack>
      </Box>
      <Stack direction={"column"} sx={{ gap: 1 }}>
        <Typography variant="h1">{value}</Typography>
        <Typography variant="body1">{description}</Typography>
      </Stack>
    </Stack>
  );
};

BigTile.propTypes = {
  icon: PropTypes.any,
  iconBgColor: PropTypes.string,
  value: PropTypes.string,
  description: PropTypes.string,
  tickIcon: PropTypes.any,
};
