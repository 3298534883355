import {
  Badge,
  Box,
  Collapse,
  Divider,
  Drawer,
  Fade,
  List,
  ListItemButton,
  ListItemIcon,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { sideMenuItems } from "../../../utilities";
import { SideMenuIcon } from "./SideMenuIcon";
import { Fragment, useEffect, useState } from "react";
import { logo1 } from "../../../assets";
import PropTypes from "prop-types";
import { useAuth } from "../../../context";
import { getViewPermissionStatus } from "../../../utilities/get-permission";

export const SideMenu = (props) => {
  const { isOpen, onOpenMenu, onCloseMenu } = props;

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin, adminRole, isMainOrg } = useAuth();

  const [forceState, setForceState] = useState(0);
  const [selected, setSelected] = useState(location.pathname);
  const [filteredMenuItems, setFilteredMenuItems] = useState(sideMenuItems);

  const forceUpdate = () => setForceState((prevState) => prevState + 1);

  useEffect(() => {
    navigate(selected);
  }, [selected, forceState]);

  useEffect(() => {
    //manage menu
    if (!isAdmin && adminRole !== null) {
      const menuOptions = sideMenuItems.filter((menuItem) => {
        if (menuItem.title === "Unverified Customers") {
          return (
            getViewPermissionStatus(
              adminRole.permissions,
              "Customer Verification (Maker)"
            ) ||
            getViewPermissionStatus(
              adminRole.permissions,
              "Customer Verification (Checker)"
            )
          );
        } else if (menuItem.title === "Settings") {
          return isMainOrg === "true";
        } else {
          return getViewPermissionStatus(adminRole.permissions, menuItem.title);
        }
      });
      setFilteredMenuItems(menuOptions);
    } else {
      const menuOptions = sideMenuItems.filter((menuItem) => {
        if (menuItem.title === "Settings") {
          return isMainOrg === "true";
        } else {
          return true;
        }
      });
      setFilteredMenuItems(menuOptions);
    }
  }, [isAdmin, adminRole]);

  const listItemButtonStyle = {
    height: 52,
    borderRadius: 2,
    mb: 1.5,
    pl: 2.5,
    gap: 2,
    "&.Mui-selected": {
      backgroundColor: theme.palette.theme.main,
      "&:hover": {
        backgroundColor: theme.palette.theme.main,
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.themeHover.main,
    },
  };

  const menuSelected = (m) => {
    if (m.link === selected) {
      forceUpdate();
    } else {
      setSelected(m.link);
    }
  };

  return (
    <Fragment>
      <Drawer
        variant="permanent"
        open={isOpen}
        //PaperProps={{ style: { width: "21%" } }}
        sx={{
          width: isOpen ? 300 : 100,
          transition: "width 0.5s ease-in-out",
        }}
      >
        <style>
          {`
          /* Hide scrollbar for Firefox */
          ::-webkit-scrollbar {
          display: none;
          }
          /* Hide scrollbar for IE/Edge */
          -ms-overflow-style: none;
        `}
        </style>
        <Collapse orientation="horizontal" in={isOpen} collapsedSize={100}>
          <Stack
            direction={"column"}
            onMouseEnter={onOpenMenu}
            onMouseLeave={onCloseMenu}
          >
            <Box
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
              sx={{ width: "100%", height: 90 }}
            >
              <img src={logo1} style={{ width: isOpen ? 150 : 70 }} />
            </Box>
            <Divider />
            <List
              sx={{
                p: 2,
                width: isOpen ? 270 : 65,
                // height: "82vh",
                flexWrap: "inherit",
                overflow: "scroll",
              }}
            >
              {filteredMenuItems.map((m, i) => {
                return (
                  <ListItemButton
                    key={i}
                    sx={
                      !isAdmin && (m.type === 9 || m.type === 10)
                        ? { display: "none" }
                        : listItemButtonStyle
                    }
                    selected={location.pathname.includes(m.link)}
                    onClick={() => {
                      menuSelected(m);
                    }}
                    disabled={!isAdmin && (m.type === 9 || m.type === 10)}
                  >
                    <ListItemIcon sx={{ minWidth: 24 }}>
                      <SideMenuIcon
                        type={m.type}
                        selected={location.pathname.includes(m.link)}
                      />
                    </ListItemIcon>
                    <Fade in={isOpen} timeout={{ enter: 1000 }}>
                      <Stack direction={"row"} alignItems={"center"} gap={2}>
                        <Typography
                          variant="p2"
                          sx={{
                            color: location.pathname.includes(m.link)
                              ? theme.palette.common.white
                              : theme.palette.primary.main,
                            fontSize: "16px",
                          }}
                        >
                          {m.title}
                        </Typography>
                        {/* {m.title === "Unverified Customers" && (
                          <Badge
                            badgeContent={unVerifiedReqCount}
                            color={
                              location.pathname.includes(m.link)
                                ? "badgeThemeSel"
                                : "badgeTheme"
                            }
                          />
                        )} */}
                      </Stack>
                    </Fade>
                  </ListItemButton>
                );
              })}
            </List>
          </Stack>
        </Collapse>
      </Drawer>
    </Fragment>
  );
};

SideMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOpenMenu: PropTypes.func.isRequired,
  onCloseMenu: PropTypes.func.isRequired,
};
