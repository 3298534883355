import React, { useState, useEffect } from "react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import { loginImage, logo1 } from "../../assets";
import { LabelTextField } from "../../components/fields/LabelTextField";
import { StdLabelPasswordTextField } from "../../components/fields/StdLabelPasswordTextField";
import { SubmitButton } from "../../components/buttons";
import { useAPI, useLocalStorage } from "../../hooks";
import { useToast, useAuth } from "../../context";
import axios from "axios";

export const Login = (props) => {
  const { showToast } = useToast();
  const { login, setOrganisation } = useAuth();
  const { api, urls, isLoading } = useAPI();
  const [showOrgCard, setShowOrgCard] = useState(true);
  const [orgCode, setOrgCode] = useState(null);
  const { setItem, removeItem } = useLocalStorage();

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    errors: { username: false, password: false },
  });

  const updateFormData = (key, value) => {
    setFormData((prev) => {
      let curr = { ...prev };
      curr[key] = value;
      curr.errors[key] = false;
      return curr;
    });
  };

  const orgCodeUpdating = (code) => {
    const sanitizedCode = code.replace(/\D/g, "").substring(0, 4);
    setOrgCode(sanitizedCode);
  };

  useEffect(() => {
    removeItem("access_token");
    removeItem("refresh_token");
    removeItem("is_superuser");
    removeItem("role");
    removeItem("toppan_name");
    removeItem("toppan_email");
    removeItem("tab_id");
    removeItem("tpn_baseUrl");
    removeItem("is_main_org");
    removeItem("tpn_org_name");
    console.log("LOGIN...");
  }, []);

  const handleGetOrganisation = () => {
    axios
      .get(`${urls.baseURL.ekyc}${urls.orgPath}?code=${orgCode}`)
      .then((response) => {
        showToast("Please login to continue", "success");
        console.log("🚀 ~ axios.get ~ response:", response.data.data);
        let data = response.data.data;
        setItem("tpn_baseUrl", data.base_url);
        setItem("is_main_org", data.is_superuser);
        setItem("tpn_org_name", data.name);
        setOrganisation();
        setShowOrgCard(false);
      })
      .catch((error) => {
        showToast(error.response?.data?.message, "error");
      });
  };

  const handleSignInClick = () => {
    if (!formData.username.trim()) {
      setFormData((prev) => {
        let curr = { ...prev };
        curr.errors.username = true;
        return curr;
      });
    }
    if (!formData.password.trim()) {
      setFormData((prev) => {
        let curr = { ...prev };
        curr.errors.password = true;
        return curr;
      });
    }

    let keys = Object.keys({ ...formData }).filter(
      (x) => formData.errors[x] === true
    );
    if (keys.length === 0) {
      let params = { ...formData };
      delete params.errors;

      api
        .post(urls.login, params)
        .then((res) => {
          // showToast(res.data.message, "success");
          showToast("Logged in successfully", "success");
          login();
        })
        .catch((err) => {
          showToast(err.response?.data?.message, "error");
        });
    }
  };

  return (
    <Grid container>
      <Grid item xs={6} display={{ xs: "none", md: "block" }}>
        <Box sx={{ height: "100%" }}>
          <img
            alt="login-img"
            style={{ height: "100dvh", objectFit: "cover", width: "100%" }}
            src={loginImage}
          />
        </Box>
      </Grid>
      <Grid item xs>
        <Box
          sx={{
            height: "100dvh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {showOrgCard && (
            <Stack spacing={4} alignItems={"center"} sx={{ width: 450 }}>
              <img
                alt="logo-img"
                style={{ width: 280, height: 120, marginBottom: "10px" }}
                src={logo1}
              />
              <Typography variant="authTitle">Sign In</Typography>
              <Typography variant="authMessage">
                Please enter your Organisation code
              </Typography>
              <LabelTextField
                label="Organisation Code"
                onTextChange={(e) => orgCodeUpdating(e.target.value)}
                error={orgCode?.trim() === ""}
                textInput={orgCode}
                helperText={
                  orgCode?.trim() === "" && "Pease enter 4 digit numeric code"
                }
              />
              <SubmitButton
                sx={{
                  width: "100%",
                  ":disabled": { backgroundColor: "lightgray" },
                }}
                onClick={handleGetOrganisation}
                disabled={isLoading || (orgCode?.trim() ?? "") === ""}
              >
                Proceed
              </SubmitButton>
            </Stack>
          )}
          {!showOrgCard && (
            <Stack spacing={4} alignItems={"center"} sx={{ width: 450 }}>
              <img
                alt="logo-img"
                style={{ width: 300, height: 120, marginBottom: "10px" }}
                src={logo1}
              />
              <Typography variant="authTitle">Sign In</Typography>
              <Typography variant="authMessage">
                Please sign in to your account
              </Typography>
              <LabelTextField
                label="User ID"
                onTextChange={(e) => updateFormData("username", e.target.value)}
                error={formData.errors.username}
                helperText={formData.errors.username && "Pease enter username"}
              />
              <Stack width={"100%"} alignItems={"flex-end"} spacing={2}>
                <StdLabelPasswordTextField
                  label="Password"
                  variant="outlined"
                  onTextChange={(e) =>
                    updateFormData("password", e.target.value)
                  }
                  error={formData.errors.password}
                  helperText={
                    formData.errors.password && "Pease enter password"
                  }
                />
                {/* <LinkButton>Forgot Password</LinkButton> */}
              </Stack>
              <SubmitButton
                sx={{ width: "100%" }}
                onClick={handleSignInClick}
                disabled={isLoading}
              >
                Sign In
              </SubmitButton>
            </Stack>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

Login.propTypes = {};
