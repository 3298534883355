import { Divider, Stack, Typography } from "@mui/material";

export const DeviceDetailsCard = (props) => {
  const { title, description } = props;

  return (
    <Stack direction={"column"}>
      <Divider />
      <Stack
        justifyContent={"space-between"}
        alignItems={"center"}
        direction={"row"}
        sx={{ pt: 2, pb: 2 }}
      >
        <Typography variant="p1">{title}</Typography>
        <Typography sx={{ textAlign: "end" }} variant="h2">
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
};
