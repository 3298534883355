import { Backdrop, Stack, Typography, useTheme } from "@mui/material";
import { sentAn } from "../../assets";
import PropTypes from "prop-types";

export const SetPasswordLinkSplash = (props) => {
  const { open, onClose, email } = props;

  const theme = useTheme();

  setTimeout(onClose, 6000);

  return (
    <Backdrop
      open={open}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Stack
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          gap: 2,
          width: 600,
          height: 400,
          bgcolor: theme.palette.common.white,
          borderRadius: 3,
        }}
      >
        <img src={sentAn} style={{ width: 150, height: 150 }} />
        <Typography variant="h1" textAlign={"center"}>
          Set password link sent!
        </Typography>
        <Typography variant="p1" textAlign={"center"}>
          We have send the password link to {email}
        </Typography>
      </Stack>
    </Backdrop>
  );
};

SetPasswordLinkSplash.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};
