import React from "react";
import { Box, CircularProgress, useTheme } from "@mui/material";
import PropTypes from "prop-types";

const LoaderCard = (props) => {
  const { height } = props;
  const theme = useTheme();
  return (
    <Box
      height={height}
      sx={{
        borderRadius: "16px",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: theme.palette.common.white,
        display: "flex",
      }}
    >
      <CircularProgress size="30px" sx={{ color: theme.palette.theme.main }} />
    </Box>
  );
};

LoaderCard.propTypes = {
  height: PropTypes.any,
};

LoaderCard.defaultProps = {
  height: "300px",
};

export default LoaderCard;
