import { TextField, useTheme } from "@mui/material";
import PropTypes from "prop-types";

export const PlaceholderTextField = (props) => {
  const {
    placeholder,
    disabled,
    textInput,
    onTextChange,
    width,
    error,
    errorText,
    backColor,
    borderColor,
  } = props;

  const theme = useTheme();

  return (
    <TextField
      fullWidth
      disabled={disabled}
      placeholder={placeholder}
      value={textInput}
      onChange={onTextChange}
      variant="outlined"
      inputProps={{
        sx: {
          fontSize: 16,
          fontWeight: 400,
          color: theme.palette.primary.main,
        },
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: 2,
          "& fieldset": {
            borderColor: borderColor
              ? borderColor
              : theme.palette.secondary.main,
          },
        },
        width: width ? width : "100%",
        backgroundColor: backColor,
        borderRadius: 2,
      }}
      error={error}
      helperText={error ? errorText : ""}
    />
  );
};

PlaceholderTextField.propTypes = {
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  textInput: PropTypes.string,
  onTextChange: PropTypes.func,
  width: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  backColor: PropTypes.string,
  borderColor: PropTypes.string,
};

PlaceholderTextField.defaultProps = {
  disabled: false,
  error: false,
  errorText: "",
};
