import { Fragment } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context";

export function AuthRoute() {
  const { isLoggedIn } = useAuth();

  return (
    <Fragment>
      {isLoggedIn ? <Navigate to="/dashboard" replace /> : <Outlet />}
    </Fragment>
  );
}

export function MainRoute() {
  const { isLoggedIn } = useAuth();

  return (
    <Fragment>
      {isLoggedIn ? <Outlet /> : <Navigate to="/login" replace />}
    </Fragment>
  );
}
