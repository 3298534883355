import { Stack, Typography, useTheme } from "@mui/material";
import { DeviceDetailsCard } from "../../../components/cards/DeviceDetailsCard";
import PropTypes from "prop-types";

export const DeviceDetails = (props) => {
  const { pageTitle, data } = props;
  const theme = useTheme();

  return (
    <Stack
      direction={"column"}
      sx={{
        bgcolor: theme.palette.common.white,
        borderRadius: 3,
        pt: 2,
        pl: 2,
        pr: 2,
        pb: 1,
      }}
    >
      <Typography
        variant="h2"
        sx={{ color: theme.palette.secondary.main, mb: 2 }}
      >
        {pageTitle}
      </Typography>
      {data.map((detail, index) => (
        <DeviceDetailsCard
          key={index}
          title={detail.title}
          description={detail.value}
        />
      ))}
    </Stack>
  );
};
DeviceDetails.propTypes = {
  data: PropTypes.array,
};

DeviceDetails.defaultProps = {
  data: [],
};
