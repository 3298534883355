import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
} from "@mui/material";
import { PageDetailsTab } from "../../pages/DigitalOnboardingSettings/PageDetails/PageDetailsTab";
import { useAPI } from "../../hooks";
import { useToast } from "../../context";

export const AddPageModal = (props) => {
  const { open, onClose, onSubmit } = props;
  const [form, setForm] = useState(null);
  const { api, urls, isLoading } = useAPI();
  const { showToast } = useToast();

  const handleSubmit = () => {
    let params = { ...form };
    console.log("🚀 ~ handleSubmit ~ form:", params);

    let isInvalid = false;
    params.form_field?.map((field) => {
      if (field.label?.trim() === "") {
        isInvalid = true;
      }
    });

    if (isInvalid) {
      showToast("Label text should not be blank", "error");
    } else {
      //API
      params.fields = params.form_field;
      delete params.form_field;
      api
        .post(urls.addFormPage, params)
        .then((res) => {
          showToast("Page created successfully", "success");
          onSubmit();
        })
        .catch((err) => {
          showToast(err.response?.data?.data?.message, "error");
        });
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose} sx={{ p: 2 }}>
      <DialogTitle textAlign={"center"} variant="h1">
        Create Page
      </DialogTitle>
      <DialogContent>
        <PageDetailsTab isNew={true} onPageChange={(data) => setForm(data)} />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Stack direction={"row"} sx={{ gap: 2, pb: 2, width: 450 }}>
          <Button
            onClick={onClose}
            variant="contained"
            color="lightTheme"
            sx={{
              width: "100%",
              height: 50,
              fontSize: 16,
              fontWeight: 700,
              textTransform: "none",
              borderRadius: 2,
            }}
            disableElevation
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            color="theme"
            sx={{
              width: "100%",
              height: 50,
              fontSize: 16,
              fontWeight: 700,
              textTransform: "none",
              borderRadius: 2,
            }}
            disabled={!form?.page_title?.trim() || isLoading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

AddPageModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
