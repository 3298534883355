import { Button, Dialog, Stack, Typography } from "@mui/material";
import { Backdrop } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { LabelDropdownTextField } from "../fields/LabelDropdownTextField";
import { LabelTextField } from "../fields/LabelTextField";
import { useAPI } from "../../hooks";
import { useToast } from "../../context";

export const EditPageAlert = (props) => {
  const { open, data, onClose, pageUpdated } = props;
  console.log(data);

  const { api, urls, isLoading } = useAPI();
  const { showToast } = useToast();

  const [menuItems, setMenuItems] = useState([]);
  const [formData, setFormData] = useState({
    page_title: data.page_title,
    errors: { page_title: false },
  });

  const handleSaveData = () => {
    let invalid = false;

    if (!formData.page_title) {
      setFormData((prev) => {
        const curr = { ...prev };
        prev.errors.page_title = true;
        return curr;
      });
      invalid = true;
    }

    if (!invalid) {
      const params = { ...formData };
      api
        .put(`${urls.editPageTitle}/${data.id}`, params)
        .then((res) => {
          showToast("Page Title updated successfully", "success");
          onClose();
          pageUpdated({id: data.id, page_title: formData.page_title });
        })
        .catch((err) => {
          showToast(err.response?.data?.message, "error");
        });
    }
  };

  const handleClose = () => {
    setFormData({
      page_title: "",
      errors: { page_title: false },
    });
    onClose();
  };

  const updateFormData = (key, value) => {
    setFormData((prev) => {
      const curr = { ...prev };
      curr[key] = value;
      curr.errors[key] = false;
      return curr;
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Stack
        direction={"column"}
        sx={{ width: 400, pt: 3, pl: 4, pr: 4, pb: 4, gap: 3 }}
      >
        <Typography
          variant="h1"
          sx={{
            mb: 2,
            textAlign: "center",
          }}
        >
          Edit Page Title
        </Typography>
        <LabelTextField
          label={"Title"}
          textInput={formData.page_title}
          onTextChange={(event) => {
            updateFormData("page_title", event.target.value);
          }}
          error={formData.errors.page_title}
          helperText={
            formData.errors.page_title ? "Please enter page title" : null
          }
        />
        <Button
          variant="contained"
          disableElevation
          color="theme"
          onClick={handleSaveData}
          sx={{
            height: 50,
            fontSize: 16,
            fontWeight: 700,
            borderRadius: 2,
            textTransform: "none",
          }}
          disabled={isLoading}
        >
          Update
        </Button>
      </Stack>
    </Dialog>
  );
};

EditPageAlert.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  pageUpdated: PropTypes.func,
};
