import { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import { SetPasswordScreen } from "../pages/SetPassword/SetPasswordScreen";
import { Login } from "../pages";
import { AuthRoute } from ".";

export function Authentication() {
  return (
    <Fragment>
      <Routes>
        <Route element={<AuthRoute />}>
          <Route path="/login" element={<Login />} />
          <Route path="/set-password" element={<SetPasswordScreen />} />
        </Route>
      </Routes>
    </Fragment>
  );
}
