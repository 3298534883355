import { Stack, Typography, Divider, Button, useTheme } from "@mui/material";
import { DocumentText1 } from "iconsax-react";
import PropTypes from "prop-types";

export const EDDDetails = (props) => {
  const { pageTitle, data } = props;
  const theme = useTheme();

  return (
    <Stack
      direction={"column"}
      sx={{
        bgcolor: theme.palette.common.white,
        borderRadius: 3,
        pt: 2,
        pl: 2,
        pr: 2,
        pb: 1,
      }}
    >
      <Typography variant="h2" sx={{ color: theme.palette.theme.main, mb: 2 }}>
        {pageTitle}
      </Typography>
      {data.map((detail, index) => (
        <Stack direction={"column"} key={index}>
          <Divider />
          <Stack
            direction={"column"}
            sx={{ pt: 2, pb: 2 }}
            spacing={1}
            // alignItems={"flex-start"}
          >
            <Typography variant="p1" sx={{ fontSize: 14 }}>
              {detail.label}
            </Typography>
            {detail.type === "text" && (
              <Typography sx={{ fontWeight: 700, fontSize: 16 }}>
                {detail.value}
              </Typography>
            )}
            {detail.type === "file" && (
              <Stack
                sx={{
                  py: 2,
                  pl: 2,
                  pr: 1,
                  backgroundColor: theme.palette.background.main,
                  borderRadius: 2,
                }}
                justifyContent={"space-between"}
                direction={"row"}
                alignItems={"center"}
              >
                <DocumentText1 size="28" color={theme.palette.theme.main} />
                <Button
                  variant="text"
                  color="theme"
                  onClick={() => window.open(detail.value, "_blank")}
                  sx={{
                    fontSize: 16,
                    fontWeight: 700,
                    textTransform: "none",
                  }}
                  disableElevation
                >
                  View
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
EDDDetails.propTypes = {
  data: PropTypes.array,
};

EDDDetails.defaultProps = {
  data: [],
};
