import { Box, TextField, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { SearchNormal1 } from "iconsax-react";

export function SearchField(props) {
  const { placeholder, searchInput, onSearchChange } = props;

  const theme = useTheme();

  return (
    <TextField
      value={searchInput}
      placeholder={placeholder}
      onChange={onSearchChange}
      variant="outlined"
      inputProps={{
        sx: {
          fontSize: 16,
          fontWeight: 400,
          color: theme.palette.primary.main,
        },
      }}
      InputProps={{
        startAdornment: (
          <Box sx={{ pr: 1 }}>
            <SearchNormal1 size="24" color={theme.palette.primary.main} />
          </Box>
        ),
      }}
      sx={{
        width: 350,
        bgcolor: theme.palette.highlighterBackground.main,
        borderRadius: 2,
        "& input::placeholder": {
          color: theme.palette.secondary.main,
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: 2,
          "& fieldset": {
            borderColor: theme.palette.placeholder.main,
          },
        },
      }}
    />
  );
}

SearchField.propTypes = {
  placeholder: PropTypes.string,
  searchInput: PropTypes.string,
  onSearchChange: PropTypes.func,
};
