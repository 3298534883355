import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from "@mui/material";
import { PaginationTable } from "./PaginationTable";
import { useNavigate } from "react-router-dom";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

export const UnregisteredUsersULTable = (props) => {
  const {
    data,
    paginationData,
    itemsPerPage,
    setItemsPerPage,
    setPageNumber,
    sortData,
    setSortData,
  } = props;

  const theme = useTheme();
  const navigate = useNavigate();

  const convertUTCtoLocalDateTime = (utcDateTimeString) => {
    const utcDateTime = new Date(utcDateTimeString);
    return utcDateTime.toLocaleString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: true, // specify AM/PM
    });
  };

  const sortingTapped = (column) => {
    switch (column) {
      case "created_at":
        if (sortData.sort_field === "created_at") {
          //reverse the order
          setSortData({
            ...sortData,
            sort_order: sortData.sort_order === "asc" ? "desc" : "asc",
          });
        } else {
          setSortData({
            sort_order: "asc",
            sort_field: "created_at",
          });
        }
        return;
      default:
        return;
    }
  };

  return (
    <Stack
      direction={"column"}
      sx={{
        bgcolor: theme.palette.common.white,
        borderRadius: 3,
        pt: "12px",
        pl: 2,
        pr: 2,
        pb: "12px",
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ height: 70 }}>
              <TableCell sx={{ py: "12px", width: 120 }}>
                <Typography variant="h2">No.</Typography>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <Typography variant="h2">Mobile No.</Typography>
              </TableCell>

              <TableCell sx={{ py: 0 }}>
                <TableSortLabel
                  active={true}
                  direction="asc"
                  sx={{
                    "&.MuiTableSortLabel-root": {
                      color: "black",
                    },
                  }}
                  IconComponent={
                    sortData.sort_field === "created_at"
                      ? sortData.sort_order === "asc"
                        ? ArrowDownwardIcon
                        : ArrowUpwardIcon
                      : UnfoldMoreIcon
                  }
                  onClick={() => sortingTapped("created_at")}
                >
                  <Typography variant="h2">Date & Time</Typography>
                </TableSortLabel>
              </TableCell>
              {/* <TableCell sx={{ py:0 }} /> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((user, index) => (
              <TableRow key={index}>
                <TableCell sx={{ py: "12px" }}>
                  <Typography variant="p1">
                    {itemsPerPage * (paginationData.page - 1) + index + 1}
                  </Typography>
                </TableCell>
                <TableCell sx={{ py: 0 }}>
                  <Typography variant="p1">{`${user.phone_code} ${user.phone_number}`}</Typography>
                </TableCell>
                <TableCell sx={{ py: 0 }}>
                  <Typography variant="p1">
                    {user.created_at
                      ? convertUTCtoLocalDateTime(user.created_at)
                      : ""}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationTable
        page={paginationData.page - 1}
        onSetPage={(page) => setPageNumber(page + 1)} //page start with zero
        rowsPerPage={itemsPerPage}
        onSetRowsPerPage={setItemsPerPage}
        totalCount={paginationData.total_count}
      />
    </Stack>
  );
};
