import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useTheme,
  Avatar,
  Chip,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import PropTypes from "prop-types";
import { SearchField } from "../fields/SearchField";
import { Add, ArrowLeft2 } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { Fragment, useState, useEffect } from "react";
import Logout from "@mui/icons-material/Logout";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { ReminderAlert } from "../alert/ReminderAlert";
import { SessionExpiredPopup } from "../alert/SessionExpiredPopup";
import { ChangePasswordAlert } from "../alert/ChangePasswordAlert";
import { useAuth } from "../../context";
import { useIdleTimer } from "react-idle-timer";
import { useModal } from "../../hooks";

export const ActionBar = (props) => {
  const { header, showBackButton, onSearchTextChange, onAddClick } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [logoutAlert, setLogoutAlert] = useState(false);
  const { logout, userName, userEmail, isAdmin, adminRole, orgName } =
    useAuth();
  const [isChangePassword, openChangePassword, closeChangePassword] =
    useModal();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickLogout = () => {
    setAnchorEl(null);
    setLogoutAlert(true);
  };

  const changePasswordTapped = () => {
    setAnchorEl(null);
    openChangePassword();
  };

  const getInitials = (text) => {
    // Trim the input text to remove leading and trailing spaces
    text = text.trim();

    if (text === "") {
      return "";
    } else {
      // Split the trimmed text into an array of words
      const words = text.split(" ");

      // Initialize variables to store initials
      let firstInitial = "";
      let secondInitial = "";

      // Check if there's at least one word
      if (words.length > 0) {
        // Extract the first character of the first word as the first initial
        firstInitial = words[0][0].toUpperCase();

        // Check if there's a second word available
        if (words.length > 1) {
          // Extract the first character of the second word as the second initial
          secondInitial = words[1][0].toUpperCase();
        } else if (words[0].length > 1) {
          // If there's no second word but the first word has more than one character,
          // use the second character of the first word as the second initial
          secondInitial = words[0][1].toUpperCase();
        }
      }

      // Return the concatenated initials
      return firstInitial + secondInitial;
    }
  };

  const theme = useTheme();
  const navigate = useNavigate();

  //Auto logout
  const [state, setState] = useState("Active");
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const [sessionExpired, setSessionExpired] = useState(false);

  function secondsToMinutes(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  }

  const onIdle = () => {
    setSessionExpired(true);
    setTimeout(logout, 2000); // Logout after 2 seconds (2000 milliseconds)
  };

  const onActive = () => {};

  const onAction = () => {
    setCount(count + 1);
  };
  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 600000,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <Fragment>
      <ReminderAlert
        open={logoutAlert}
        onClose={() => setLogoutAlert(false)}
        onConfirm={() => {
          setLogoutAlert(false);
          logout();
        }}
        header={"Logout"}
        reminder={"Are you sure want to logout from this account?"}
        closeText={"Cancel"}
        confirmText={"Logout"}
      />
      <SessionExpiredPopup
        open={sessionExpired}
        onConfirm={() => {
          setSessionExpired(false);
        }}
        header={"Session Expired"}
        reminder={"Please login to continue"}
        confirmText={"Login"}
      />
      {isChangePassword && (
        <ChangePasswordAlert
          open={isChangePassword}
          onClose={closeChangePassword}
          onSubmit={closeChangePassword}
        />
      )}

      <Stack direction={"column"}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            height: 90,
            pl: 2,
            pr: 4,
          }}
        >
          <Stack direction={"row"} alignItems={"center"} sx={{ gap: 2 }}>
            {showBackButton && (
              <Box
                justifyContent={"center"}
                alignItems={"center"}
                display={"flex"}
                onClick={() => navigate(-1)}
                sx={{
                  width: 30,
                  height: 30,
                  bgcolor: theme.palette.themeHover.main,
                  borderRadius: 4,
                  cursor: "pointer",
                }}
              >
                <ArrowLeft2 size="18" color={theme.palette.theme.main} />
              </Box>
            )}
            {/* <Typography variant="h1">{header}</Typography> */}
            {(header === "Digital Onboarding Customers" ||
              header === "Authentication Customers" ||
              header === "Unverified Customers" ||
              header === "Unregistered List" ||
              header === "Customer Archives" ||
              header === "Admin Users" ||
              header.includes("Transactions")) && (
              <SearchField
                placeholder={
                  header === "Digital Onboarding Customers" ||
                  header === "Authentication Customers"
                    ? "Search by Name, Phone or Document ID"
                    : header === "Unverified Customers"
                    ? "Search by Name or Phone Number"
                    : header === "Unregistered List"
                    ? "Search by Phone Number"
                    : header === "Customer Archives"
                    ? "Search by Name, Phone or ID Number"
                    : header === "Admin Users"
                    ? "Search by Name or Email ID"
                    : header.includes("Transactions")
                    ? "Search by Name or Transaction ID"
                    : "Search"
                }
                onSearchChange={onSearchTextChange}
              />
            )}
          </Stack>

          <Stack direction={"row"} alignItems={"center"} sx={{ gap: 2 }}>
            {/* <Chip
              variant="caption"
              label={`Session Expiry - ${secondsToMinutes(remaining)}`}
            /> */}

            {/* {(header === "Admin Users" || header === "Admin Roles") && (
              <Button
                variant="contained"
                disableElevation
                color="theme"
                startIcon={<Add size="30" color={theme.palette.common.white} />}
                onClick={onAddClick}
                sx={{
                  height: 55,
                  pl: 4,
                  pr: 4,
                  borderRadius: 2,
                  fontSize: 16,
                  fontWeight: 700,
                  textTransform: "none",
                }}
              >
                {header === "Admin Users"
                  ? "Add User"
                  : header === "Admin Roles"
                  ? "Add Role"
                  : ""}
              </Button>
            )} */}

            <Avatar
              sx={{
                bgcolor: theme.palette.theme.main,
                color: "white",
                cursor: "pointer",
              }}
              onClick={handleClick}
            >
              {getInitials(userName ?? "--")}
            </Avatar>
          </Stack>
        </Stack>
        <Divider />
      </Stack>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 4px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
            minWidth: "200px",
            p: 1,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Stack
          textAlign={"center"}
          alignItems={"center"}
          direction={"column"}
          spacing={2}
          p={2}
        >
          <Avatar
            sx={{
              bgcolor: theme.palette.theme.main,
              color: "white",
              width: "45px !important",
              height: "45px !important",
            }}
          >
            {getInitials(userName ?? "--")}
          </Avatar>
          <Stack direction={"column"} spacing={0}>
            <Typography variant="h2" sx={{ fontSize: 16 }}>
              {userName ?? "--"}
            </Typography>
            <Typography color={"gray"}>
              {isAdmin ? "Admin" : adminRole?.role_name}
            </Typography>
            <Typography sx={{ fontWeight: 500 }} color={"gray"}>
              {orgName}
            </Typography>
          </Stack>

          <Typography color={"gray"}>{userEmail ?? "--"}</Typography>
        </Stack>
        <Divider />
        <MenuItem
          onClick={changePasswordTapped}
          sx={{ mt: 1, pt: 1, ":hover": { bgcolor: "ligtGray" } }}
        >
          <ListItemIcon>
            <LockOpenIcon fontSize="small" />
          </ListItemIcon>
          Change Password
        </MenuItem>
        <MenuItem
          onClick={onClickLogout}
          sx={{ mt: 1, pt: 1, ":hover": { bgcolor: "ligtGray" } }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

ActionBar.propTypes = {
  header: PropTypes.string.isRequired,
  showBackButton: PropTypes.bool,
  onSearchTextChange: PropTypes.func,
  onAddClick: PropTypes.func,
};

ActionBar.defaultProps = {
  showBackButton: false,
};
