import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from "@mui/material";
import { Status } from "../basic/Status";
import { PaginationTable } from "./PaginationTable";
import { useNavigate } from "react-router-dom";
import NoDataCard from "../cards/NoDataCard";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

export const UsersCOMTable = (props) => {
  const {
    data,
    paginationData,
    itemsPerPage,
    setItemsPerPage,
    setPageNumber,
    sortData,
    setSortData,
  } = props;

  const theme = useTheme();
  const navigate = useNavigate();

  const convertUTCtoLocalDateTime = (utcDateTimeString) => {
    const utcDateTime = new Date(utcDateTimeString);
    return utcDateTime.toLocaleString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: true, // specify AM/PM
    });
  };

  const sortingTapped = (column) => {
    switch (column) {
      case "full_name":
        if (sortData.sort_field === "full_name") {
          //reverse the order
          setSortData({
            ...sortData,
            sort_order: sortData.sort_order === "asc" ? "desc" : "asc",
          });
        } else {
          setSortData({
            sort_order: "asc",
            sort_field: "full_name",
          });
        }
        return;
      case "nationality":
        if (sortData.sort_field === "nationality") {
          //reverse the order
          setSortData({
            ...sortData,
            sort_order: sortData.sort_order === "asc" ? "desc" : "asc",
          });
        } else {
          setSortData({
            sort_order: "asc",
            sort_field: "nationality",
          });
        }
        return;
      case "device_model":
        if (sortData.sort_field === "device_model") {
          //reverse the order
          setSortData({
            ...sortData,
            sort_order: sortData.sort_order === "asc" ? "desc" : "asc",
          });
        } else {
          setSortData({
            sort_order: "asc",
            sort_field: "device_model",
          });
        }
        return;
      case "created_at":
        if (sortData.sort_field === "created_at") {
          //reverse the order
          setSortData({
            ...sortData,
            sort_order: sortData.sort_order === "asc" ? "desc" : "asc",
          });
        } else {
          setSortData({
            sort_order: "asc",
            sort_field: "created_at",
          });
        }
        return;
      case "device_status":
        if (sortData.sort_field === "device_status") {
          //reverse the order
          setSortData({
            ...sortData,
            sort_order: sortData.sort_order === "asc" ? "desc" : "asc",
          });
        } else {
          setSortData({
            sort_order: "asc",
            sort_field: "device_status",
          });
        }
        return;
      default:
        return;
    }
  };

  return (
    <Stack
      direction={"column"}
      sx={{
        bgcolor: theme.palette.common.white,
        borderRadius: 3,
        pt: "12px",
        pl: 2,
        pr: 2,
        pb: "12px",
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ height: 70 }}>
              <TableCell sx={{ py: "12px" }}>
                <Typography variant="h2">No.</Typography>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <TableSortLabel
                  active={true}
                  direction="asc"
                  sx={{
                    "&.MuiTableSortLabel-root": {
                      color: "black",
                    },
                  }}
                  IconComponent={
                    sortData.sort_field === "full_name"
                      ? sortData.sort_order === "asc"
                        ? ArrowDownwardIcon
                        : ArrowUpwardIcon
                      : UnfoldMoreIcon
                  }
                  onClick={() => sortingTapped("full_name")}
                >
                  <Typography variant="h2">Name</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <TableSortLabel
                  active={true}
                  direction="asc"
                  sx={{
                    "&.MuiTableSortLabel-root": {
                      color: "black",
                    },
                  }}
                  IconComponent={
                    sortData.sort_field === "nationality"
                      ? sortData.sort_order === "asc"
                        ? ArrowDownwardIcon
                        : ArrowUpwardIcon
                      : UnfoldMoreIcon
                  }
                  onClick={() => sortingTapped("nationality")}
                >
                  <Typography variant="h2">Nationality</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <Typography variant="h2">Document ID</Typography>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <TableSortLabel
                  active={true}
                  direction="asc"
                  sx={{
                    "&.MuiTableSortLabel-root": {
                      color: "black",
                    },
                  }}
                  IconComponent={
                    sortData.sort_field === "device_model"
                      ? sortData.sort_order === "asc"
                        ? ArrowDownwardIcon
                        : ArrowUpwardIcon
                      : UnfoldMoreIcon
                  }
                  onClick={() => sortingTapped("device_model")}
                >
                  <Typography variant="h2">Device Name</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <Typography variant="h2">Mobile No.</Typography>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <TableSortLabel
                  active={true}
                  direction="asc"
                  sx={{
                    "&.MuiTableSortLabel-root": {
                      color: "black",
                    },
                  }}
                  IconComponent={
                    sortData.sort_field === "created_at"
                      ? sortData.sort_order === "asc"
                        ? ArrowDownwardIcon
                        : ArrowUpwardIcon
                      : UnfoldMoreIcon
                  }
                  onClick={() => sortingTapped("created_at")}
                >
                  <Typography variant="h2">Created on</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <TableSortLabel
                  active={true}
                  direction="asc"
                  sx={{
                    "&.MuiTableSortLabel-root": {
                      color: "black",
                    },
                  }}
                  IconComponent={
                    sortData.sort_field === "device_status"
                      ? sortData.sort_order === "asc"
                        ? ArrowDownwardIcon
                        : ArrowUpwardIcon
                      : UnfoldMoreIcon
                  }
                  onClick={() => sortingTapped("device_status")}
                >
                  <Typography variant="h2">Device Status</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ py: 0 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((user, index) => {
              return (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  key={index}
                >
                  <TableCell sx={{ py: "12px" }}>
                    <Typography variant="p1">
                      {itemsPerPage * (paginationData.page - 1) + index + 1}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0, maxWidth: "250px" }}>
                    <Typography variant="p1">
                      {user.full_name ? user.full_name : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0 }}>
                    <Typography variant="p1">{user.nationality}</Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0 }}>
                    <Typography variant="p1">
                      {user.pin ? user.pin : user.national_id}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0 }}>
                    <Typography variant="p1">
                      {user.device.device_model
                        ? user.device.device_model
                        : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0 }}>
                    <Typography variant="p1">
                      {user.phone_code} {user.phone_number}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0 }}>
                    <Typography variant="p1">
                      {user.created_at
                        ? convertUTCtoLocalDateTime(user.created_at)
                        : ""}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0 }}>
                    <Status
                      type={
                        user.device.device_status
                          ? user.device.device_status
                          : "Unsecure"
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ py: 0 }} align="right">
                    <Button
                      variant="contained"
                      color="theme"
                      onClick={() =>
                        navigate(`/customers/details/${user.id}`, {
                          state: { clearData: true },
                        })
                      }
                      sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        textTransform: "none",
                        maxWidth: "55px",
                        minWidth: "30px",
                        minHeight: "28px",
                        maxHeight: "28px",
                      }}
                      disableElevation
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length === 0 && <NoDataCard title={"No Data Found"} />}
      <PaginationTable
        page={paginationData.page - 1}
        onSetPage={(page) => setPageNumber(page + 1)} //page start with zero
        rowsPerPage={itemsPerPage}
        onSetRowsPerPage={setItemsPerPage}
        totalCount={paginationData.total_count}
      />
    </Stack>
  );
};
