import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { PlaceholderTextField } from "../fields/PlaceholderTextField";
import { PlainCheckbox } from "../checkbox/PlainCheckbox";
import { useAPI, useModal } from "../../hooks";
import { useToast } from "../../context";

export const AddAdminRoleModal = (props) => {
  const { open, data, existingRoles, onClose, roleCreated } = props;
  const { api, urls, isLoading } = useAPI();
  const { showToast } = useToast();

  const [permissionList, setPermissionList] = useState(data);
  const [roleName, setRoleName] = useState("");
  const [nameDuplicateFound, setNameDuplicateFound] = useState(false);

  const theme = useTheme();

  const handleSubmit = () => {
    let params = { role_name: roleName, permissions: permissionList };
    // api
    api
      .post(urls.addNewRole, params)
      .then((res) => {
        roleCreated();
      })
      .catch((err) => {
        showToast(err.response?.data?.data?.message, "error");
      });
  };

  const viewUpdated = (flag, index) => {
    const updatedPermissions = permissionList.map((permission, i) => {
      // If the current index matches the index of the permission being updated
      if (i === index) {
        // Return a new object with updated view property
        return {
          ...permission,
          n_a: 0,
          view: flag ? 1 : 0,
          change: !flag
            ? permission.change === null
              ? null
              : 0
            : permission.change,
        };
      }
      // Otherwise, return the permission as is
      return permission;
    });
    // Update the state with the new array
    setPermissionList(updatedPermissions);
  };

  const editUpdated = (flag, index) => {
    const updatedPermissions = permissionList.map((permission, i) => {
      // If the current index matches the index of the permission being updated
      if (i === index) {
        // Return a new object with updated view property
        return {
          ...permission,
          n_a: 0,
          view: flag ? 1 : permission.view,
          change: flag ? 1 : 0,
        };
      }
      // Otherwise, return the permission as is
      return permission;
    });
    // Update the state with the new array
    setPermissionList(updatedPermissions);
  };

  const naUpdated = (flag, index) => {
    const updatedPermissions = permissionList.map((permission, i) => {
      // If the current index matches the index of the permission being updated
      if (i === index) {
        // Return a new object with updated view property
        return {
          ...permission,
          view: permission.view === null ? null : 0,
          change: permission.change === null ? null : 0,
          n_a: flag ? 1 : 0,
        };
      }
      // Otherwise, return the permission as is
      return permission;
    });
    // Update the state with the new array
    setPermissionList(updatedPermissions);
  };

  const roleTextUpdated = (text) => {
    setNameDuplicateFound(
      existingRoles.filter((role) => role.role_name === text).length > 0
    );
    setRoleName(text);
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose} sx={{ p: 2 }}>
      <DialogTitle textAlign={"center"} variant="h1">
        Create Admin Role
      </DialogTitle>
      <DialogContent>
        <Box
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
          sx={{
            p: 2,
            bgcolor: theme.palette.common.white,
            borderRadius: 3,
          }}
        >
          <Stack
            direction={"column"}
            sx={{
              width: "100%",
              borderRadius: 3,
              gap: 2,
            }}
          >
            <Typography variant="h1">Role Settings</Typography>
            <Box
              sx={{
                bgcolor: theme.palette.popupBlue.main,
                borderRadius: 3,
                p: 3,
              }}
            >
              <PlaceholderTextField
                placeholder="Role"
                textInput={roleName}
                onTextChange={(e) => {
                  roleTextUpdated(e.target.value);
                }}
                error={nameDuplicateFound}
                errorText={"Role name already exist"}
                backColor={"white"}
                borderColor="#CBE4FF"
              />
            </Box>
            <Typography variant="h1">Permissions</Typography>
            {/* <AdminRolesARTable
              data={permissionList}
              setPermissionList={setPermissionList}
            /> */}
            <Stack
              direction={"column"}
              sx={{
                width: "100%",
                bgcolor: theme.palette.popupBlue.main,
                borderRadius: 3,
                pt: "12px",
                pb: "12px",
                px: 1,
              }}
            >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow sx={{ height: 70 }}>
                      <TableCell sx={{ py: "12px", width: 50 }}>
                        <Typography variant="h2">No.</Typography>
                      </TableCell>
                      <TableCell sx={{ py: 0 }} />
                      <TableCell sx={{ py: 0 }} align="center">
                        <Typography variant="h2" sx={{ fontSize: "16px" }}>
                          View
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ py: 0 }} align="center">
                        <Typography variant="h2" sx={{ fontSize: "16px" }}>
                          Edit
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ py: 0 }} align="center">
                        <Typography variant="h2" sx={{ fontSize: "16px" }}>
                          N/A
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {permissionList.map((permssion, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell sx={{ py: "12px" }}>
                            <Typography
                              variant="p1"
                              sx={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {index + 1}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ py: 0 }}>
                            <Typography
                              variant="p1"
                              sx={{ fontSize: "15px", fontWeight: 500 }}
                            >
                              {permssion.title}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ py: 0 }} align="center">
                            <PlainCheckbox
                              checked={permssion.view === 1}
                              onChange={(e, flag) => viewUpdated(flag, index)}
                              disabled={permssion.view === null || index === 0}
                            />
                          </TableCell>
                          <TableCell sx={{ py: 0 }} align="center">
                            <PlainCheckbox
                              checked={permssion.change === 1}
                              onChange={(e, flag) => editUpdated(flag, index)}
                              disabled={permssion.change === null}
                            />
                          </TableCell>

                          <TableCell sx={{ py: 0 }} align="center">
                            <PlainCheckbox
                              checked={permssion.n_a === 1}
                              onChange={(e, flag) => naUpdated(flag, index)}
                              disabled={permssion.n_a === null}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Stack direction={"row"} sx={{ gap: 2, pb: 2, width: 420 }}>
          <Button
            onClick={onClose}
            variant="contained"
            color="lightTheme"
            sx={{
              width: "100%",
              height: 50,
              fontSize: 16,
              fontWeight: 700,
              textTransform: "none",
              borderRadius: 2,
            }}
            disableElevation
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            color="theme"
            sx={{
              width: "100%",
              height: 50,
              fontSize: 16,
              fontWeight: 700,
              textTransform: "none",
              borderRadius: 2,
            }}
            onClick={handleSubmit}
            disabled={roleName.trim() === "" || nameDuplicateFound || isLoading}
          >
            Submit
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

AddAdminRoleModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
