import { Box, Typography, useTheme, alpha } from "@mui/material";
import PropTypes from "prop-types";

export const Status = (props) => {
  const { type } = props;

  const theme = useTheme();

  let bgcolor = theme.palette.status.default;
  if (type.toLowerCase() === "n/a" || type.toLowerCase() === "na") {
    bgcolor = theme.palette.status.notApplicable;
  } else if (type.toLowerCase() === "pending") {
    bgcolor = theme.palette.status.pending;
  } else if (type.toLowerCase() === "in progress") {
    bgcolor = theme.palette.status.inProgress;
  } else if (type.toLowerCase() === "secure") {
    bgcolor = theme.palette.status.secure;
  } else if (type.toLowerCase() === "unsecure") {
    bgcolor = theme.palette.status.unsecure;
  } else if (type.toLowerCase() === "approved") {
    bgcolor = theme.palette.status.approved;
  } else if (type.toLowerCase() === "pin verified") {
    bgcolor = theme.palette.status.approved;
  } else if (type.toLowerCase() === "rejected") {
    bgcolor = theme.palette.status.rejected;
  } else if (type.toLowerCase() === "active") {
    bgcolor = theme.palette.status.active;
  } else if (type.toLowerCase() === "inactive") {
    bgcolor = theme.palette.status.inactive;
  } else if (type.toLowerCase() === "success") {
    bgcolor = theme.palette.status.success;
  } else if (type.toLowerCase() === "failed") {
    bgcolor = theme.palette.status.failed;
  } else if (type.toLowerCase() === "verification successful") {
    bgcolor = theme.palette.status.verificationSuccessful;
  } else if (type.toLowerCase() === "verification failed") {
    bgcolor = theme.palette.status.verificationFailed;
  } else if (type.toLowerCase() === "transaction completed") {
    bgcolor = theme.palette.status.transactionCompleted;
  } else if (type.toLowerCase() === "transaction in progress") {
    bgcolor = theme.palette.status.transactionInProgress;
  } else if (type.toLowerCase() === "transaction rejected") {
    bgcolor = theme.palette.status.transactionRejected;
  } else if (type.toLowerCase() === "transaction failed") {
    bgcolor = theme.palette.status.transactionFailed;
  } else if (type.toLowerCase() === "timeout") {
    bgcolor = theme.palette.status.failed;
  } else if (type.toLowerCase() === "completed") {
    bgcolor = theme.palette.status.approved;
  } else if (type.toLowerCase() === "initiated") {
    bgcolor = theme.palette.status.transactionInProgress;
  } else if (type.toLowerCase() === "cancelled") {
    bgcolor = theme.palette.status.failed;
  }
  return (
    <Box
      justifyContent={"center"}
      textAlign={"center"}
      display={"flex"}
      sx={{
        width:
          type.toLowerCase() === "user rejected"
            ? 100
            : type.toLowerCase() === "verification successful" ||
              type.toLowerCase() === "verification failed" ||
              type.toLowerCase() === "transaction completed" ||
              type.toLowerCase() === "transaction in progress" ||
              type.toLowerCase() === "transaction rejected" ||
              type.toLowerCase() === "transaction failed"
            ? 150
            : 80,
        pt: "4px",
        pl: "12px",
        pr: "12px",
        pb: "4px",
        bgcolor: alpha(bgcolor, 0.3),
        borderRadius: 4,
      }}
    >
      <Typography
        variant="p2"
        sx={{
          color: bgcolor,
          fontWeight: 500,
        }}
      >
        {type}
      </Typography>
    </Box>
  );
};

Status.propTypes = {
  type: PropTypes.string,
};
