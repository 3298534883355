import { ActionBar } from "../../components/headers/ActionBar";
import { AuthenticationListTable } from "../../components/table/AuthenticationListTable";
import { Fragment } from "react";
import { PageStackLayout } from "../../components/layout/PageStackLayout";
import { useAPI } from "../../hooks";
import { useToast, useAuth } from "../../context";
import LoaderCard from "../../components/cards/LoaderCard";
import { useState, useEffect } from "react";
import { Typography } from "@mui/material";

export const AuthenticationCustomersScreen = () => {
  const { showToast } = useToast();
  const { logout } = useAuth();
  const { api, urls, isLoading } = useAPI();

  const [requestData, setRequestData] = useState({
    itemsPerPage: 25,
    currentPage: 1,
    searchText: "",
    sortData: {
      sort_field: null,
      sort_order: "asc",
    },
  });

  const [customersList, setCustomersList] = useState([]);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    limit: 0,
    total_count: 0,
  });
  const [timer, setTimer] = useState(null);

  //api
  const getCustomersList = async () => {
    const { itemsPerPage, currentPage, searchText, sortData } = requestData;
    api
      .get(
        `${urls.customers}?type=Risk&page=${currentPage}&limit=${itemsPerPage}&search=${searchText}&sort_field=${sortData.sort_field}&sort_order=${sortData.sort_order}`
      )
      .then((res) => {
        let data = res.data.data;
        setCustomersList(data.data);
        setPaginationData(data.pagination);
      })
      .catch(handleApiError);
  };

  //error handling
  const handleApiError = (err) => {
    if (err.response?.status === 401) {
      logout();
    } else {
      console.log("err", err);
      showToast(err.response?.data?.message, "error");
    }
  };

  //useEffect
  useEffect(() => {
    getCustomersList();
  }, [requestData]);

  const updateRequestData = (newData) => {
    setRequestData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  // const onSearchTextChange = (event) => {
  //   updateRequestData({
  //     searchText: event.target.value,
  //     currentPage: 1,
  //     sortData: {
  //       sort_field: null,
  //       sort_order: "asc",
  //     },
  //   });
  // };

  const onSearchTextChange = (event) => {
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      updateRequestData({
        searchText: event.target.value,
        currentPage: 1,
        sortData: {
          sort_field: null,
          sort_order: "asc",
        },
      });
    }, 500);

    setTimer(newTimer);
  };

  return (
    <Fragment>
      <ActionBar
        header={"Authentication Customers"}
        onSearchTextChange={onSearchTextChange}
      />
      <PageStackLayout>
        <Typography variant="h1">Authentication Customers</Typography>
        {isLoading && <LoaderCard />}
        {!isLoading && (
          <AuthenticationListTable
            data={customersList}
            paginationData={paginationData}
            itemsPerPage={requestData.itemsPerPage}
            setItemsPerPage={(count) =>
              updateRequestData({ itemsPerPage: count, currentPage: 1 })
            }
            setPageNumber={(page) => updateRequestData({ currentPage: page })}
            sortData={requestData.sortData}
            setSortData={(data) => updateRequestData({ sortData: data })}
          />
        )}
      </PageStackLayout>
    </Fragment>
  );
};
