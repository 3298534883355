import { Backdrop, Button, Dialog, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

export const SessionExpiredPopup = (props) => {
  const { open, onConfirm, header, reminder, confirmText, buttonColor } = props;

  return (
    <Dialog
      open={open}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Stack
        direction={"column"}
        sx={{ width: 300, pt: 3, pl: 4, pr: 4, pb: 4, gap: 3 }}
      >
        <Typography
          variant="h1"
          sx={{
            textAlign: "center",
          }}
        >
          {header}
        </Typography>
        <Typography
          variant="p1"
          sx={{
            textAlign: "center",
          }}
        >
          {reminder}
        </Typography>
        <Stack direction={"row"} sx={{ gap: 2 }}>
          <Button
            onClick={() => onConfirm()}
            variant="contained"
            disableElevation
            color={"theme"}
            sx={{
              width: "100%",
              height: 50,
              fontSize: 16,
              fontWeight: 700,
              textTransform: "none",
              borderRadius: 2,
            }}
          >
            {confirmText}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

SessionExpiredPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
};
