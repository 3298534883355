import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { Status } from "../basic/Status";
import { DocumentText1 } from "iconsax-react";
import PropTypes from "prop-types";

export const ReviewCard = (props) => {
  const { title, reviewData, showDivider } = props;

  const theme = useTheme();

  function formatDate(isoDate) {
    const date = new Date(isoDate);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month starts from 0
    const year = date.getFullYear();

    // Pad day and month with leading zero if they are less than 10
    const formattedDay = day < 10 ? "0" + day : day;
    const formattedMonth = month < 10 ? "0" + month : month;

    return formattedDay + "/" + formattedMonth + "/" + year;
  }

  function getFileNameFromUrl(url) {
    // Split the URL by '/'
    const parts = url.split("/");
    // Get the last part of the split, which should be the file name
    const fileName = parts[parts.length - 1];
    return fileName;
  }

  return (
    <Stack direction={"column"} sx={{ pt: 1, pb: 1, gap: 2 }}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="p1">{title}</Typography>
        <Status type={reviewData.title} />
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        spacing={2}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ gap: 2 }}
          width={"50%"}
        >
          {(reviewData.file_url ?? "" !== "") && (
            <Box
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
              sx={{
                width: 45,
                height: 60,
                borderRadius: "8px",
                bgcolor: theme.palette.themeHover.main,
                cursor: "pointer",
              }}
              onClick={() => window.open(reviewData.file_url, "_blank")}
            >
              <DocumentText1 size="20" color={theme.palette.theme.main} />
            </Box>
          )}
          <Stack direction={"column"} sx={{ gap: "4px" }}>
            {(reviewData.file_url ?? "") !== "" && (
              <Typography
                variant="p1"
                sx={{ cursor: "pointer", color: theme.palette.theme.main }}
                onClick={() => window.open(reviewData.file_url, "_blank")}
              >
                {/* {getFileNameFromUrl(reviewData.file_url)} */}
                Click here to view the file
              </Typography>
            )}
            {(reviewData.file_url ?? "") === "" && (
              <Typography variant="p1">No Attachment</Typography>
            )}
            <Typography
              variant="p2"
              sx={{ color: theme.palette.secondary.main }}
            >
              {reviewData.title} on {formatDate(reviewData.created_at)}
            </Typography>
            <Typography
              variant="p2"
              sx={{ color: theme.palette.secondary.main }}
            >
              {reviewData.title} by {reviewData.admin_name}
            </Typography>
          </Stack>
        </Stack>
        <Typography variant="p1" sx={{ color: theme.palette.secondary.main }}>
          {reviewData.description}
        </Typography>
      </Stack>
      {showDivider && <Divider />}
    </Stack>
  );
};

ReviewCard.propTypes = {
  title: PropTypes.string,
  reviewData: PropTypes.any,
  showDivider: PropTypes.bool,
};

ReviewCard.defaultProps = {
  showDivider: true,
};
