import { Fragment, useState, useRef } from "react";
import {
  Grid,
  Button,
  Stack,
  Box,
  Divider,
  Typography,
  TextField,
  Modal,
  useTheme,
  alpha,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import PropTypes from "prop-types";
import { useAPI } from "../../../hooks";
import { useToast, useAuth } from "../../../context";

const ApproveRejectPopup = (props) => {
  const theme = useTheme();
  const {
    show,
    handleClose,
    isApprove,
    isApiCalling,
    onClickApprove,
    onClickReject,
  } = props;

  const [selectedFile, setSelectedFile] = useState(null);
  const [reviewText, setReviewText] = useState("");
  const fileInputRef = useRef(null);

  const showFilePicker = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  return (
    <Fragment>
      <Modal
        open={show}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(4),
            borderRadius: theme.spacing(2),
          }}
        >
          <Stack
            direction={"column"}
            spacing={2}
            justifyContent={"center"}
            textAlign={"center"}
          >
            <Typography
              variant="h2"
              sx={{ fontWeight: 700, fontSize: 24 }}
              pb={1}
            >
              {isApprove ? "Approve User" : "Reject User"}
            </Typography>
            <Box
              sx={{
                border: `2px dotted ${theme.palette.theme.main}`,
                padding: theme.spacing(2),
                marginBottom: theme.spacing(2),
                borderRadius: 2,
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                width: "350px",
                height: "20px",
                display: "flex",
                cursor: "pointer",
                ":hover": {
                  backgroundColor: alpha(theme.palette.theme.main, 0.1),
                },
              }}
              onClick={showFilePicker}
            >
              <ArrowUpwardIcon
                sx={{ width: 20, color: theme.palette.theme.main }}
              />
              <Typography
                variant="body"
                sx={{ fontWeight: 700, fontSize: 16, textAlign: "center" }}
                color={theme.palette.theme.main}
              >
                Upload file
              </Typography>
            </Box>

            {selectedFile && (
              <Typography
                variant="body"
                sx={{
                  fontWeight: 400,
                  fontSize: 14,
                  textAlign: "left",
                  fontStyle: "italic",
                }}
              >
                *{selectedFile?.name}
              </Typography>
            )}

            <TextField
              fullWidth
              label={"Reason"}
              variant="outlined"
              inputProps={{
                sx: {
                  fontSize: 16,
                  fontWeight: 400,
                  color: theme.palette.primary.main,
                },
              }}
              multiline
              maxRows={4}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                  "& fieldset": {
                    borderColor: theme.palette.textFieldBorder.main,
                  },
                },
              }}
              value={reviewText}
              onChange={(e) => setReviewText(e.target.value)}
            />
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: "none" }}
              accept=".jpg, .jpeg, .png, .gif, .pdf, .avif"
              onChange={handleFileChange}
            />
            <Stack direction={"row"} sx={{ my: 1, gap: 2, height: 50 }}>
              <Button
                variant="outlined"
                disableElevation
                color="theme"
                sx={{
                  width: "100%",
                  height: "100%",
                  fontSize: 16,
                  fontWeight: 700,
                  borderRadius: 2,
                  textTransform: "none",
                }}
                onClick={handleClose}
                disabled={isApiCalling}
              >
                Cancel
              </Button>
              {!isApprove && (
                <Button
                  variant="contained"
                  disableElevation
                  color="btnNegative"
                  sx={{
                    width: "100%",
                    height: "100%",
                    fontSize: 16,
                    fontWeight: 700,
                    borderRadius: 2,
                    textTransform: "none",
                  }}
                  onClick={() => onClickReject(selectedFile, reviewText)}
                  disabled={isApiCalling}
                >
                  Reject
                </Button>
              )}
              {isApprove && (
                <Button
                  variant="contained"
                  disableElevation
                  color="btnPositive"
                  sx={{
                    width: "100%",
                    height: "100%",
                    fontSize: 16,
                    fontWeight: 700,
                    borderRadius: 2,
                    textTransform: "none",
                  }}
                  onClick={() => onClickApprove(selectedFile, reviewText)}
                  disabled={isApiCalling}
                >
                  Approve
                </Button>
              )}
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </Fragment>
  );
};

ApproveRejectPopup.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  isApprove: PropTypes.bool.isRequired,
  isApiCalling: PropTypes.bool,
  onClickApprove: PropTypes.func,
  onClickReject: PropTypes.func,
};

export default ApproveRejectPopup;
