import { Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PropTypes from "prop-types";

export const PlainCheckbox = (props) => {
  const { disabled, checked, onChange } = props;

  return (
    <Checkbox
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      checkedIcon={<CheckBoxIcon color={disabled ? "white" : "theme"} />}
      icon={<CheckBoxOutlineBlankIcon color={disabled ? "white" : "theme"} />}
      size="small"
    />
  );
};

PlainCheckbox.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

PlainCheckbox.defaultProps = {
  disabled: false,
};
