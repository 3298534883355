import { Button, Dialog, Stack, Typography } from "@mui/material";
import { Backdrop } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { LabelTextField } from "../fields/LabelTextField";
import { useAPI } from "../../hooks";
import { useToast } from "../../context";

export const EditAppVersionAlert = (props) => {
  const { open, onClose, versionsList, selectedVersion, onConfirm } = props;

  const { api, urls, isLoading } = useAPI();
  const { showToast } = useToast();

  const [menuItems, setMenuItems] = useState([]);
  const [formData, setFormData] = useState({
    device_os: selectedVersion.device_os,
    version: selectedVersion.version,
    errors: {
      version: false,
    },
  });

  const handleSaveData = () => {
    console.log("🚀 ~ handleSaveData ~ formData:", formData);
    let invalid = false;

    if (!formData.version) {
      setFormData((prev) => {
        const curr = { ...prev };
        prev.errors.version = true;
        return curr;
      });
      invalid = true;
    }

    if (!invalid) {
      const params = { ...formData };
      delete params.errors;

      api
        .put(`${urls.editAppVersion}`, params)
        .then((res) => {
          let data = res.data;
          showToast("App version updated successfully", "success");
          setFormData({
            device_os: "",
            version: "",
            errors: {
              version: false,
            },
          });
          onConfirm(data.data);
        })
        .catch((err) => {
          showToast(err.response?.data?.message, "error");
        });
    }
  };

  const handleClose = () => {
    setFormData({
      code: "",
      name: "",
      errors: { name: false, code: false, code_message: "" },
    });
    onClose();
  };

  const updateAppVersionText = (value) => {
    setFormData((prev) => {
      const curr = { ...prev };
      const regex = /^[0-9.]*$/;
      if (regex.test(value)) {
        curr.version = value;
        curr.errors.version = false;
      }
      return curr;
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Stack
        direction={"column"}
        sx={{ width: 400, pt: 3, pl: 4, pr: 4, pb: 4, gap: 3 }}
      >
        <Typography
          variant="h1"
          sx={{
            mb: 2,
            textAlign: "center",
          }}
        >
          Edit App Version
        </Typography>
        <LabelTextField
          label={"Platform"}
          textInput={`${
            selectedVersion.device_os == "android" ? "Android" : "iOS"
          }`}
          error={formData.errors.name}
          disabled
        />
        <LabelTextField
          label={"Latest Version"}
          textInput={formData.version}
          onTextChange={(event) => {
            updateAppVersionText(event.target.value);
          }}
          error={formData.errors.name}
          helperText={
            formData.errors.name ? "Please enter the app version" : null
          }
        />

        <Button
          variant="contained"
          disableElevation
          color="theme"
          onClick={handleSaveData}
          sx={{
            height: 50,
            fontSize: 16,
            fontWeight: 700,
            borderRadius: 2,
            textTransform: "none",
          }}
          disabled={isLoading}
        >
          Update
        </Button>
      </Stack>
    </Dialog>
  );
};

EditAppVersionAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
