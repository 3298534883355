import {
  Dialog,
  Stack,
  Typography,
  Button,
  useTheme,
  Box,
} from "@mui/material";
import { LabelTextField } from "../fields/LabelTextField";
import { Backdrop } from "@mui/material";
import PropTypes from "prop-types";
import { ArrowUp, DocumentText1 } from "iconsax-react";
import { useRef, useState } from "react";
import { useToast } from "../../context";

export const StatusChangeUploaderAlert = (props) => {
  const { open, onClose, header } = props;

  const theme = useTheme();
  const pickerRef = useRef();

  const { showToast } = useToast();

  const [uploadFile, setUploadFile] = useState(null);

  const handleInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedExtensions = ["pdf", "doc", "docx"];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        setUploadFile(file);
      } else {
        showToast("Please select a valid PDF or DOC file", "error");
      }
    }
  };

  const handleOnClose = () => {
    setUploadFile(null);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <input
        ref={pickerRef}
        type="file"
        accept=".pdf,.doc,.docx"
        onChange={handleInputChange}
        style={{ display: "none" }}
      />
      <Stack
        direction={"column"}
        sx={{ width: 400, pt: 3, pl: 4, pr: 4, pb: 4, gap: 2 }}
      >
        <Typography
          variant="h1"
          sx={{
            textAlign: "center",
          }}
        >
          {header} User
        </Typography>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          onClick={() => pickerRef.current.click()}
          sx={{
            mt: 2,
            p: "18px",
            gap: 1,
            cursor: "pointer",
            borderRadius: 2,
            border: 1,
            borderStyle: "dashed",
            borderColor: theme.palette.theme.main,
          }}
        >
          {uploadFile ? (
            <>
              <Box
                justifyContent={"center"}
                alignItems={"center"}
                display={"flex"}
                sx={{
                  width: 50,
                  height: 50,
                  borderRadius: "8px",
                  bgcolor: theme.palette.themeHover.main,
                }}
              >
                <DocumentText1 size="20" color={theme.palette.theme.main} />
              </Box>
              <Typography variant="h2">{uploadFile?.name}</Typography>
            </>
          ) : (
            <>
              <ArrowUp size="18" color={theme.palette.theme.main} />
              <Typography variant="h2" sx={{ color: theme.palette.theme.main }}>
                Upload
              </Typography>
            </>
          )}
        </Stack>
        <LabelTextField
          label={"Reason"}
          textInput={""}
          onTextChange={() => {}}
        />
        <Stack direction={"row"} sx={{ gap: 2 }}>
          <Button
            onClick={handleOnClose}
            variant="outlined"
            color="theme"
            sx={{
              width: "100%",
              height: 50,
              fontSize: 16,
              fontWeight: 700,
              textTransform: "none",
              borderRadius: 2,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {}}
            variant="contained"
            disableElevation
            color={header === "Approve" ? "btnPositive" : "btnNegative"}
            sx={{
              width: "100%",
              height: 50,
              fontSize: 16,
              fontWeight: 700,
              textTransform: "none",
              borderRadius: 2,
            }}
          >
            {header}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

StatusChangeUploaderAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
};
