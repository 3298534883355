import { Box, Divider, Stack, useTheme } from "@mui/material";
import { userPl } from "../../../assets";
import ZoomableImage from "../../../components/image-viewer/ZoomableImage";

export const InfoImage = (props) => {
  const { profileImage, IdImage1, IdImage2 } = props;
  const theme = useTheme();

  const boxStyle = {
    bgcolor: theme.palette.common.white,
    borderRadius: 3,
    p: 2,
    gap: 2,
  };

  return (
    <Stack direction={"column"} sx={{ gap: 2 }}>
      <Box sx={boxStyle}>
        <ZoomableImage
          border={"4px"}
          src={profileImage}
          customPlaceholder={userPl}
        />
      </Box>
      {(IdImage1 || IdImage2) && (
        <Stack direction={"column"} sx={boxStyle}>
          {IdImage1 && <ZoomableImage border={"4px"} src={IdImage1} />}
          {IdImage2 && <Divider />}
          {IdImage2 && <ZoomableImage border={"4px"} src={IdImage2} />}
        </Stack>
      )}
    </Stack>
  );
};
