import {
  Button,
  Divider,
  Stack,
  Typography,
  Chip,
  useTheme,
} from "@mui/material";
import { LabelTextField } from "../../../components/fields/LabelTextField";
import { Fragment, useState } from "react";
import { StatusChangeUploaderAlert } from "../../../components/alert/StatusChangeUploaderAlert";
import { ReminderAlert } from "../../../components/alert/ReminderAlert";
import { useAuth } from "../../../context";
import { getEditPermissionStatus } from "../../../utilities/get-permission";

export const AccountDetails = (props) => {
  const {
    title,
    data,
    onConfirmSendVerification,
    onConfirmUserDelete,
    isDeletedUser,
  } = props;
  const [userData, setUserData] = useState(data);
  const { adminRole, isAdmin } = useAuth();
  const theme = useTheme();

  const [header, setHeader] = useState("");
  const [statusChangeUploaderAlert, setStatusChangeUploaderAlert] =
    useState(false);
  const [showVerificationConfirm, setShowVerificationConfirm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const convertUTCtoLocalDateTime = (utcDateTimeString) => {
    const utcDateTime = new Date(utcDateTimeString);
    return utcDateTime.toLocaleString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: true, // specify AM/PM
    });
  };

  const onClickConfirmVerification = () => {
    setShowVerificationConfirm(false);
    onConfirmSendVerification();
  };

  const onClickConfirmDelete = () => {
    setShowDeleteConfirm(false);
    onConfirmUserDelete();
  };

  function formatDate(dateString) {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      const formattedDate = `${day}/${month}/${year}`;
      return formattedDate;
    } else {
      return "";
    }
  }

  return (
    <Fragment>
      <StatusChangeUploaderAlert
        header={header}
        open={statusChangeUploaderAlert}
        onClose={() => setStatusChangeUploaderAlert(false)}
      />
      <Stack
        direction={"column"}
        sx={{
          bgcolor: theme.palette.common.white,
          borderRadius: 3,
          p: 2,
          gap: 2,
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={2}
          display={"flex"}
          justifyContent="space-between"
        >
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Typography
              variant="h2"
              sx={{ color: theme.palette.secondary.main }}
            >
              {title}
            </Typography>
            {isDeletedUser && <Chip label="Archived" color="error" />}
            {!isDeletedUser && userData?.is_verified && (
              <Chip label="Verified &#10004;" color="success" />
            )}
            {!isDeletedUser && !userData?.is_verified && (
              <Chip label="Unverified" color="warning" />
            )}
          </Stack>
          {!isDeletedUser && (
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              {userData.user_onboarding_type !== "Risk" &&
                (isAdmin ||
                  getEditPermissionStatus(
                    adminRole.permissions,
                    "Digital Onboarding Customers"
                  ) ||
                  getEditPermissionStatus(
                    adminRole.permissions,
                    "Authentication Customers"
                  )) && (
                  // button not for 'risk based customers'
                  <Button
                    variant="outlined"
                    disableElevation
                    color="btnPositive"
                    sx={{
                      height: "100%",
                      fontSize: 16,
                      fontWeight: 700,
                      borderRadius: 2,
                      textTransform: "none",
                    }}
                    onClick={() => setShowVerificationConfirm(true)}
                  >
                    Request New Verification
                  </Button>
                )}
              {(isAdmin ||
                getEditPermissionStatus(
                  adminRole.permissions,
                  "Digital Onboarding Customers"
                ) ||
                getEditPermissionStatus(
                  adminRole.permissions,
                  "Authentication Customers"
                )) && (
                <Button
                  variant="contained"
                  disableElevation
                  color="btnNegative"
                  sx={{
                    height: "100%",
                    fontSize: 16,
                    fontWeight: 700,
                    borderRadius: 2,
                    textTransform: "none",
                  }}
                  onClick={() => setShowDeleteConfirm(true)}
                >
                  Delete User
                </Button>
              )}
            </Stack>
          )}
        </Stack>

        <Divider />
        <Stack direction={"column"} sx={{ mt: 1, gap: 3 }}>
          <LabelTextField
            label={userData?.last_name ?? "" !== "" ? "First Name" : "Name"}
            textInput={userData?.full_name}
            onTextChange={() => {}}
            disabled
          />
          {userData?.last_name !== null &&
            (userData?.last_name ?? "") !== "" && (
              <LabelTextField
                label={"Last Name"}
                textInput={userData?.last_name}
                onTextChange={() => {}}
                disabled
              />
            )}
          {userData?.dob !== null && (userData?.dob ?? "") !== "" && (
            <LabelTextField
              label={"Date of Birth"}
              textInput={formatDate(userData?.dob)}
              onTextChange={() => {}}
              disabled
            />
          )}
          {userData?.gender !== null && (userData?.gender ?? "") !== "" && (
            <LabelTextField
              label={"Gender"}
              textInput={userData?.gender}
              onTextChange={() => {}}
              disabled
            />
          )}
          {userData?.nationality !== null &&
            (userData?.nationality ?? "") !== "" && (
              <LabelTextField
                label={"Nationality"}
                textInput={userData?.nationality}
                onTextChange={() => {}}
                disabled
              />
            )}
          {userData?.national_id !== null &&
            (userData?.national_id ?? "") !== "" && (
              <LabelTextField
                label={"National ID No."}
                textInput={userData?.national_id}
                onTextChange={() => {}}
                disabled
              />
            )}
          {userData?.date_of_issue !== null &&
            (userData?.date_of_issue ?? "") !== "" && (
              <LabelTextField
                label={"Issue Date"}
                textInput={formatDate(userData?.date_of_issue)}
                onTextChange={() => {}}
                disabled
              />
            )}
          {userData?.date_of_expiry !== null &&
            (userData?.date_of_expiry ?? "") !== "" && (
              <LabelTextField
                label={"Expiry Date"}
                textInput={formatDate(userData?.date_of_expiry)}
                onTextChange={() => {}}
                disabled
              />
            )}
          {userData?.pin !== null && (userData?.pin ?? "") !== "" && (
            <LabelTextField
              label={"PIN"}
              textInput={userData?.pin}
              onTextChange={() => {}}
              disabled
            />
          )}
          {userData?.phone_number !== null &&
            (userData?.phone_number ?? "") !== "" && (
              <LabelTextField
                label={"Phone No."}
                textInput={`${userData?.phone_code ?? ""}${
                  userData?.phone_number ?? ""
                }`}
                onTextChange={() => {}}
                disabled
              />
            )}
          {userData?.created_at !== null &&
            (userData?.created_at ?? "") !== "" && (
              <LabelTextField
                label={"Created At"}
                textInput={
                  userData?.created_at
                    ? convertUTCtoLocalDateTime(userData?.created_at)
                    : ""
                }
                onTextChange={() => {}}
                disabled
              />
            )}
          {userData?.mrz !== null && (userData?.mrz ?? "") !== "" && (
            <LabelTextField
              label={"MRZ"}
              textInput={userData?.mrz}
              onTextChange={() => {}}
              disabled
            />
          )}
          {userData?.address1 !== null && (userData?.address1 ?? "") !== "" && (
            <LabelTextField
              label={"Address"}
              textInput={userData?.address1}
              onTextChange={() => {}}
              disabled
            />
          )}
          {userData?.place_of_birth !== null &&
            (userData?.place_of_birth ?? "") !== "" && (
              <LabelTextField
                label={"Place of Birth"}
                textInput={userData?.place_of_birth}
                onTextChange={() => {}}
                disabled
              />
            )}
          {userData?.place_of_issue !== null &&
            (userData?.place_of_issue ?? "") !== "" && (
              <LabelTextField
                label={"Place of Issue"}
                textInput={userData?.place_of_issue}
                onTextChange={() => {}}
                disabled
              />
            )}
          {userData?.father_name !== null &&
            (userData?.father_name ?? "") !== "" && (
              <LabelTextField
                label={"Father's Name"}
                textInput={userData?.father_name}
                onTextChange={() => {}}
                disabled
              />
            )}
          {userData?.profession !== null &&
            (userData?.profession ?? "") !== "" && (
              <LabelTextField
                label={"Profession"}
                textInput={userData?.profession}
                onTextChange={() => {}}
                disabled
              />
            )}
        </Stack>
      </Stack>
      {/* Popup */}
      <ReminderAlert
        open={showVerificationConfirm}
        onClose={() => setShowVerificationConfirm(false)}
        onConfirm={onClickConfirmVerification}
        header={"Request New Verification"}
        reminder={"Are you sure, you want to proceed?"}
        closeText={"Cancel"}
        confirmText={"Proceed"}
        buttonColor="theme"
      />
      <ReminderAlert
        open={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={onClickConfirmDelete}
        header={"Delete Customer"}
        reminder={"Are you sure, you want to delete this customer?"}
        closeText={"Cancel"}
        confirmText={"Confirm"}
      />
    </Fragment>
  );
};
