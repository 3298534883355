import { createContext, useContext, useState, useEffect } from "react";
import { useLocalStorage } from "../hooks";

const AuthContext = createContext();

const AuthProvider = (props) => {
  const { getItem, removeItem } = useLocalStorage();

  const token = getItem("access_token");
  const isSuper = getItem("is_superuser");
  const role = getItem("role");
  const name = getItem("toppan_name");
  const email = getItem("toppan_email");
  const tpn_baseUrl = getItem("tpn_baseUrl");
  const is_main_org = getItem("is_main_org");
  const tpn_org_name = getItem("tpn_org_name");

  const [isLoggedIn, setIsLoggedIn] = useState(Boolean(token));
  const [isAdmin, setIsAdmin] = useState(JSON.parse(isSuper));
  const [adminRole, setAdminRole] = useState(role ? JSON.parse(role) : null);
  const [userName, setUserName] = useState(name);
  const [userEmail, setUserEmail] = useState(email);
  const [apiBaseURL, setApiBaseURL] = useState(tpn_baseUrl);
  const [isMainOrg, setIsMainOrg] = useState(is_main_org);
  const [orgName, setOrgName] = useState(tpn_org_name);

  const login = () => {
    setIsLoggedIn(true);
  };

  const setOrganisation = () => {
    setApiBaseURL(getItem("tpn_baseUrl"));
    setIsMainOrg(getItem("is_main_org"));
    setOrgName(getItem("tpn_org_name"));
    console.log("🚀 ~ setOrganisation ~ getItem():", getItem("tpn_baseUrl"));
  };

  const logout = () => {
    setIsLoggedIn(false);
    setIsAdmin(false);
    setAdminRole(null);
    setUserName(null);
    setUserEmail(null);
    setApiBaseURL(null);
    setIsMainOrg(null);
    setOrgName(null);

    removeItem("access_token");
    removeItem("refresh_token");
    removeItem("is_superuser");
    removeItem("role");
    removeItem("toppan_name");
    removeItem("toppan_email");
    removeItem("tab_id");
    removeItem("tpn_baseUrl");
    removeItem("is_main_org");
    removeItem("tpn_org_name");
  };

  useEffect(() => {
    if (role) {
      setAdminRole(JSON.parse(role));
    }
    setIsAdmin(JSON.parse(isSuper));
    setUserName(name);
    setUserEmail(email);
    setApiBaseURL(tpn_baseUrl);
    setIsMainOrg(is_main_org);
    setOrgName(tpn_org_name);
  }, [role, isSuper, name, email, tpn_baseUrl, is_main_org, tpn_org_name]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        login,
        logout,
        setOrganisation,
        isAdmin,
        adminRole,
        userName,
        userEmail,
        apiBaseURL,
        isMainOrg,
        orgName,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
