import { Fragment, useEffect, useState } from "react";
import { ActionBar } from "../../components/headers/ActionBar";
import { PageStackLayout } from "../../components/layout/PageStackLayout";
import { DynamicKeyTable } from "../../components/convertion/DynamicKeyTable";
import { DynamicKeyTableWithTitle } from "../../components/convertion/DynamicKeyTableWithTitle";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks";
import { useLocation, useParams } from "react-router-dom";
import LoaderCard from "../../components/cards/LoaderCard";
import {
  Divider,
  Grid,
  Stack,
  Typography,
  Box,
  Button,
  useTheme,
} from "@mui/material";
import { StatusCard } from "../../components/cards/StatusCard";

export const StatusDetailsScreen = () => {
  const { type, amlId } = useParams();
  const { showToast } = useToast();
  const { logout } = useAuth();
  const { api, urls, isLoading } = useAPI();

  const theme = useTheme();
  const location = useLocation();

  const [personalDetails, setPersonalDetails] = useState(null);
  const [pepDetails, setPepDetails] = useState(null);
  const [crimeDetails, setCrimeDetails] = useState(null);
  const [blacklistDetails, setBlackListDetails] = useState(null);
  const [sanctionsDetails, setSanctionsDetails] = useState(null);
  const [sanctionsAuthortyArray, setSanctionsAuthorityArray] = useState(null);
  const [sanctionsWeblinks, setSanctionsWeblinks] = useState(null);
  const [cardDetails, setCardDetails] = useState({});
  const [requestDetails, setRequestDetails] = useState({});
  const [viewMoreSelected, setViewMoreSelected] = useState(false);

  const getStatusDetails = async () => {
    api
      .get(`${urls.aml_details}/${amlId}/`)
      .then((res) => {
        let data = res.data.data;
        let response = { ...data.response };

        let request = data.request;

        delete response.REQUEST_INFO;
        delete response.REQUEST_SUMMARY;

        if (response.CONFIDENCE) {
          response.CONFIDENCE = Object.fromEntries(
            Object.entries(response.CONFIDENCE).map(([key, value]) => [
              key,
              `${value}`,
            ])
          );
        }

        const requestDetails = response.REQUEST_DETAILS?.[0];
        if (requestDetails) {
          const personalInfo = requestDetails.PERSONAL_INFO?.[0];
          if (personalInfo) {
            Object.keys(personalInfo).forEach((key) => {
              if (personalInfo[key] === "") {
                delete personalInfo[key];
              }
            });
          }
        }

        setCardDetails(data.response);
        if (requestDetails) {
          setPersonalDetails(requestDetails.PERSONAL_INFO[0]);

          // Check pep
          if (requestDetails.PEP) {
            if (requestDetails.PEP.PEP_DETAILS?.length > 0) {
              setPepDetails(requestDetails.PEP.PEP_DETAILS[0]);
            }
          }

          // Check crimes
          if (requestDetails.CRIMES) {
            if (requestDetails.CRIMES.CRIMES_DETAILS?.length > 0) {
              setCrimeDetails(requestDetails.CRIMES.CRIMES_DETAILS[0]);
            }
          }

          // Check blacklist
          if (requestDetails.BLACKLIST) {
            if (requestDetails.BLACKLIST.BLACKLIST_DETAILS?.length > 0) {
              setBlackListDetails(
                requestDetails.BLACKLIST.BLACKLIST_DETAILS[0]
              );
            }
          }

          //Sanctions
          if (requestDetails.SANCTIONS) {
            // Sanctions details
            if (requestDetails.SANCTIONS.SANCTIONS_DETAILS?.length > 0) {
              const sanctionData =
                requestDetails.SANCTIONS.SANCTIONS_DETAILS[0];
              // const sanctionsArray = sanctionData?.SANCTIONS;
              delete sanctionData.SANCTIONS;
              delete sanctionData.ADDRESSENTITY;
              delete sanctionData.OWNERSHIPOWNER;
              delete sanctionData.UNKNOWNLINKFROM;

              setSanctionsDetails(sanctionData);
            }

            //Sanctions Array
            if (requestDetails.SANCTIONS.SANCTIONS_SUMMARY) {
              const sanctionsSummary =
                response?.REQUEST_DETAILS?.[0]?.SANCTIONS?.SANCTIONS_SUMMARY;

              if (sanctionsSummary) {
                const { COUNTRIES, AUTHORITIES } = sanctionsSummary;

                if (COUNTRIES?.length === AUTHORITIES?.length) {
                  const sanctionsAuthorityArray = COUNTRIES.map(
                    (country, index) => ({
                      no: index + 1,
                      AUTHORITY: AUTHORITIES[index],
                      COUNTRY: country.toUpperCase(),
                    })
                  );

                  setSanctionsAuthorityArray(sanctionsAuthorityArray);
                }
              }
            }

            //sanctions weblinks
            if (requestDetails.SANCTIONS.SANCTIONS_WEBLINKS) {
              setSanctionsWeblinks(requestDetails.SANCTIONS.SANCTIONS_WEBLINKS);
            }
          }
        }

        setRequestDetails(request);
      })
      .catch(handleApiError);
  };

  //error handling
  const handleApiError = (err) => {
    if (err.response?.status === 401) {
      logout();
    } else {
      console.log("err", err);
      showToast(err.response?.data?.message, "error");
    }
  };

  //useEffect
  useEffect(() => {
    getStatusDetails();
  }, []);

  return (
    <Fragment>
      <ActionBar
        header={
          type === "1" ? "Screening Status Details" : "ID Verification Details"
        }
        showBackButton={true}
      />
      <PageStackLayout>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h1">
            {type === "1"
              ? "Screening Status Details"
              : "ID Verification Details"}
          </Typography>
          <Button
            variant="contained"
            disableElevation
            color="theme"
            sx={{
              height: 40,
              borderRadius: 2,
              fontSize: 13,
              fontWeight: 500,
              textTransform: "none",
              width: "100px",
            }}
            size="small"
            onClick={() => setViewMoreSelected(!viewMoreSelected)}
          >
            {viewMoreSelected ? "View Less" : "View More"}
          </Button>
        </Stack>

        {isLoading && <LoaderCard />}
        {!isLoading && (
          <Stack direction={"column"} spacing={2}>
            {cardDetails.REQUEST_SUMMARY && (
              <Stack direction={"column"} sx={{ gap: 2 }}>
                <Grid
                  container
                  sx={{
                    bgcolor: theme.palette.common.white,
                    p: 3,
                    gap: 2,
                    borderRadius: 2,
                  }}
                >
                  <Grid item xs>
                    <Stack direction={"column"} sx={{ gap: "2px" }}>
                      <Typography
                        variant="p1"
                        sx={{ color: theme.palette.secondary.main }}
                      >
                        Name
                      </Typography>
                      <Typography variant="h1">
                        {requestDetails.Criterias?.length > 0
                          ? requestDetails.Criterias[0]?.Value
                          : "NA"}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs>
                    <Stack direction={"column"} sx={{ gap: "2px" }}>
                      <Typography
                        variant="p1"
                        sx={{ color: theme.palette.secondary.main }}
                      >
                        Date of Birth
                      </Typography>
                      <Typography variant="h1">
                        {requestDetails.Criterias?.length > 1
                          ? requestDetails.Criterias[1]?.Value
                          : "NA"}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs>
                    <Stack direction={"column"} sx={{ gap: "2px" }}>
                      <Typography
                        variant="p1"
                        sx={{ color: theme.palette.secondary.main }}
                      >
                        Nationality
                      </Typography>
                      <Typography variant="h1">
                        {requestDetails.Criterias?.length > 2
                          ? requestDetails.Criterias[2]?.Value
                          : "NA"}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container sx={{ gap: 2 }}>
                  <Grid item xs>
                    <StatusCard summary={cardDetails.REQUEST_SUMMARY[0]} />
                  </Grid>
                  <Grid item xs>
                    <StatusCard summary={cardDetails.REQUEST_SUMMARY[1]} />
                  </Grid>
                  <Grid item xs>
                    <StatusCard summary={cardDetails.REQUEST_SUMMARY[2]} />
                  </Grid>
                </Grid>
                <Grid container sx={{ gap: 2 }} justifyContent={"center"}>
                  {cardDetails.REQUEST_SUMMARY[3] && (
                    <Grid item xs={3.9}>
                      <StatusCard summary={cardDetails.REQUEST_SUMMARY[3]} />
                    </Grid>
                  )}
                  {cardDetails.REQUEST_SUMMARY[4] && (
                    <Grid item xs={3.9}>
                      <StatusCard summary={cardDetails.REQUEST_SUMMARY[4]} />
                    </Grid>
                  )}
                </Grid>
              </Stack>
            )}

            {viewMoreSelected && (
              <Stack>
                {personalDetails && (
                  <DynamicKeyTable
                    jsonObject={personalDetails}
                    title={"PERSONAL INFO"}
                  />
                )}
                {pepDetails && (
                  <DynamicKeyTable
                    jsonObject={pepDetails}
                    title={"PEP DETAILS"}
                  />
                )}
                {crimeDetails && (
                  <DynamicKeyTable
                    jsonObject={crimeDetails}
                    title={"CRIMES DETAILS"}
                  />
                )}
                {blacklistDetails && (
                  <DynamicKeyTable
                    jsonObject={blacklistDetails}
                    title={"BLACKLIST DETAILS"}
                  />
                )}
                {sanctionsDetails && (
                  <DynamicKeyTable
                    jsonObject={sanctionsDetails}
                    title={"SANCTIONS DETAILS"}
                  />
                )}
                {sanctionsAuthortyArray?.length > 0 && (
                  <DynamicKeyTableWithTitle
                    jsonObject={sanctionsAuthortyArray}
                    title={"SANCTIONS SUMMARY"}
                  />
                )}

                {sanctionsWeblinks?.length > 0 && (
                  <DynamicKeyTableWithTitle
                    jsonObject={sanctionsWeblinks}
                    title={"SANCTIONS WEBLINKS"}
                  />
                )}
              </Stack>
            )}
            {/* {viewMoreSelected && <DynamicKeys jsonObject={personalDetails} />} */}
          </Stack>
        )}
      </PageStackLayout>
    </Fragment>
  );
};
