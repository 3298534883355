import { Fragment, useState, useEffect } from "react";
import { EDDDetails } from "../UserDetails/EDDDetails";
import { Stack, Box, useTheme } from "@mui/material";
import LoaderCard from "../../../components/cards/LoaderCard";
import { useAPI } from "../../../hooks";
import { useToast, useAuth } from "../../../context";
import { useParams } from "react-router-dom";
import NoDataCard from "../../../components/cards/NoDataCard";

export const MoreInfoTab = () => {
  const theme = useTheme();
  const { showToast } = useToast();
  const { logout } = useAuth();
  const { api, urls, isLoading } = useAPI();
  const { uid } = useParams();
  const [userData, setUserData] = useState([]);

  const getUserMoreDetails = async () => {
    api
      .get(`${urls.customerMoreDetails}${uid}`)
      .then((res) => {
        let data = res.data.data;
        console.log("🚀 ~ .then ~ data:", data);
        setUserData(data);
      })

      .catch(handleApiError);
  };

  //error handling
  const handleApiError = (err) => {
    if (err.response?.status === 401) {
      logout();
    } else {
      console.log("err", err);
      showToast(err.response?.data?.message, "error");
    }
  };

  useEffect(() => {
    if (uid) {
      getUserMoreDetails();
    }
  }, []);

  return (
    <Fragment>
      {isLoading && <LoaderCard />}
      {!isLoading && userData.length > 0 && (
        <Box
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
          sx={{
            p: 4,
            bgcolor: theme.palette.common.white,
            borderRadius: 3,
          }}
        >
          <Stack
            direction={"column"}
            sx={{
              p: 3,
              width: 700,
              bgcolor: theme.palette.background.main,
              borderRadius: 3,
            }}
            spacing={2}
          >
            {userData.map((pageData, index) => {
              return (
                <EDDDetails
                  key={index}
                  pageTitle={pageData.page_title}
                  data={pageData.form_field}
                />
              );
            })}
          </Stack>
        </Box>
      )}
      {!isLoading && userData.length === 0 && <NoDataCard />}
    </Fragment>
  );
};
