import { MenuItem, TextField, useTheme } from "@mui/material";
import PropTypes from "prop-types";

export const OrgPathDropdownTextField = (props) => {
  const {
    label,
    disabled,
    textInput,
    onTextChange,
    menuItems,
    error,
    helperText,
  } = props;

  const theme = useTheme();

  return (
    <TextField
      fullWidth
      select
      disabled={disabled}
      label={label}
      value={textInput}
      onChange={onTextChange}
      variant="outlined"
      error={error}
      helperText={helperText}
      inputProps={{
        sx: {
          fontSize: 16,
          fontWeight: 400,
          color: theme.palette.primary.main,
        },
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: 2,
          "& fieldset": {
            borderColor: theme.palette.secondary.main,
          },
        },
      }}
    >
      {menuItems.map((item) => (
        <MenuItem key={item.uuid} value={item.uuid}>
          {item.path}
        </MenuItem>
      ))}
    </TextField>
  );
};

OrgPathDropdownTextField.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  textInput: PropTypes.string.isRequired,
  onTextChange: PropTypes.func.isRequired,
  menuItems: PropTypes.array.isRequired,
};

OrgPathDropdownTextField.defaultProps = {
  disabled: false,
};
