import { IconButton, InputAdornment, TextField, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export const StdLabelPasswordTextField = (props) => {
  const {
    label,
    disabled,
    textInput,
    onTextChange,
    variant,
    error,
    helperText,
  } = props;

  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      fullWidth
      disabled={disabled}
      label={label}
      value={textInput}
      onChange={onTextChange}
      variant={variant}
      type={showPassword ? "text" : "password"}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disableRipple
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        sx: { color: theme.palette.secondary.main },
      }}
      inputProps={{
        sx: {
          fontSize: 16,
          fontWeight: 400,
          color: theme.palette.primary.main,
        },
      }}
      sx={
        variant === "outlined" && {
          "& .MuiOutlinedInput-root": {
            borderRadius: 2,
            "& fieldset": {
              borderColor: theme.palette.secondary.main,
            },
          },
        }
      }
    />
  );
};

StdLabelPasswordTextField.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  textInput: PropTypes.string.isRequired,
  onTextChange: PropTypes.func.isRequired,
  variant: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

StdLabelPasswordTextField.defaultProps = {
  disabled: false,
  variant: "standard",
};
