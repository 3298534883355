import {
  Box,
  Divider,
  FormControl,
  RadioGroup,
  Stack,
  useTheme,
  IconButton,
} from "@mui/material";
import { LabelRadio } from "../radio/LabelRadio";
import { LabelCheckbox } from "../checkbox/LabelCheckbox";
import { useState } from "react";
import { PlaceholderTextField } from "../fields/PlaceholderTextField";
import PropTypes from "prop-types";
import { Edit2 } from "iconsax-react";

export const PageFieldCard = (props) => {
  const {
    field,
    onFieldChange,
    isPopup,
    showEdit,
    isEdit,
    onEditClick,
    readOnly,
  } = props;
  const theme = useTheme();

  const updateField = (key, value) => {
    if (!readOnly) {
      let data = { ...field };
      data[key] = value;
      onFieldChange(data);
    }
  };

  const updateValidation = (key, value) => {
    let data = { ...field };
    data.validation[key] = value;
    onFieldChange(data);
  };

  return (
    <Stack
      direction={isPopup ? "column" : "row"}
      sx={{
        pt: 2,
        pl: 3,
        pr: 3,
        pb: 3,
        gap: 2,
        borderRadius: 3,
        bgcolor: theme.palette.popupBlue.main,
        alignItems: isPopup ? "flex-start" : "center",
      }}
    >
      {!isPopup && (
        <Box sx={{ width: "50%" }}>
          <PlaceholderTextField
            placeholder="Enter Label Text"
            textInput={field?.label}
            onTextChange={(e) => updateField("label", e.target.value)}
            disabled={readOnly}
            backColor={"white"}
          />
        </Box>
      )}
      <Stack direction={"row"} sx={{ gap: 1 }}>
        {!isEdit && (
          <FormControl disabled={isEdit || readOnly}>
            <RadioGroup
              row
              value={field?.type}
              onChange={(e) => updateField("type", e.target.value)}
            >
              <LabelRadio label="Text Field" value="text" />
              <LabelRadio label="Upload File" value="file" />
            </RadioGroup>
          </FormControl>
        )}
        {!isEdit && (
          <Divider
            orientation="vertical"
            flexItem
            sx={{ mt: "12px", mb: "12px" }}
          />
        )}
        <Box sx={{ ml: 2, alignItems: "center", display: "flex" }}>
          <LabelCheckbox
            label="Required"
            checked={field?.validation?.required}
            onChange={(e) => {
              updateValidation("required", e.target.checked);
              if (e.target.checked) {
                updateField("status", true);
              }
            }}
            disabled={readOnly}
          />
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ mt: "12px", mb: "12px" }}
        />
        <Box sx={{ ml: 2, alignItems: "center", display: "flex" }}>
          <LabelCheckbox
            label="Hide"
            disabled={field?.validation?.required || readOnly}
            checked={!(field?.status ?? true)}
            onChange={(e) => updateField("status", !e.target.checked)}
          />
        </Box>
      </Stack>
      {isPopup && (
        <PlaceholderTextField
          placeholder="Enter Label Text"
          textInput={field?.label}
          onTextChange={(e) => updateField("label", e.target.value)}
          disabled={isEdit}
          error={field?.label.trim() === ""}
          backColor={"white"}
        />
      )}
      {showEdit && (
        <IconButton onClick={() => onEditClick(field)}>
          <Edit2 variant="Bold" color={theme.palette.theme.main} />
        </IconButton>
      )}
    </Stack>
  );
};

PageFieldCard.propTypes = {
  field: PropTypes.object,
  onFieldChange: PropTypes.func,
  isPopup: PropTypes.bool,
  showEdit: PropTypes.bool,
  isEdit: PropTypes.bool,
  onEditClick: PropTypes.func,
  readOnly: PropTypes.bool,
};
PageFieldCard.defaultProps = {
  readOnly: false,
};
