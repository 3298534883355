import { Button, Dialog, Stack, Typography } from "@mui/material";
import { Backdrop } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { OrgPathDropdownTextField } from "../fields/OrgPathDropdownTextField";
import { LabelTextField } from "../fields/LabelTextField";
import { useAPI } from "../../hooks";
import { useToast } from "../../context";

export const AddOrganisationAlert = (props) => {
  const { open, onClose, organisationsList, onConfirm } = props;

  const { api, urls, isLoading } = useAPI();
  const { showToast } = useToast();

  const [menuItems, setMenuItems] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    path: "",
    errors: {
      name: false,
      code: false,
      code_message: "",
      path: false,
    },
  });

  const getPathList = async () => {
    api
      .get(urls.orgPathList)
      .then((res) => {
        setMenuItems(res.data.data.filter((code) => !code.used));
      })
      .catch((err) => {
        showToast(err.response?.data?.data?.message, "error");
      });
  };

  useEffect(() => {
    if (open) {
      getPathList();
    }
  }, [open]);

  const handleSaveData = () => {
    let invalid = false;

    if (!formData.path) {
      setFormData((prev) => {
        const curr = { ...prev };
        prev.errors.path = true;
        return curr;
      });
      invalid = true;
    }

    if (!formData.name) {
      setFormData((prev) => {
        const curr = { ...prev };
        prev.errors.name = true;
        return curr;
      });
      invalid = true;
    }

    if (!formData.code) {
      setFormData((prev) => {
        const curr = { ...prev };
        prev.errors.code = true;
        prev.errors.code_message = "Please enter the organisation code";
        return curr;
      });
      invalid = true;
    } else if (organisationsList.some((item) => item.code === formData.code)) {
      setFormData((prev) => {
        const curr = { ...prev };
        prev.errors.code = true;
        prev.errors.code_message =
          "Code is already taken, please use another code";
        return curr;
      });
      invalid = true;
    } else if (formData.code.length !== 4) {
      setFormData((prev) => {
        const curr = { ...prev };
        prev.errors.code = true;
        prev.errors.code_message = "Code should contain 4 digits";
        return curr;
      });
      invalid = true;
    }
    if (!invalid) {
      const params = { ...formData };
      delete params.errors;

      api
        .post(urls.addOrganisation, params)
        .then((res) => {
          let data = res.data;
          showToast("Organisation created successfully", "success");
          setFormData({
            name: "",
            code: "",
            path: "",
            errors: {
              name: false,
              code: false,
              code_message: "",
              path: false,
            },
          });
          onConfirm(data.data);
        })
        .catch((err) => {
          showToast(err.response?.data?.message, "error");
        });
    }
  };

  const handleClose = () => {
    setFormData({
      path: "",
      name: "",
      errors: { name: false, code: false, code_message: "", path: false },
    });
    onClose();
  };

  const updateFormData = (key, value) => {
    setFormData((prev) => {
      const curr = { ...prev };
      curr[key] = value;
      curr.errors[key] = false;
      return curr;
    });
  };

  const orgCodeUpdating = (code) => {
    const sanitizedCode = code.replace(/\D/g, "").substring(0, 4);
    updateFormData("code", sanitizedCode);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Stack
        direction={"column"}
        sx={{ width: 400, pt: 3, pl: 4, pr: 4, pb: 4, gap: 3 }}
      >
        <Typography
          variant="h1"
          sx={{
            mb: 2,
            textAlign: "center",
          }}
        >
          Add Organisation
        </Typography>
        <LabelTextField
          label={"Organisation Name"}
          textInput={formData.name}
          onTextChange={(event) => {
            updateFormData("name", event.target.value);
          }}
          error={formData.errors.name}
          helperText={
            formData.errors.name ? "Please enter the organisation name" : null
          }
        />
        <LabelTextField
          label={"Organisation Code"}
          textInput={formData.code}
          onTextChange={(event) => {
            orgCodeUpdating(event.target.value);
          }}
          error={formData.errors.code}
          helperText={
            formData.errors.code ? formData.errors.code_message : null
          }
        />
        <OrgPathDropdownTextField
          label={"Organisation Path"}
          //textInput={""}
          onTextChange={(event) => {
            updateFormData("path", event.target.value);
          }}
          menuItems={menuItems}
          error={formData.errors.path}
          helperText={
            formData.errors.path ? "Please select a organisation path" : null
          }
        />
        <Button
          variant="contained"
          disableElevation
          color="theme"
          onClick={handleSaveData}
          sx={{
            height: 50,
            fontSize: 16,
            fontWeight: 700,
            borderRadius: 2,
            textTransform: "none",
          }}
          disabled={isLoading}
        >
          Add
        </Button>
      </Stack>
    </Dialog>
  );
};

AddOrganisationAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
