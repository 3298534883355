import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { Status } from "../basic/Status";
import { PaginationTable } from "./PaginationTable";
import { useNavigate, useParams } from "react-router-dom";
import NoDataCard from "../cards/NoDataCard";
import { useEffect, useState, Fragment } from "react";
import { useAPI } from "../../hooks";
import { useToast, useAuth } from "../../context";
import LoaderCard from "../cards/LoaderCard";

export const ProfileEditHistoryTable = () => {
  const { showToast } = useToast();
  const { logout } = useAuth();
  const { api, urls, isLoading } = useAPI();
  const { uid } = useParams();

  const theme = useTheme();
  const navigate = useNavigate();

  const [requestData, setRequestData] = useState({
    itemsPerPage: 25,
    currentPage: 1,
  });

  const [editHistory, setEditHistory] = useState([]);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    limit: 0,
    total_count: 0,
  });

  const convertUTCtoLocalDateTime = (utcDateTimeString) => {
    const utcDateTime = new Date(utcDateTimeString);
    return utcDateTime.toLocaleString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: true, // specify AM/PM
    });
  };

  const getEditHistory = async () => {
    const { itemsPerPage, currentPage } = requestData;
    api
      .get(
        `${urls.editHistory}${uid}/?page=${currentPage}&limit=${itemsPerPage}`
      )
      .then((res) => {
        const data = res.data;
        setEditHistory(data.data.data);
        setPaginationData(data.data.pagination);
        console.log("🚀 ~ .then ~ res:", res);
      })

      .catch(handleApiError);
  };

  const handleApiError = (err) => {
    if (err.response?.status === 401) {
      logout();
    } else {
      console.log("err", err);
      showToast(err.response?.data?.message, "error");
    }
  };

  useEffect(() => {
    getEditHistory();
  }, [requestData]);

  return (
    <Stack
      direction={"column"}
      sx={{
        bgcolor: theme.palette.common.white,
        borderRadius: 3,
        pt: "12px",
        pl: 2,
        pr: 2,
        pb: "12px",
      }}
    >
      {isLoading && <LoaderCard />}
      {!isLoading && (
        <Fragment>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ height: 70 }}>
                  <TableCell sx={{ py: "12px" }}>
                    <Typography variant="h2">No.</Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0 }}>
                    <Typography variant="h2">Date & Time</Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0 }}>
                    <Typography variant="h2">Maker Review</Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0 }}>
                    <Typography variant="h2">Checker Review</Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0 }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {editHistory.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ py: "12px" }}>
                      <Typography variant="p1">
                        {requestData.itemsPerPage * (paginationData.page - 1) +
                          index +
                          1}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ py: 0 }}>
                      <Typography variant="p1">
                        {convertUTCtoLocalDateTime(user.created_at)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ py: 0 }}>
                      <Status
                        type={user.editor_status ? user.editor_status : "N/A"}
                      />
                    </TableCell>
                    <TableCell sx={{ py: 0 }}>
                      <Status
                        type={user.manager_status ? user.manager_status : "N/A"}
                      />
                    </TableCell>
                    <TableCell sx={{ py: 0 }} align="right">
                      <Button
                        variant="contained"
                        disableElevation
                        color="theme"
                        onClick={() =>
                          navigate(`/unverified_customers/details/${user.id}`)
                        }
                        sx={{
                          fontSize: 14,
                          fontWeight: 400,
                          textTransform: "none",
                          maxWidth: "55px",
                          minWidth: "30px",
                          minHeight: "28px",
                          maxHeight: "28px",
                        }}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {editHistory.length === 0 && !isLoading && (
            <NoDataCard title={"No Data Found"} />
          )}
          <PaginationTable
            page={paginationData.page - 1}
            onSetPage={(page) =>
              setRequestData((prev) => ({ ...prev, currentPage: page + 1 }))
            } //page start with zero
            rowsPerPage={requestData.itemsPerPage}
            onSetRowsPerPage={(count) =>
              setRequestData((prev) => ({ ...prev, itemsPerPage: count }))
            }
            totalCount={paginationData.total_count}
          />
        </Fragment>
      )}
    </Stack>
  );
};
