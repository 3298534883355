import { Fragment, useEffect, useState } from "react";
import { ActionBar } from "../../components/headers/ActionBar";
import { PageStackLayout } from "../../components/layout/PageStackLayout";
import { OrgListTable } from "../../components/table/OrgListTable";
import { AppSettingsTable } from "../../components/table/AppSettingsTable";
import { useAuth, useToast } from "../../context";
import { useAPI, useModal } from "../../hooks";
import LoaderCard from "../../components/cards/LoaderCard";
import { Typography, Stack, Button, useTheme } from "@mui/material";
import { Add } from "iconsax-react";
import { AddOrganisationAlert } from "../../components/alert/AddOrganisationAlert";
import { EditOrganisationAlert } from "../../components/alert/EditOrganisationAlert";
import { EditAppVersionAlert } from "../../components/alert/EditAppVersionAlert";

export const OrganisationsListScreen = () => {
  const { showToast } = useToast();
  const { logout } = useAuth();
  const theme = useTheme();
  const { api, urls, isLoading } = useAPI();
  const [isOpenAddModal, openAddModal, closeAddModal] = useModal();
  const [isOpenEditModal, openEditModal, closeEditModal] = useModal();

  const [organisationsList, setOrganisationsList] = useState([]);
  const [loadingOrgList, setLoadingOrgList] = useState(false);
  const [editingOrganisation, setEditingOrganisation] = useState(null);

  const [loadingAppVersion, setLoadingAppVersion] = useState(false);
  const [appVersionList, setAppVersionList] = useState([]);
  const [isOpenEditVersionModal, openEditVersionModal, closeEditVersionModal] =
    useModal();
  const [editingVersion, setEditingVersion] = useState(null);

  //api
  const getorganisationsList = async () => {
    setLoadingOrgList(true);
    api
      .get(urls.organisations)
      .then((res) => {
        setLoadingOrgList(false);
        let data = res.data.data;
        setOrganisationsList(data);
      })
      .catch(handleApiError);
  };

  const getAppVersionsList = async () => {
    setLoadingAppVersion(true);
    api
      .get(urls.getAppVersion)
      .then((res) => {
        setLoadingAppVersion(false);
        let data = res.data.data;
        setAppVersionList(data);
      })
      .catch(handleApiError);
  };

  //error handling
  const handleApiError = (err) => {
    if (err.response?.status === 401) {
      logout();
    } else {
      setLoadingOrgList(false);
      setLoadingAppVersion(false);
      console.log("err", err);
      showToast(err.response?.data?.message, "error");
    }
  };

  const onClickEdit = (organisation) => {
    setEditingOrganisation(organisation);
    openEditModal();
  };

  const onClickEditVersion = (version) => {
    setEditingVersion(version);
    openEditVersionModal();
  };

  //useEffect
  useEffect(() => {
    getorganisationsList();
    getAppVersionsList();
  }, []);

  return (
    <Fragment>
      <ActionBar header={`Manage Organisation `} />

      <PageStackLayout>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant="h1">Manage Organisation</Typography>
          <Button
            variant="contained"
            disableElevation
            color="theme"
            startIcon={<Add size="25" color={theme.palette.common.white} />}
            onClick={openAddModal}
            sx={{
              height: 40,
              pl: 2,
              pr: 2,
              borderRadius: 2,
              fontSize: 16,
              fontWeight: 700,
              textTransform: "none",
            }}
          >
            Add Organisation
          </Button>
        </Stack>
        {loadingOrgList && <LoaderCard />}
        {!loadingOrgList && (
          <OrgListTable data={organisationsList} onClickEdit={onClickEdit} />
        )}
        <Stack direction={"row"} justifyContent={"space-between"} mt={2}>
          <Typography variant="h1">App Settings</Typography>
        </Stack>
        {loadingAppVersion && <LoaderCard />}
        {!loadingAppVersion && (
          <AppSettingsTable
            data={appVersionList}
            onClickEdit={onClickEditVersion}
          />
        )}
      </PageStackLayout>
      <AddOrganisationAlert
        open={isOpenAddModal}
        onClose={closeAddModal}
        organisationsList={organisationsList}
        onConfirm={(user) => {
          closeAddModal();
          getorganisationsList();
        }}
      />
      {isOpenEditModal && (
        <EditOrganisationAlert
          open={isOpenEditModal}
          onClose={closeEditModal}
          organisationsList={organisationsList}
          selectedOrganisation={editingOrganisation}
          onConfirm={(user) => {
            closeEditModal();
            getorganisationsList();
          }}
        />
      )}
      {isOpenEditVersionModal && (
        <EditAppVersionAlert
          open={isOpenEditVersionModal}
          onClose={closeEditVersionModal}
          versionsList={appVersionList}
          selectedVersion={editingVersion}
          onConfirm={(user) => {
            closeEditVersionModal();
            getAppVersionsList();
          }}
        />
      )}
    </Fragment>
  );
};
