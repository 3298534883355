import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { PaginationTable } from "./PaginationTable";
import PropTypes from "prop-types";
import NoDataCard from "../cards/NoDataCard";

export const AdminRolesTable = (props) => {
  const {
    roles,
    paginationData,
    itemsPerPage,
    setItemsPerPage,
    setPageNumber,
    onEditRoleClick,
    onDeleteRoleClick,
  } = props;
  const theme = useTheme();

  return (
    <Stack
      direction={"column"}
      sx={{
        bgcolor: theme.palette.common.white,
        borderRadius: 3,
        pt: "12px",
        pl: 2,
        pr: 2,
        pb: "12px",
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ height: 70 }}>
              <TableCell sx={{ py: "12px", width: 200 }}>
                <Typography variant="h2">No.</Typography>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <Typography variant="h2">Role Name</Typography>
              </TableCell>
              <TableCell sx={{ py: 0 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map((role, index) => (
              <TableRow key={index}>
                <TableCell sx={{ py: "12px" }}>
                  <Typography variant="p1">{index + 1}</Typography>
                </TableCell>
                <TableCell sx={{ py: 0 }}>
                  <Typography variant="p1">{role.role_name}</Typography>
                </TableCell>
                <TableCell sx={{ py: 0 }} align="right">
                  <Stack
                    direction={"row"}
                    spacing={1}
                    justifyContent={"flex-end"}
                  >
                    <Button
                      variant="contained"
                      color="theme"
                      onClick={() => onEditRoleClick(role, index)}
                      sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        textTransform: "none",
                        maxWidth: "55px",
                        minWidth: "30px",
                        minHeight: "28px",
                        maxHeight: "28px",
                      }}
                      disableElevation
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="theme"
                      onClick={() => onDeleteRoleClick(role, index)}
                      sx={{
                        fontSize: 14,
                        fontWeight: 400,
                        textTransform: "none",
                        maxWidth: "75px",
                        minWidth: "30px",
                        minHeight: "28px",
                        maxHeight: "28px",
                      }}
                      disableElevation
                    >
                      Delete
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {roles.length === 0 && <NoDataCard title={"No Data Found"} />}
      <PaginationTable
        page={paginationData.page - 1}
        onSetPage={(page) => setPageNumber(page + 1)}
        rowsPerPage={itemsPerPage}
        onSetRowsPerPage={setItemsPerPage}
        totalCount={paginationData.total_count}
      />
    </Stack>
  );
};

AdminRolesTable.propTypes = {
  page: PropTypes.number,
  roles: PropTypes.array,
};
