import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import NoDataCard from "../cards/NoDataCard";

export const OrgListTable = (props) => {
  const { data, onClickEdit } = props;
  const theme = useTheme();

  return (
    <Stack
      direction={"column"}
      sx={{ bgcolor: theme.palette.common.white, borderRadius: 3, p: 2 }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ height: 70 }}>
              <TableCell sx={{ py: "12px" }}>
                <Typography variant="h2">No.</Typography>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <Typography variant="h2">Organisation Name</Typography>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <Typography variant="h2">Organisation Code</Typography>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <Typography variant="h2">Store URL</Typography>
              </TableCell>
              <TableCell sx={{ py: 0 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((org, index) => {
              return (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  key={index}
                >
                  <TableCell sx={{ py: "12px" }}>
                    <Typography variant="p1">{index + 1}</Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0, maxWidth: "250px" }}>
                    <Typography variant="p1">
                      {`${org.name} `}
                      {org.is_superuser ? <b>(Primary)</b> : ""}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0 }}>
                    <Typography variant="p1">{org.code}</Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0 }}>
                    <Typography variant="p1">
                      {org.base_url}/{org.path}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" sx={{ py: 0 }}>
                    {!org.is_superuser && (
                      <Button
                        color="theme"
                        onClick={() => onClickEdit(org)}
                        sx={{
                          fontSize: 14,
                          fontWeight: 400,
                          textTransform: "none",
                          maxWidth: "55px",
                          minWidth: "30px",
                          minHeight: "28px",
                          maxHeight: "28px",
                        }}
                        disableElevation
                        size="small"
                      >
                        Edit
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length === 0 && <NoDataCard title={"No Data Found"} />}
    </Stack>
  );
};
