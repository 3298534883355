import {
  Box,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useState, useEffect, Fragment } from "react";
import { Status } from "../basic/Status";
import { PaginationTable } from "./PaginationTable";
import {
  getCurrentDateTime,
  getRemainingSeconds,
} from "../../utilities/extensions";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks";
import { useParams } from "react-router-dom";
import LoaderCard from "../cards/LoaderCard";
import NoDataCard from "../cards/NoDataCard";

export const DeletedCustomerTransactionTable = () => {
  const { showToast } = useToast();
  const { logout } = useAuth();
  const { api, urls, isLoading } = useAPI();
  const { uid } = useParams();
  const showHeader = true;

  const theme = useTheme();

  const getReason = (status) => {
    if (status?.toLowerCase() === "failed") {
      return "Timeout";
    } else {
      return status;
    }
  };

  const [requestData, setRequestData] = useState({
    itemsPerPage: 25,
    currentPage: 1,
    searchText: "",
  });

  const [transactionsList, setTransactionsList] = useState([]);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    limit: 0,
    total_count: 0,
  });

  //api
  const getTransactionsList = async () => {
    const { itemsPerPage, currentPage, searchText } = requestData;
    api
      .get(
        `${urls.deletedCustomerTransactionLogs}${uid}/?page=${currentPage}&limit=${itemsPerPage}&search=${searchText}`
      )
      .then((res) => {
        let data = res.data.data;
        setTransactionsList(data.data);
        setPaginationData(data.pagination);
      })
      .catch(handleApiError);
  };

  //error handling
  const handleApiError = (err) => {
    if (err.response?.status === 401) {
      logout();
    } else {
      console.log("err", err);
      showToast(err.response?.data?.message, "error");
    }
  };

  const updateRequestData = (newData) => {
    setRequestData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  const convertUTCtoLocalDateTime = (utcDateTimeString) => {
    const utcDateTime = new Date(utcDateTimeString);
    return utcDateTime.toLocaleString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: true, // specify AM/PM
    });
  };

  //useEffect
  useEffect(() => {
    getTransactionsList();
  }, [requestData]);

  return (
    <Stack
      direction={"column"}
      sx={{
        bgcolor: theme.palette.common.white,
        borderRadius: 3,
        pt: showHeader ? 2 : "12px",
        pl: 2,
        pr: 2,
        pb: "12px",
      }}
    >
      {isLoading && <LoaderCard />}
      {!isLoading && (
        <Fragment>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ height: 70 }}>
                  <TableCell sx={{ py: "12px" }}>
                    <Typography variant="h2">No.</Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0 }}>
                    <Typography variant="h2">Transaction ID</Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0 }}>
                    <Typography variant="h2">Device Name</Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0 }}>
                    <Typography variant="h2">Date & Time</Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0 }} align="center">
                    <Typography variant="h2">Reason</Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0 }}>
                    <Typography variant="h2">Amount</Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0 }} align="right">
                    <Typography variant="h2">Authorization Status</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionsList.map((transaction, index) => (
                  <TableRow>
                    <TableCell sx={{ py: "12px" }}>
                      <Typography variant="p1">
                        {requestData.itemsPerPage * (paginationData?.page - 1) +
                          index +
                          1}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ py: 0 }}>
                      <Typography variant="p1">
                        {transaction.transaction_id}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ py: 0 }}>
                      <Typography variant="p1">
                        {transaction.user_device}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ py: 0 }}>
                      <Typography variant="p1">
                        {convertUTCtoLocalDateTime(transaction.created_at)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ py: 0 }} align="center">
                      <Typography
                        variant="p1"
                        align="center"
                        justifyContent={"center"}
                        display={"flex"}
                      >
                        <Status
                          type={
                            transaction.status === "True"
                              ? "Transaction initiated"
                              : getReason(
                                  checkStatusTimeout(
                                    transaction.status ?? "N/A",
                                    transaction.created_at
                                  )
                                )
                          }
                        />
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ py: 0 }}>
                      <Typography variant="p1">
                        {transaction.currency} {transaction.transaction_amount}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ py: 0 }}>
                      <Box justifyContent={"right"} display={"flex"}>
                        <Status
                          type={checkStatusTimeout(
                            transaction.transaction_status ?? "N/A",
                            transaction.created_at
                          )}
                        />
                        {/* <Status type={transaction.transaction_status} /> */}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!isLoading && transactionsList.length === 0 && <NoDataCard />}

          <PaginationTable
            page={paginationData?.page ? paginationData.page - 1 : 0}
            onSetPage={(page) => updateRequestData({ currentPage: page + 1 })} //page start with zero
            rowsPerPage={requestData.itemsPerPage}
            onSetRowsPerPage={(count) =>
              updateRequestData({ itemsPerPage: count, currentPage: 1 })
            }
            totalCount={
              paginationData?.total_count ? paginationData.total_count : 0
            }
          />
        </Fragment>
      )}
    </Stack>
  );
};

const checkStatusTimeout = (status, createdAt) => {
  if (
    status?.toLowerCase() === "initiated" &&
    getRemainingSeconds(createdAt, getCurrentDateTime()) >= 90
  ) {
    return "Failed";
  } else if (status?.toLowerCase() === "cancelled") {
    return "Failed";
  } else {
    return status;
  }

  // if (status.toLowerCase() === "approved") {
  //   return status;
  // } else {
  //   if (getRemainingSeconds(createdAt, getCurrentDateTime()) >= 90) {
  //     return "Failed";
  //   } else {
  //     return status;
  //   }
  // }
};
