export default function themeTypography(theme) {
  return {
    fontFamily:
      "Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Manrope, Rockwell, sans-serif",
    h1: {
      fontSize: "24px",
      fontWeight: 700,
      color: "#000000",
      fontStyle: "normal",
    },
    h2: {
      fontSize: "16px",
      fontWeight: 700,
      color: "#000000",
      fontStyle: "normal",
    },
    p1: {
      fontSize: "16px",
      fontWeight: 400,
      color: "#000000",
      fontStyle: "normal",
    },
    p2: {
      fontSize: "14px",
      fontWeight: 400,
      color: "#000000",
      fontStyle: "normal",
    },
    c1: {
      fontSize: "14px",
      fontWeight: 500,
      color: "#000000",
      fontStyle: "normal",
    },
    c2: {
      fontSize: "20px",
      fontWeight: 700,
      color: "#000000",
      fontStyle: "normal",
    },
    c3: {
      fontSize: "20px",
      fontWeight: 400,
      color: "#000000",
      fontStyle: "normal",
    },
    c4: {
      fontSize: "16px",
      fontWeight: 500,
      color: "#000000",
      fontStyle: "normal",
    },
    authTitle: {
      fontSize: 42,
      fontWeight: 700,
      fontFamily: "Rockwell",
    },
    authMessage: {
      fontSize: 20,
      fontWeight: 500,
      fontFamily: "Helvetica Neue",
    },
  };
}
