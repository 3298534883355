import React, { useState } from "react";
import { Modal, Box, useTheme } from "@mui/material";
import ImageViewer from "react-simple-image-viewer";
import { imagePlaceholder } from "../../assets";
import PropTypes from "prop-types";

const ZoomableImage = ({ src, customPlaceholder, border }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const handleOpen = () => {
    if (src !== "") {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <img
        src={
          src !== ""
            ? src
            : customPlaceholder !== ""
            ? customPlaceholder
            : imagePlaceholder
        }
        alt="Click to zoom"
        style={{
          width: "100%",
          cursor: src !== "" ? "pointer" : "auto",
          borderRadius: border,
        }}
        onClick={handleOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          style={{
            backgroundColor: theme.palette.background.paper,
            border: "2px solid #000",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
          }}
        >
          <ImageViewer
            src={[src]}
            currentIndex={currentImage}
            onClose={handleClose}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
            }}
          />
        </Box>
      </Modal>
    </Box>
  );
};
ZoomableImage.propTypes = {
  src: PropTypes.string.isRequired,
  customPlaceholder: PropTypes.string,
  border: PropTypes.string,
};
ZoomableImage.defaultProps = {
  src: "",
  customPlaceholder: "",
  border: "0px",
};

export default ZoomableImage;
