import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from "@mui/material";
import { PaginationTable } from "./PaginationTable";
import { SwitchLabel } from "../basic/SwitchLabel";
import PropTypes from "prop-types";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import NoDataCard from "../cards/NoDataCard";

export const AdminUsersAUTable = (props) => {
  const {
    users,
    onAccessChange,
    onSetPassword,
    onEditUser,
    paginationData,
    itemsPerPage,
    setItemsPerPage,
    setPageNumber,
    sortData,
    setSortData,
    resetingPassword,
  } = props;

  const theme = useTheme();

  const sortingTapped = (column) => {
    switch (column) {
      case "full_name":
        if (sortData.sort_field === "full_name") {
          //reverse the order
          setSortData({
            ...sortData,
            sort_order: sortData.sort_order === "asc" ? "desc" : "asc",
          });
        } else {
          setSortData({
            sort_order: "asc",
            sort_field: "full_name",
          });
        }
        return;
      case "role_name":
        if (sortData.sort_field === "role_name") {
          //reverse the order
          setSortData({
            ...sortData,
            sort_order: sortData.sort_order === "asc" ? "desc" : "asc",
          });
        } else {
          setSortData({
            sort_order: "asc",
            sort_field: "role_name",
          });
        }
        return;
      case "email":
        if (sortData.sort_field === "email") {
          //reverse the order
          setSortData({
            ...sortData,
            sort_order: sortData.sort_order === "asc" ? "desc" : "asc",
          });
        } else {
          setSortData({
            sort_order: "asc",
            sort_field: "email",
          });
        }
        return;
      case "is_active":
        if (sortData.sort_field === "is_active") {
          //reverse the order
          setSortData({
            ...sortData,
            sort_order: sortData.sort_order === "asc" ? "desc" : "asc",
          });
        } else {
          setSortData({
            sort_order: "asc",
            sort_field: "is_active",
          });
        }
        return;
      default:
        return;
    }
  };

  return (
    <Stack
      direction={"column"}
      sx={{
        bgcolor: theme.palette.common.white,
        borderRadius: 3,
        pt: "12px",
        pl: 2,
        pr: 2,
        pb: "12px",
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ height: 70 }}>
              <TableCell sx={{ py: "12px" }}>
                <Typography variant="h2">No.</Typography>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <TableSortLabel
                  active={true}
                  direction="asc"
                  sx={{
                    "&.MuiTableSortLabel-root": {
                      color: "black",
                    },
                  }}
                  IconComponent={
                    sortData.sort_field === "full_name"
                      ? sortData.sort_order === "asc"
                        ? ArrowDownwardIcon
                        : ArrowUpwardIcon
                      : UnfoldMoreIcon
                  }
                  onClick={() => sortingTapped("full_name")}
                >
                  <Typography variant="h2">Name</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <TableSortLabel
                  active={true}
                  direction="asc"
                  sx={{
                    "&.MuiTableSortLabel-root": {
                      color: "black",
                    },
                  }}
                  IconComponent={
                    sortData.sort_field === "role_name"
                      ? sortData.sort_order === "asc"
                        ? ArrowDownwardIcon
                        : ArrowUpwardIcon
                      : UnfoldMoreIcon
                  }
                  onClick={() => sortingTapped("role_name")}
                >
                  <Typography variant="h2">Role</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <TableSortLabel
                  active={true}
                  direction="asc"
                  sx={{
                    "&.MuiTableSortLabel-root": {
                      color: "black",
                    },
                  }}
                  IconComponent={
                    sortData.sort_field === "email"
                      ? sortData.sort_order === "asc"
                        ? ArrowDownwardIcon
                        : ArrowUpwardIcon
                      : UnfoldMoreIcon
                  }
                  onClick={() => sortingTapped("email")}
                >
                  <Typography variant="h2">Email ID</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <TableSortLabel
                  active={true}
                  direction="asc"
                  sx={{
                    "&.MuiTableSortLabel-root": {
                      color: "black",
                    },
                  }}
                  IconComponent={
                    sortData.sort_field === "is_active"
                      ? sortData.sort_order === "asc"
                        ? ArrowDownwardIcon
                        : ArrowUpwardIcon
                      : UnfoldMoreIcon
                  }
                  onClick={() => sortingTapped("is_active")}
                >
                  <Typography variant="h2">Access</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ py: 0 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, index) => (
              <TableRow key={index}>
                <TableCell sx={{ py: "12px" }}>
                  <Typography variant="p1">{index + 1}</Typography>
                </TableCell>
                <TableCell sx={{ py: 0 }}>
                  <Typography variant="p1">{user.first_name}</Typography>
                </TableCell>
                <TableCell sx={{ py: 0 }}>
                  <Typography variant="p1">{user.role?.role_name}</Typography>
                </TableCell>
                <TableCell sx={{ py: 0 }}>
                  <Typography variant="p1">{user.email}</Typography>
                </TableCell>
                <TableCell sx={{ py: 0 }}>
                  <SwitchLabel
                    status={user.is_active}
                    onSwitchChange={(checked) => {
                      onAccessChange(user, checked);
                    }}
                  />
                </TableCell>
                <TableCell sx={{ py: 0 }} align="right">
                  <Button
                    variant="contained"
                    color="theme"
                    onClick={() => onEditUser(user)}
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      textTransform: "none",
                      maxWidth: "55px",
                      minWidth: "30px",
                      minHeight: "28px",
                      maxHeight: "28px",
                      mr: 1,
                    }}
                    disableElevation
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="theme"
                    onClick={() => onSetPassword(user)}
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      textTransform: "none",
                      minHeight: "28px",
                      maxHeight: "28px",
                    }}
                    disabled={resetingPassword}
                  >
                    Reset Password
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {users.length === 0 && <NoDataCard title={"No Data Found"} />}
      <PaginationTable
        page={paginationData.page - 1}
        onSetPage={(page) => setPageNumber(page + 1)}
        rowsPerPage={itemsPerPage}
        onSetRowsPerPage={setItemsPerPage}
        totalCount={paginationData.total_count}
      />
    </Stack>
  );
};

AdminUsersAUTable.propTypes = {
  page: PropTypes.number,
  users: PropTypes.array,
  onAccessChange: PropTypes.func,
  onSetPassword: PropTypes.func,
  onEditUser: PropTypes.func,
  resetingPassword: PropTypes.bool,
};
