import React, { Fragment, useState, useEffect } from "react";
import { Grid, useTheme } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import { MainRoute } from ".";
import { useAuth } from "../context";
import { DashboardScreen } from "../pages/Dashboard/DashboardScreen";
import { SideMenu } from "../components/menu/side_menu/SideMenu";
import { DigitalOnboardingCustomersScreen } from "../pages/DigitalOnboardingCustomers/DigitalOnboardingCustomersScreen";
import { AuthenticationCustomersScreen } from "../pages/AuthenticationCustomers/AuthenticationCustomersScreen";
import { UnverifiedCustomersScreen } from "../pages/UnverifiedCustomers/UnverifiedCustomersScreen";
import { UnRegisteredListScreen } from "../pages/UnregisteredList/UnregisteredListScreen";
import { CustomerArchivesScreen } from "../pages/CustomerArchives/CustomerArchivesScreen";
import { AdminUsersScreen } from "../pages/AdminUsers/AdminUsersScreen";
import { MemberDetailsScreen } from "../pages/MemberDetails/MemberDetailsScreen";
import { DigitalOnboardingSettingsScreen } from "../pages/DigitalOnboardingSettings/DigitalOnboardingSettingsScreen";
import { TransactionsScreen } from "../pages/Transactions/TransactionsScreen";
import { AdminRolesScreen } from "../pages/AdminRoles/AdminRolesScreen";
import { RiskBasedAuthSettingsScreen } from "../pages/RiskBasedAuthSettings/RiskBasedAuthSettingsScreen";
import { UnverifiedMemberDetailsScreen } from "../pages/MemberDetails/UnverifiedMemberDetailsScreen";
import { DeletedUserUnverifiedMemberDetailsScreen } from "../pages/MemberDetails/DeletedUserUnverifiedMemberDetailsScreen";
import { StatusDetailsScreen } from "../pages/StatusDetails/StatusDetailsScreen";
import { DocumentStatusDetailsScreen } from "../pages/StatusDetails/DocumentStatusScreen";
import NotFoundPage from "../pages/NotFoundPage";
import { useAPI, useModal } from "../hooks";
import { useToast } from "../context";
import { useLocalStorage } from "../hooks";
import { getViewPermissionStatus } from "../utilities/get-permission";
import { OrganisationsListScreen } from "../pages/Organisations/OrganisationsListScren";
import { DeletedMemberDetailsScreen } from "../pages/MemberDetails/DeletedMemberDetailsScreen";
import { DeletedUserStatusDetailsScreen } from "../pages/StatusDetails/DeletedUserStatusDetailsScreen";
import { DeletedUserDocumentStatusDetailsScreen } from "../pages/StatusDetails/DeletedUserDocumentStatusScreen";

export function Main() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isLoggedIn, isAdmin, logout, isMainOrg } = useAuth();
  const { api, urls, isLoading } = useAPI();
  const { setItem } = useLocalStorage();
  const { showToast } = useToast();
  const [isSuperAdmin, setIsSuperAdmin] = useState(isAdmin);
  const [rolesArray, setRolesArray] = useState([]);
  const theme = useTheme();

  const getMyRole = async () => {
    api
      .get(urls.getMyRole)
      .then((res) => {
        const data = res.data.data;
        setIsSuperAdmin(data.is_superuser);
        if (data.role) {
          setItem("role", JSON.stringify(data.role));
          setRolesArray(data.role?.permissions);
        }
      })
      .catch(handleApiError);
  };

  //error handling
  const handleApiError = (err) => {
    if (err.response?.status === 401) {
      logout();
    } else {
      console.log("err", err);
      showToast(err.response?.data?.message, "error");
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      getMyRole();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isAdmin !== isSuperAdmin) {
      //update the admin status
      setIsSuperAdmin(isAdmin);
    }
  }, [isAdmin, isLoggedIn]);

  return (
    <Fragment>
      <Grid container direction={"row"}>
        {isLoggedIn && (
          <Grid item>
            <SideMenu
              isOpen={isMenuOpen}
              onOpenMenu={() => setIsMenuOpen(true)}
              onCloseMenu={() => setIsMenuOpen(false)}
            />
          </Grid>
        )}
        <Grid item xs>
          <Routes>
            <Route element={<MainRoute />}>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/dashboard" element={<DashboardScreen />} />

              {(isSuperAdmin ||
                getViewPermissionStatus(
                  rolesArray,
                  "Customer Verification (Maker)"
                ) ||
                getViewPermissionStatus(
                  rolesArray,
                  "Customer Verification (Checker)"
                )) && (
                <Route
                  path="/unverified_customers"
                  element={<UnverifiedCustomersScreen />}
                />
              )}

              {(isSuperAdmin ||
                getViewPermissionStatus(
                  rolesArray,
                  "Customer Verification (Maker)"
                ) ||
                getViewPermissionStatus(
                  rolesArray,
                  "Customer Verification (Checker)"
                )) && (
                <Route
                  path="/unverified_customers/details/:uid"
                  element={<UnverifiedMemberDetailsScreen />}
                />
              )}
              {isSuperAdmin && (
                <Route
                  path="/archived_unverified_customers/details/:uid"
                  element={<DeletedUserUnverifiedMemberDetailsScreen />}
                />
              )}

              {(isSuperAdmin ||
                getViewPermissionStatus(
                  rolesArray,
                  "Digital Onboarding Customers"
                )) && (
                <Route
                  path="/digital_onboarding_customers"
                  element={<DigitalOnboardingCustomersScreen />}
                />
              )}
              {(isSuperAdmin ||
                getViewPermissionStatus(
                  rolesArray,
                  "Authentication Customers"
                )) && (
                <Route
                  path="/authentication_customers"
                  element={<AuthenticationCustomersScreen />}
                />
              )}
              {(isSuperAdmin ||
                getViewPermissionStatus(
                  rolesArray,
                  "Digital Onboarding Customers"
                ) ||
                getViewPermissionStatus(
                  rolesArray,
                  "Authentication Customers"
                )) && (
                <Route
                  path="/customers/details/:uid"
                  element={<MemberDetailsScreen />}
                />
              )}

              {isSuperAdmin && (
                <Route
                  path="/unregistered_list"
                  element={<UnRegisteredListScreen />}
                />
              )}
              {isSuperAdmin && (
                <Route
                  path="/customer_archives"
                  element={<CustomerArchivesScreen />}
                />
              )}
              {isSuperAdmin && (
                <Route
                  path="/customer_archive/details/:uid"
                  element={<DeletedMemberDetailsScreen />}
                />
              )}
              {(isSuperAdmin ||
                getViewPermissionStatus(
                  rolesArray,
                  "Digital Onboarding Settings"
                )) && (
                <Route
                  path="/digital_onboarding_settings"
                  element={<DigitalOnboardingSettingsScreen />}
                />
              )}
              {(isSuperAdmin ||
                getViewPermissionStatus(
                  rolesArray,
                  "Authentication Settings"
                )) && (
                <Route
                  path="/risk_based_auth_settings"
                  element={<RiskBasedAuthSettingsScreen />}
                />
              )}
              {isSuperAdmin && (
                <Route path="/admin_users" element={<AdminUsersScreen />} />
              )}
              {isSuperAdmin && (
                <Route path="/admin_roles" element={<AdminRolesScreen />} />
              )}
              {(isSuperAdmin ||
                getViewPermissionStatus(rolesArray, "Transactions")) && (
                <Route path="/transactions" element={<TransactionsScreen />} />
              )}
              {isSuperAdmin && isMainOrg === "true" && (
                <Route
                  path="/organisations"
                  element={<OrganisationsListScreen />}
                />
              )}
              {(isSuperAdmin ||
                getViewPermissionStatus(
                  rolesArray,
                  "Digital Onboarding Customers"
                ) ||
                getViewPermissionStatus(
                  rolesArray,
                  "Authentication Customers"
                )) && (
                <Route
                  path="/customers/details/:id/aml_data/:type/:amlId"
                  element={<StatusDetailsScreen />}
                />
              )}
              {isSuperAdmin && (
                <Route
                  path="/customer_archive/details/:id/aml_data/:type/:amlId"
                  element={<DeletedUserStatusDetailsScreen />}
                />
              )}
              {(isSuperAdmin ||
                getViewPermissionStatus(
                  rolesArray,
                  "Digital Onboarding Customers"
                ) ||
                getViewPermissionStatus(
                  rolesArray,
                  "Authentication Customers"
                )) && (
                <Route
                  path="/customers/details/:id/document_status/:uid"
                  element={<DocumentStatusDetailsScreen />}
                />
              )}
              {isSuperAdmin && (
                <Route
                  path="/customer_archive/details/:id/document_status/:uid"
                  element={<DeletedUserDocumentStatusDetailsScreen />}
                />
              )}

              {/* 404 Page */}
              {!isLoading && <Route path="*" element={<NotFoundPage />} />}
            </Route>
          </Routes>
        </Grid>
      </Grid>
    </Fragment>
  );
}
