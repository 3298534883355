export const sideMenuItems = [
  {
    type: 1,
    title: "Dashboard",
    link: "/dashboard",
  },

  {
    type: 2,
    title: "Digital Onboarding Customers",
    link: "/digital_onboarding_customers",
  },

  {
    type: 3,
    title: "Authentication Customers",
    link: "/authentication_customers",
  },
  {
    type: 12,
    title: "Transactions",
    link: "/transactions",
  },
  {
    type: 4,
    title: "Unverified Customers",
    link: "/unverified_customers",
  },
  // {
  //   type: 5,
  //   title: "Unregistered List",
  //   link: "/unregistered_list",
  // },
  {
    type: 6,
    title: "Customer Archives",
    link: "/customer_archives",
  },
  {
    type: 7,
    title: "Digital Onboarding Settings",
    link: "/digital_onboarding_settings",
  },
  {
    type: 8,
    title: "Authentication Settings",
    link: "/risk_based_auth_settings",
  },
  {
    type: 9,
    title: "Admin Users",
    link: "/admin_users",
  },
  {
    type: 10,
    title: "Admin Roles",
    link: "/admin_roles",
  },
  {
    type: 11,
    title: "Settings",
    link: "/organisations",
  },
];
