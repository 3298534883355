import { Fragment, useEffect, useState } from "react";
import { ActionBar } from "../../components/headers/ActionBar";
import { PageStackLayout } from "../../components/layout/PageStackLayout";
import { Typography, Button, Stack, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAPI, useModal } from "../../hooks";
import { useToast } from "../../context";
import LoaderCard from "../../components/cards/LoaderCard";
import { AdminRolesTable } from "../../components/table/AdminRolesTable";
import { AddAdminRoleModal } from "../../components/modals/AddAdminRoleModal";
import { EditAdminRoleModal } from "../../components/modals/EditAdminRoleModal";
import { ReminderAlert } from "../../components/alert/ReminderAlert";
import { DeleteAdminErrorAlert } from "../../components/alert/DeleteAdminErrorAlert";
import { Add } from "iconsax-react";

export const AdminRolesScreen = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { api, urls, isLoading } = useAPI();
  const { showToast } = useToast();
  const [adminRoles, setAdminRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [rolesLoading, setRolesLoading] = useState(false);
  const [userSelectedpermissions, setUserSelectedPermissions] = useState([]);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    limit: 25,
    total_count: 0,
  });
  const [isOpenAddModal, openAddModal, closeAddModal] = useModal();
  const [isOpenEditModal, openEditModal, closeEditModal] = useModal();
  const [isOpenDeleteModal, openDeleteModal, closeDeleteModal] = useModal();
  const [isOpenDeleteErrorModal, openDeleteErrorModal, closeDeleteErrorModal] =
    useModal();
  const [editRoleItem, setEditRoleItem] = useState(null);
  const [deleteRoleItem, setDeleteRoleItem] = useState(null);

  const onAddClick = () => {
    if (permissions.length > 0) {
      setUserSelectedPermissions(permissions);
      openAddModal();
    } else {
      showToast("Failed to load the roles, please reload the page", "error");
    }
  };

  const roleCreated = () => {
    closeAddModal();
    showToast("Role created successfully", "success");
    getRoleList();
  };

  const roleUpdated = () => {
    closeEditModal();
    showToast("Role updated successfully", "success");
    getRoleList();
  };

  const getRoleList = async () => {
    setRolesLoading(true);
    let params = { ...paginationData };
    delete params.total_count;
    api
      .get(urls.roleList, { params: params })
      .then((res) => {
        setAdminRoles(res.data.data.data);
        setRolesLoading(false);
        setPaginationData((prev) => ({
          ...prev,
          total_count: res.data.data.pagination.total_count,
        }));
      })
      .catch((err) => {
        setRolesLoading(false);
        showToast(err.response?.data?.data?.message, "error");
      });
  };

  const onClickConfirmDelete = async () => {
    closeDeleteModal();
    api
      .delete(`${urls.deleteRole}${deleteRoleItem.uuid}`)
      .then((res) => {
        showToast("Role has deleted successfully", "success");
        getRoleList();
      })
      .catch((err) => {
        showToast(err.response?.data?.data?.message, "error");
      });
  };

  const getPermissionsList = async () => {
    api
      .get(`${urls.permissionsList}?page=1&limit=50`)
      .then((res) => {
        const data = res.data.data.data;
        const permissionsArray = data.map((permission) => {
          return { ...permission, n_a: permission.n_a !== null ? 1 : null };
        });
        setPermissions(permissionsArray);
        setUserSelectedPermissions(permissionsArray);
      })
      .catch((err) => {
        showToast(err.response?.data?.data?.message, "error");
      });
  };

  const onEditRoleClick = (role, index) => {
    setEditRoleItem(role);
    openEditModal();
  };

  const onDeleteRoleClick = (role, index) => {
    // openDeleteModal();
    if (role.user_count > 0) {
      openDeleteErrorModal();
    } else {
      setDeleteRoleItem(role);
      openDeleteModal();
    }
  };

  useEffect(() => {
    getRoleList();
  }, [paginationData.page, paginationData.limit]);

  useEffect(() => {
    getPermissionsList();
  }, []);

  return (
    <Fragment>
      <ActionBar header={"Admin Roles"} onAddClick={onAddClick} />

      <PageStackLayout>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant="h1">Admin Roles</Typography>
          <Button
            variant="contained"
            disableElevation
            color="theme"
            startIcon={<Add size="25" color={theme.palette.common.white} />}
            onClick={onAddClick}
            sx={{
              height: 40,
              pl: 2,
              pr: 2,
              borderRadius: 2,
              fontSize: 16,
              fontWeight: 700,
              textTransform: "none",
            }}
          >
            Add Role
          </Button>
        </Stack>
        {rolesLoading ? (
          <LoaderCard />
        ) : (
          <AdminRolesTable
            roles={adminRoles}
            paginationData={paginationData}
            itemsPerPage={paginationData.limit}
            setItemsPerPage={(count) =>
              setPaginationData((prev) => ({ ...prev, limit: count }))
            }
            setPageNumber={(page) =>
              setPaginationData((prev) => ({ ...prev, page: page }))
            }
            onEditRoleClick={onEditRoleClick}
            onDeleteRoleClick={onDeleteRoleClick}
          />
        )}
      </PageStackLayout>
      {isOpenAddModal && (
        <AddAdminRoleModal
          open={isOpenAddModal}
          onClose={closeAddModal}
          data={userSelectedpermissions}
          existingRoles={adminRoles}
          roleCreated={roleCreated}
        />
      )}
      {isOpenEditModal && (
        <EditAdminRoleModal
          open={isOpenEditModal}
          onClose={closeEditModal}
          data={editRoleItem}
          existingRoles={adminRoles}
          roleUpdated={roleUpdated}
        />
      )}
      {isOpenDeleteModal && (
        <ReminderAlert
          open={isOpenDeleteModal}
          onClose={closeDeleteModal}
          onConfirm={onClickConfirmDelete}
          header={"Delete Role"}
          reminder={"Are you sure, you want to delete this role?"}
          closeText={"Cancel"}
          confirmText={"Confirm"}
        />
      )}
      {isOpenDeleteErrorModal && (
        <DeleteAdminErrorAlert
          open={isOpenDeleteErrorModal}
          onClose={closeDeleteErrorModal}
        />
      )}
    </Fragment>
  );
};
