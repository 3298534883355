import {
  Box,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from "@mui/material";
import { Status } from "../basic/Status";
import { PaginationTable } from "./PaginationTable";
import { SearchField } from "../fields/SearchField";
import {
  getCurrentDateTime,
  getRemainingSeconds,
} from "../../utilities/extensions";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import NoDataCard from "../cards/NoDataCard";

export const TransactionsCOMTable = (props) => {
  const {
    data,
    showHeader,
    paginationData,
    itemsPerPage,
    setItemsPerPage,
    setPageNumber,
    sortData,
    setSortData,
  } = props;

  const theme = useTheme();

  const getReason = (status) => {
    if (status?.toLowerCase() === "failed") {
      return "Timeout";
    } else {
      return status;
    }
  };

  const convertUTCtoLocalDateTime = (utcDateTimeString) => {
    const utcDateTime = new Date(utcDateTimeString);
    return utcDateTime.toLocaleString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: true, // specify AM/PM
    });
  };

  const sortingTapped = (column) => {
    switch (column) {
      case "customer":
        if (sortData.sort_field === "customer") {
          //reverse the order
          setSortData({
            ...sortData,
            sort_order: sortData.sort_order === "asc" ? "desc" : "asc",
          });
        } else {
          setSortData({
            sort_order: "asc",
            sort_field: "customer",
          });
        }
        return;

      case "user_device":
        if (sortData.sort_field === "user_device") {
          //reverse the order
          setSortData({
            ...sortData,
            sort_order: sortData.sort_order === "asc" ? "desc" : "asc",
          });
        } else {
          setSortData({
            sort_order: "asc",
            sort_field: "user_device",
          });
        }
        return;
      case "created_at":
        if (sortData.sort_field === "created_at") {
          //reverse the order
          setSortData({
            ...sortData,
            sort_order: sortData.sort_order === "asc" ? "desc" : "asc",
          });
        } else {
          setSortData({
            sort_order: "asc",
            sort_field: "created_at",
          });
        }
        return;
      case "transaction_amount":
        if (sortData.sort_field === "transaction_amount") {
          //reverse the order
          setSortData({
            ...sortData,
            sort_order: sortData.sort_order === "asc" ? "desc" : "asc",
          });
        } else {
          setSortData({
            sort_order: "asc",
            sort_field: "transaction_amount",
          });
        }
        return;
      default:
        return;
    }
  };

  return (
    <Stack
      direction={"column"}
      sx={{
        bgcolor: theme.palette.common.white,
        borderRadius: 3,
        pt: showHeader ? 2 : "12px",
        pl: 2,
        pr: 2,
        pb: "12px",
      }}
    >
      {showHeader && (
        <Stack direction={"column"} sx={{ gap: 2 }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h1">Transactions</Typography>
            <SearchField placeholder={"Search by Name or Transaction ID"} />
          </Stack>
          <Divider />
        </Stack>
      )}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ height: 70 }}>
              <TableCell sx={{ py: "12px" }}>
                <Typography variant="h2">No.</Typography>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <Typography variant="h2">Transaction ID</Typography>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <TableSortLabel
                  active={true}
                  direction="asc"
                  sx={{
                    "&.MuiTableSortLabel-root": {
                      color: "black",
                    },
                  }}
                  IconComponent={
                    sortData.sort_field === "user_device"
                      ? sortData.sort_order === "asc"
                        ? ArrowDownwardIcon
                        : ArrowUpwardIcon
                      : UnfoldMoreIcon
                  }
                  onClick={() => sortingTapped("user_device")}
                >
                  <Typography variant="h2">Device Name</Typography>
                </TableSortLabel>
              </TableCell>

              <TableCell sx={{ py: 0 }}>
                <TableSortLabel
                  active={true}
                  direction="asc"
                  sx={{
                    "&.MuiTableSortLabel-root": {
                      color: "black",
                    },
                  }}
                  IconComponent={
                    sortData.sort_field === "customer"
                      ? sortData.sort_order === "asc"
                        ? ArrowDownwardIcon
                        : ArrowUpwardIcon
                      : UnfoldMoreIcon
                  }
                  onClick={() => sortingTapped("customer")}
                >
                  <Typography variant="h2">Customer Name</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <TableSortLabel
                  active={true}
                  direction="asc"
                  sx={{
                    "&.MuiTableSortLabel-root": {
                      color: "black",
                    },
                  }}
                  IconComponent={
                    sortData.sort_field === "created_at"
                      ? sortData.sort_order === "asc"
                        ? ArrowDownwardIcon
                        : ArrowUpwardIcon
                      : UnfoldMoreIcon
                  }
                  onClick={() => sortingTapped("created_at")}
                >
                  <Typography variant="h2">Date & Time</Typography>
                </TableSortLabel>
              </TableCell>

              <TableCell sx={{ py: 0 }} align="center">
                <Typography variant="h2">Reason</Typography>
              </TableCell>
              <TableCell sx={{ py: 0 }}>
                <TableSortLabel
                  active={true}
                  direction="asc"
                  sx={{
                    "&.MuiTableSortLabel-root": {
                      color: "black",
                    },
                  }}
                  IconComponent={
                    sortData.sort_field === "transaction_amount"
                      ? sortData.sort_order === "asc"
                        ? ArrowDownwardIcon
                        : ArrowUpwardIcon
                      : UnfoldMoreIcon
                  }
                  onClick={() => sortingTapped("transaction_amount")}
                >
                  <Typography variant="h2">Amount</Typography>
                </TableSortLabel>
              </TableCell>

              <TableCell sx={{ py: 0 }} align="right">
                <Typography variant="h2">Authorization Status</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((transaction, index) => (
              <TableRow key={index}>
                <TableCell sx={{ py: "12px" }}>
                  <Typography variant="p1">
                    {itemsPerPage * (paginationData.page - 1) + index + 1}
                  </Typography>
                </TableCell>
                <TableCell sx={{ py: 0 }}>
                  <Typography variant="p1">
                    {transaction.transaction_id}
                  </Typography>
                </TableCell>
                <TableCell sx={{ py: 0 }}>
                  <Typography variant="p1">
                    {transaction.user_device}
                  </Typography>
                </TableCell>
                <TableCell sx={{ py: 0 }}>
                  <Typography variant="p1">{transaction.customer}</Typography>
                </TableCell>
                <TableCell sx={{ py: 0 }}>
                  <Typography variant="p1">
                    {convertUTCtoLocalDateTime(transaction.created_at)}
                  </Typography>
                </TableCell>
                <TableCell sx={{ py: 0 }} align="center">
                  <Typography
                    variant="p1"
                    align="center"
                    justifyContent={"center"}
                    display={"flex"}
                  >
                    <Status
                      type={
                        transaction.status === "True"
                          ? "Transaction initiated"
                          : getReason(
                              checkStatusTimeout(
                                transaction.status ?? "N/A",
                                transaction.created_at
                              )
                            )
                      }
                    />
                  </Typography>
                </TableCell>
                <TableCell sx={{ py: 0 }}>
                  <Typography variant="p1">
                    {transaction.currency} {transaction.transaction_amount}
                  </Typography>
                </TableCell>
                <TableCell sx={{ py: 0 }}>
                  <Box justifyContent={"right"} display={"flex"}>
                    <Status
                      type={checkStatusTimeout(
                        transaction.transaction_status ?? "N/A",
                        transaction.created_at
                      )}
                    />
                    {/* <Status type={transaction.transaction_status} /> */}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length === 0 && <NoDataCard title={"No Data Found"} />}
      <PaginationTable
        page={paginationData.page - 1}
        onSetPage={(page) => setPageNumber(page + 1)} //page start with zero
        rowsPerPage={itemsPerPage}
        onSetRowsPerPage={setItemsPerPage}
        totalCount={paginationData.total_count}
      />
    </Stack>
  );
};

const checkStatusTimeout = (status, createdAt) => {
  if (
    status?.toLowerCase() === "initiated" &&
    getRemainingSeconds(createdAt, getCurrentDateTime()) >= 90
  ) {
    return "Failed";
  } else if (status?.toLowerCase() === "cancelled") {
    return "Failed";
  } else {
    return status;
  }

  // if (status.toLowerCase() === "approved") {
  //   return status;
  // } else {
  //   if (getRemainingSeconds(createdAt, getCurrentDateTime()) >= 90) {
  //     return "Failed";
  //   } else {
  //     return status;
  //   }
  // }
};
