import { Stack, Tab, Tabs, useTheme } from "@mui/material";
import PropTypes from "prop-types";

export const MemberDetailsTab = (props) => {
  const {
    selectedTab,
    onSetSelectedTab,
    isUnverifiedMember,
    showEdd,
    showIDverification,
  } = props;

  const theme = useTheme();

  const handleTabChange = (event, newValue) => {
    onSetSelectedTab(newValue);
  };

  const tabStyle = {
    ml: 2,
    mr: 2,
    height: 80,
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 700,
    textTransform: "none",
    "&.Mui-selected": {
      color: theme.palette.theme.main,
    },
  };

  return (
    <Stack
      direction={"row"}
      sx={{
        height: 80,
        bgcolor: theme.palette.common.white,
        borderRadius: 3,
        alignItems: "center",
      }}
    >
      <Tabs
        variant="scrollable"
        scrollButtons={false}
        value={selectedTab}
        onChange={handleTabChange}
        TabIndicatorProps={{
          style: {
            height: 3,
            backgroundColor: theme.palette.theme.main,
          },
        }}
      >
        <Tab label="User Details" sx={tabStyle} />
        {!isUnverifiedMember && <Tab label="Screening Status" sx={tabStyle} />}
        {!isUnverifiedMember && showIDverification && (
          <Tab label="ID Verification" sx={tabStyle} />
        )}
        {!isUnverifiedMember && <Tab label="Transaction Logs" sx={tabStyle} />}
        {!isUnverifiedMember && <Tab label="Edit History" sx={tabStyle} />}
        {!isUnverifiedMember && <Tab label="Security Logs" sx={tabStyle} />}
        {!isUnverifiedMember && showEdd && <Tab label="EDD" sx={tabStyle} />}
      </Tabs>
    </Stack>
  );
};

MemberDetailsTab.propTypes = {
  selectedTab: PropTypes.number.isRequired,
  onSetSelectedTab: PropTypes.func.isRequired,
  isUnverifiedMember: PropTypes.bool,
};

MemberDetailsTab.defaultProps = {
  isUnverifiedMember: false,
};
