import { Stack, useTheme } from "@mui/material";

export const PageStackLayout = ({ children }) => {
  const theme = useTheme();

  return (
    <Stack
      direction={"column"}
      sx={{
        gap: 2,
        p: 2,
        height: "86vh",
        flexWrap: "inherit",
        overflow: "scroll",
        bgcolor: theme.palette.background.main,
      }}
    >
      {children}
    </Stack>
  );
};
