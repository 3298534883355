import { InputAdornment, TextField, useTheme } from "@mui/material";
import PropTypes from "prop-types";

export const LabelPriceTextField = (props) => {
  const {
    label,
    disabled,
    symbol,
    textInput,
    onTextChange,
    error,
    errorMessage,
  } = props;

  const theme = useTheme();

  return (
    <TextField
      fullWidth
      disabled={disabled}
      label={label}
      value={textInput}
      onChange={onTextChange}
      error={error}
      helperText={error ? errorMessage : null}
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{symbol}</InputAdornment>
        ),
      }}
      inputProps={{
        sx: {
          fontSize: 16,
          fontWeight: 700,
          color: theme.palette.primary.main,
        },
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: 2,
          "& fieldset": {
            borderColor: theme.palette.secondary.main,
          },
        },
      }}
    />
  );
};

LabelPriceTextField.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  symbol: PropTypes.string.isRequired,
  textInput: PropTypes.string.isRequired,
  onTextChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

LabelPriceTextField.defaultProps = {
  disabled: false,
  error: false,
};
