import { styled, Button, alpha } from "@mui/material";

const SubmitButton = styled(Button)(
  ({ theme, variant = "contained", disableElevation = true }) => ({
    color: theme.palette.theme.contrastText,
    backgroundColor: theme.palette.theme.main,
    ":hover": {
      backgroundColor: theme.palette.theme.main,
    },
    ":disabled": {
      backgroundColor: alpha(theme.palette.theme.main, 0.7),
    },
    textTransform: "none",
    fontSize: 18,
    fontWeight: 700,
    fontFamily: "Helvetica",
    borderRadius: "10px",
    height: 60,
  })
);

const LinkButton = styled(Button)(({ theme }) => ({
  color: theme.palette.theme.main,
  textTransform: "none",
  fontSize: 16,
  fontWeight: 500,
  fontFamily: "Helvetica Neue",
}));

const OutlinedButton = styled(Button)(
  ({ theme, variant = "outlined", color = "theme" }) => ({
    fontSize: 16,
    fontWeight: 700,
    fontFamily: "Helvetica",
    textTransform: "none",
    borderRadius: 2,
  })
);

export { SubmitButton, LinkButton, OutlinedButton };
