import { Fragment, useEffect, useState } from "react";
import { ActionBar } from "../../components/headers/ActionBar";
import { PageStackLayout } from "../../components/layout/PageStackLayout";
import { MemberDetailsTab } from "../../components/tabs/MemberDetailsTab";
import { DeletedUserDetailsTab } from "./UserDetails/DeletedUserDetailsTab";
import { DeletedCustomerTransactionTable } from "../../components/table/DeletedCustomerTransactionTable";
import { DeletedUserIDVerificationListTable } from "../../components/table/DeletedUserIDVerificationListTable";
import { DeletedUserProfileEditHistoryTable } from "../../components/table/DeletedUserProfileEditHistoryTable";
import { DeletedUserSecurityLogsTable } from "../../components/table/DeletedUserSecurityLogsTable";
import { DeletedUserMoreInfoTab } from "./MoreInfo/DeletedUserMoreInfoTab";
import { DeletedUserStatusCOMTable } from "../../components/table/DeletedUserStatusCOMTable";
import { Typography } from "@mui/material";
import { useLocalStorage } from "../../hooks";
import { useLocation } from "react-router-dom";

export const DeletedMemberDetailsScreen = () => {
  const { getItem, setItem, removeItem } = useLocalStorage();
  const location = useLocation();
  const [userDetails, setUserDetails] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (location.state) {
      if (location.state.clearData) {
        // Clear the clearData flag without redirecting
        const updatedState = { ...location.state };
        delete updatedState.clearData;
        window.history.replaceState(
          { ...location.state, clearData: null },
          "",
          location.pathname
        );

        // Clear the state
        setSelectedTab(0); // Reset selectedTab to default value
        removeItem("tab_id");
      } else {
        setSelectedTab(
          getItem("tab_id") !== null ? parseInt(getItem("tab_id"), 10) : 0
        );
      }
    } else {
      setSelectedTab(
        getItem("tab_id") !== null ? parseInt(getItem("tab_id"), 10) : 0
      );
    }
  }, [location.state]);

  useEffect(() => {
    setItem("tab_id", selectedTab);
  }, [selectedTab]);

  return (
    <Fragment>
      <ActionBar header={"Customer Details"} showBackButton={true} />
      <PageStackLayout>
        <Typography variant="h1">Customer Details</Typography>
        {userDetails && (
          <MemberDetailsTab
            selectedTab={selectedTab}
            onSetSelectedTab={setSelectedTab}
            showEdd={userDetails?.edd_status ?? false}
            showIDverification={(userDetails?.id_type ?? "") !== "m"}
          />
        )}
        {(userDetails?.id_type ?? "") !== "m" ? (
          selectedTab === 0 ? (
            <DeletedUserDetailsTab
              onSetUserDetails={(data) => setUserDetails(data)}
            />
          ) : selectedTab === 1 ? (
            <DeletedUserStatusCOMTable type={1} userDetails={userDetails} />
          ) : selectedTab === 2 ? (
            <DeletedUserIDVerificationListTable
              userDetails={userDetails}
              data={[]}
            />
          ) : selectedTab === 3 ? (
            <DeletedCustomerTransactionTable />
          ) : selectedTab === 4 ? (
            <DeletedUserProfileEditHistoryTable />
          ) : selectedTab === 5 ? (
            <DeletedUserSecurityLogsTable />
          ) : (
            <DeletedUserMoreInfoTab />
          )
        ) : selectedTab === 0 ? (
          <DeletedUserDetailsTab
            onSetUserDetails={(data) => setUserDetails(data)}
          />
        ) : selectedTab === 1 ? (
          <DeletedUserStatusCOMTable type={1} userDetails={userDetails} />
        ) : selectedTab === 2 ? (
          <DeletedCustomerTransactionTable />
        ) : selectedTab === 3 ? (
          <DeletedUserProfileEditHistoryTable />
        ) : selectedTab === 4 ? (
          <DeletedUserSecurityLogsTable />
        ) : (
          <DeletedUserMoreInfoTab />
        )}

        {/* {selectedTab === 0 ? (
          <DeletedUserDetailsTab
            onSetUserDetails={(data) => setUserDetails(data)}
          />
        ) : selectedTab === 1 ? (
          <DeletedUserStatusCOMTable type={1} userDetails={userDetails} />
        ) : selectedTab === 2 ? (
          <DeletedUserIDVerificationListTable
            userDetails={userDetails}
            data={[]}
          />
        ) : selectedTab === 3 ? (
          <DeletedCustomerTransactionTable />
        ) : selectedTab === 4 ? (
          <DeletedUserProfileEditHistoryTable />
        ) : selectedTab === 5 ? (
          <DeletedUserSecurityLogsTable />
        ) : (
          <DeletedUserMoreInfoTab />
        )} */}
      </PageStackLayout>
    </Fragment>
  );
};
