import { Button, Divider, useTheme } from "@mui/material";
import { Stack, Tab, Tabs } from "@mui/material";
import { Add } from "iconsax-react";
import PropTypes from "prop-types";
import { Fragment } from "react";

export const InfoTab = (props) => {
  const { selectedTab, onSetSelectedTab, showAddPage, onAddPage, tabs } = props;

  const theme = useTheme();

  const handleTabChange = (event, newValue) => {
    onSetSelectedTab(newValue);
  };

  const tabStyle = {
    ml: 2,
    mr: 2,
    height: 80,
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 700,
    textTransform: "none",
    "&.Mui-selected": {
      color: theme.palette.theme.main,
    },
  };

  return (
    <Stack
      direction={"row"}
      sx={{
        height: 80,
        bgcolor: theme.palette.common.white,
        borderRadius: 3,
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Tabs
        variant="scrollable"
        scrollButtons={false}
        value={selectedTab}
        onChange={handleTabChange}
        TabIndicatorProps={{
          style: {
            height: 3,
            backgroundColor: theme.palette.theme.main,
          },
        }}
        sx={{ width: "100%" }}
      >
        {tabs.map((tab, ind) => (
          <Tab key={ind} value={ind} label={tab.title} sx={tabStyle} />
          // <Fragment key={ind}>
          //   <Tab value={ind} label={tab.title} sx={tabStyle} />
          //   {ind < tabs.length - 1 && (
          //     <Divider orientation="vertical" flexItem />
          //   )}
          // </Fragment>
        ))}
      </Tabs>
      {showAddPage && (
        <Button
          variant="contained"
          disableElevation
          color="theme"
          onClick={onAddPage}
          startIcon={<Add size="30" color={theme.palette.common.white} />}
          sx={{
            width: 180,
            height: 50,
            ml: 2,
            mr: 2,
            borderRadius: 2,
            fontSize: 16,
            fontWeight: 700,
            textTransform: "none",
          }}
        >
          Add Page
        </Button>
      )}
    </Stack>
  );
};

InfoTab.propTypes = {
  selectedTab: PropTypes.number.isRequired,
  onSetSelectedTab: PropTypes.func.isRequired,
  showAddPage: PropTypes.bool.isRequired,
  onAddPage: PropTypes.func,
  tabs: PropTypes.array,
};

InfoTab.defaultProps = {
  showAddPage: false,
  tabs: [],
};
