import { Dialog, Stack, Typography } from "@mui/material";
import { Backdrop } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useAPI } from "../../hooks";
import { useToast } from "../../context";
import LoaderCard from "../cards/LoaderCard";

export const CheckIdenfaceAlert = (props) => {
  const { open, onClose, onConfirm } = props;

  const { api, urls, isLoading } = useAPI();
  const { showToast } = useToast();
  const [jsonResponse, setJsonResponse] = useState(null);

  const checkIDenFace = async () => {
    api
      .post(urls.checkIdenFace)
      .then((res) => {
        setJsonResponse(res.data.data);
      })
      .catch((err) => {
        showToast(err.response?.data?.data?.message, "error");
      });
  };

  useEffect(() => {
    if (open) {
      checkIDenFace();
    }
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Stack
        direction={"column"}
        sx={{ width: 500, pt: 3, pl: 4, pr: 4, pb: 4, gap: 3 }}
      >
        <Typography
          variant="h1"
          sx={{
            mb: 2,
            textAlign: "center",
          }}
        >
          Check Status
        </Typography>
        {isLoading && <LoaderCard />}
        {!isLoading && (
          <Typography variant="body1">
            {/* Check if JSON data is available */}
            {jsonResponse
              ? JSON.stringify(jsonResponse, null, 2)
              : "Loading..."}
          </Typography>
        )}
      </Stack>
    </Dialog>
  );
};

CheckIdenfaceAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
