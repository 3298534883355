import { createTheme } from "@mui/material/styles";
import themeTypography from "./typography";
import { RockwellRegular } from "../assets";

const generateTheme = (mode) => {
  const isDarkMode = mode === "dark";

  const palette = {
    mode,
    transparent: {
      main: isDarkMode ? "#00000000" : "#00000000",
    },
    common: {
      black: "#00000",
      white: "#FFFFFF",
      lightGreyBg: "#F7F7F7",
      greyBg: "#E8E8E8",
      blueTxt: "#2C74FF",
      redBtn: "#FF1818",
      greyTxt: "#CECECE",
      bgclr: "#EDFAFF",
      greenBg: "#1ACA2C",
      hightlighterOne: "#0874EA0F",
      hightlighterTwo: "#FF8A651A",
      hightlighterThree: "#E9FFEB",
    },
    status: {
      default: "#6B747D",
      notApplicable: "#6B747D",
      pending: "#FFA63D",
      inProgress: "#FFE976",
      secure: "#19A089",
      unsecure: "#F05151",
      approved: "#19A089",
      rejected: "#F05151",
      active: "#19A089",
      inactive: "#F05151",
      success: "#19A089",
      failed: "#F05151",
      verificationSuccessful: "#19A089",
      verificationFailed: "#F05151",
      transactionCompleted: "#19A089",
      transactionInProgress: "#00A9F4",
      transactionRejected: "#F05151",
      transactionFailed: "#F05151",
    },
    amlStatus: {
      red: "#F05151",
      yellow: "#FFA63D",
      green: "#19A089",
      gray: "#6B747D",
      orange: "#FF8A65",
      default: "#6B747D",
    },
    switch: {
      activate: "#19A089",
      deactivate: "#717171",
    },
    primary: {
      main: isDarkMode ? "#000000" : "#000000",
    },
    secondary: {
      main: isDarkMode ? "#777777" : "#777777",
    },
    placeholder: {
      main: isDarkMode ? "#8D8B8B" : "#8D8B8B",
    },
    background: {
      main: isDarkMode ? "#F8F8F8" : "#F8F8F8",
    },
    highlighterBackground: {
      main: isDarkMode ? "#F5F5F5" : "#F5F5F5",
    },
    theme: {
      main: isDarkMode ? "#0874EA" : "#0874EA",
      contrastText: isDarkMode ? "#FFFFFF" : "#FFFFFF",
    },
    lightTheme: {
      main: isDarkMode ? "#0874EA1A" : "#0874EA1A",
      contrastText: isDarkMode ? "#0874EA" : "#0874EA",
    },
    themeHover: {
      main: isDarkMode ? "#0874EA1A" : "#0874EA1A",
    },
    line: {
      main: isDarkMode ? "#E0E0E0" : "#E0E0E0",
    },
    badgeTheme: {
      main: isDarkMode ? "#0874EA" : "#0874EA",
      contrastText: isDarkMode ? "#FFFFFF" : "#FFFFFF",
    },
    badgeThemeSel: {
      main: isDarkMode ? "#FFFFFF" : "#FFFFFF",
      contrastText: isDarkMode ? "#0874EA" : "#0874EA",
    },
    btnPositive: {
      main: isDarkMode ? "#19A089" : "#19A089",
      contrastText: isDarkMode ? "#FFFFFF" : "#FFFFFF",
    },
    btnNegative: {
      main: isDarkMode ? "#F05151" : "#F05151",
      contrastText: isDarkMode ? "#FFFFFF" : "#FFFFFF",
    },
    baseError: {
      main: isDarkMode ? "#00A067" : "#00A067",
    },
    textFieldBorder: {
      main: isDarkMode ? "#D4D3D3" : "#D4D3D3",
    },
    textGray: {
      main: isDarkMode ? "#919191" : "#919191",
    },
    passGreen: {
      main: isDarkMode ? "#0AA91A" : "#0AA91A",
    },
    sideMenuGreen: {
      main: isDarkMode ? "#2ADEC0" : "#2ADEC0",
    },
    popupBlue: {
      main: isDarkMode ? "#EDFAFF" : "#EDFAFF",
    },
    graphCountBlack: {
      main: isDarkMode ? "#363636" : "#363636",
    },
    graphCountGray: {
      main: isDarkMode ? "#929292" : "#929292",
    },
  };

  return createTheme({
    palette: palette,
    typography: themeTypography(),
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'Rockwell';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Rockwell'), local('Rockwell-Regular'), url(${RockwellRegular}) format('ttf');
        }
      `,
      },
    },
  });
};

export { generateTheme };
