import { Checkbox, FormControlLabel, useTheme } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PropTypes from "prop-types";

export const LabelCheckbox = (props) => {
  const { label, disabled, checked, onChange } = props;

  const theme = useTheme();

  return (
    <FormControlLabel
      disabled={disabled}
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          checkedIcon={<CheckBoxIcon color={disabled ? "gray" : "theme"} />}
          icon={
            <CheckBoxOutlineBlankIcon color={disabled ? "gray" : "theme"} />
          }
        />
      }
      label={
        <span
          style={{
            fontSize: 14,
            fontWeight: 400,
            color: disabled ? "gray" : theme.palette.primary.main,
          }}
        >
          {label}
        </span>
      }
    />
  );
};

LabelCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

LabelCheckbox.defaultProps = {
  disabled: false,
};
