import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";

export const StatusCard = (props) => {
  const { summary } = props;

  const theme = useTheme();

  let color = theme.palette.amlStatus.default;
  if (summary.SERVICE_FLAG === "ADVERSEMEDIA_CHK_SEARCH") {
    if (summary.STATUS_CODE === "0") {
      color = theme.palette.amlStatus.yellow;
    } else if (summary.STATUS_CODE === "1") {
      color = theme.palette.amlStatus.green;
    } else if (summary.STATUS_CODE === "2") {
      color = theme.palette.amlStatus.red;
    } else if (summary.STATUS_CODE === "3") {
      color = theme.palette.amlStatus.gray;
    }
  } else {
    if (summary.STATUS_CODE === "1") {
      color = theme.palette.amlStatus.red;
    } else if (summary.STATUS_CODE === "2") {
      color = theme.palette.amlStatus.green;
    } else if (summary.STATUS_CODE === "3") {
      color = theme.palette.amlStatus.orange;
    } else if (summary.STATUS_CODE === "4") {
      color = theme.palette.amlStatus.yellow;
    } else if (summary.STATUS_CODE === "5") {
      color = theme.palette.amlStatus.yellow;
    } else if (summary.STATUS_CODE === "6") {
      color = theme.palette.amlStatus.yellow;
    } else if (summary.STATUS_CODE === "7") {
      color = theme.palette.amlStatus.green;
    }
  }

  let bgColor = theme.palette.amlStatus.default;
  if (summary.COLOR_TAG.toLowerCase() === "red") {
    bgColor = theme.palette.amlStatus.red;
  } else if (summary.COLOR_TAG.toLowerCase() === "green") {
    bgColor = theme.palette.amlStatus.green;
  } else if (summary.COLOR_TAG.toLowerCase() === "yellow") {
    bgColor = theme.palette.amlStatus.orange;
  }

  return (
    <Stack
      direction={"column"}
      sx={{
        borderRadius: 2,
        pt: 2,
        pb: 2,
        width: "100%",
        bgcolor: alpha(bgColor, 0.1),
      }}
    >
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          height: 20,
          pl: 2,
          pr: 2,
        }}
      >
        <Box
          sx={{
            pt: "6px",
            pl: 1,
            pr: 1,
            pb: "6px",
            bgcolor: "white",
            borderRadius: 5,
          }}
        >
          <Typography variant="p1" sx={{ color: color }}>
            {summary.STATUS_DESC}
          </Typography>
        </Box>
        <Typography variant="h2">{`Level: ${summary.LEVEL}`}</Typography>
      </Stack>
      <Divider sx={{ mt: "12px" }} />
      <Stack direction={"column"} sx={{ p: 2, gap: "12px" }}>
        <Grid container direction={"row"} spacing={2}>
          <Grid item xs>
            <Stack direction={"column"} sx={{ gap: "2px" }}>
              <Stack
                direction={"row"}
                sx={{ gap: "2px", alignItems: "center" }}
              >
                <Typography
                  variant="p1"
                  sx={{ color: theme.palette.secondary.main }}
                >
                  Probability :{"  "}
                </Typography>
                <Typography variant="h2">{summary.PROB}</Typography>
              </Stack>
              <Stack
                direction={"row"}
                sx={{ gap: "2px", alignItems: "center" }}
              >
                <Typography
                  variant="p1"
                  sx={{ color: theme.palette.secondary.main }}
                >
                  Count :{"  "}
                </Typography>
                <Typography variant="h2">{summary.COUNT}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item xs>
            <Stack direction={"column"} sx={{ gap: "2px" }}>
              <Stack
                direction={"row"}
                sx={{ gap: "2px", alignItems: "center" }}
              >
                <Typography
                  variant="p1"
                  sx={{ color: theme.palette.secondary.main }}
                >
                  Match Status :{"  "}
                </Typography>
                <Typography variant="h2">{summary.MATCH_STATUS}</Typography>
              </Stack>
              <Stack
                direction={"row"}
                sx={{ gap: "2px", alignItems: "center" }}
              >
                <Typography
                  variant="p1"
                  sx={{ color: theme.palette.secondary.main }}
                >
                  Src Tag :{"  "}
                </Typography>
                <Typography variant="h2">{summary.SRC_TAG}</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};
