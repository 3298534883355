import { FormControlLabel, Radio, useTheme } from "@mui/material";
import PropTypes from "prop-types";

export const LabelRadio = (props) => {
  const { label, value } = props;

  const theme = useTheme();

  return (
    <FormControlLabel
      value={value}
      control={
        <Radio
          sx={{
            color: theme.palette.theme.main,
            "&.Mui-checked": {
              color: theme.palette.theme.main,
            },
          }}
        />
      }
      label={
        <span
          style={{
            fontSize: 14,
            fontWeight: 400,
            color: theme.palette.primary.main,
          }}
        >
          {label}
        </span>
      }
    />
  );
};

LabelRadio.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
