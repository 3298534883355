import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import { PaginationTable } from "./PaginationTable";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks";
import { useEffect, useState, Fragment } from "react";
import LoaderCard from "../cards/LoaderCard";
import NoDataCard from "../cards/NoDataCard";
import { getEditPermissionStatus } from "../../utilities/get-permission";
import { ReminderAlert } from "../alert/ReminderAlert";

export const IDVerificationListTable = (props) => {
  const { userDetails, data } = props;

  const { uid } = useParams();
  const { showToast } = useToast();
  const { logout, adminRole, isAdmin } = useAuth();
  const { api, urls, isLoading } = useAPI();
  const [showVerificationConfirm, setShowVerificationConfirm] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();

  const [requestData, setRequestData] = useState({
    itemsPerPage: 25,
    currentPage: 1,
  });

  const [statusList, setStatusList] = useState([]);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    limit: 0,
    total_count: 0,
  });

  //api
  const getVerificationList = async () => {
    const { itemsPerPage, currentPage } = requestData;
    api
      .get(
        `${urls.idVerificationList}/${uid}?page=${currentPage}&limit=${itemsPerPage}`
      )
      .then((res) => {
        let data = res.data.data;
        setStatusList(data.data);
        setPaginationData(data.pagination);
      })
      .catch(handleApiError);
  };

  const onClickConfirmVerification = () => {
    setShowVerificationConfirm(false);
    api
      .put(urls.unVerifiyCustomer, { uuid: uid })
      .then((res) => {
        showToast("Customer Unverified", "success");
      })
      .catch(handleApiError);
  };

  const verifyDocument = () => {
    api
      .post(`${urls.triggerDocVerification}/${uid}`)
      .then((res) => {
        showToast("Request Completed", "success");
        getVerificationList();
      })
      .catch(handleApiError);
  };

  //error handling
  const handleApiError = (err) => {
    if (err.response?.status === 401) {
      logout();
    } else {
      console.log("err", err);
      showToast(err.response?.data?.message, "error");
    }
  };

  const convertUTCtoLocalDateTime = (utcDateTimeString) => {
    const utcDateTime = new Date(utcDateTimeString);
    return utcDateTime.toLocaleString(undefined, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: true, // specify AM/PM
    });
  };

  //useEffect
  useEffect(() => {
    getVerificationList();
  }, [requestData]);

  const updateRequestData = (newData) => {
    setRequestData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  return (
    <Fragment>
      <Stack
        direction={"column"}
        sx={{
          bgcolor: theme.palette.common.white,
          borderRadius: 3,
          pt: "12px",
          pl: 2,
          pr: 2,
          pb: "12px",
        }}
      >
        {!isLoading &&
          (isAdmin ||
            getEditPermissionStatus(
              adminRole.permissions,
              "Digital Onboarding Customers"
            ) ||
            getEditPermissionStatus(
              adminRole.permissions,
              "Authentication Customers"
            )) && (
            <Box textAlign={"right"}>
              {userDetails?.user_onboarding_type !== "Risk" && (
                <Button
                  variant="outlined"
                  disableElevation
                  color="theme"
                  sx={{
                    height: "100%",
                    fontSize: 16,
                    fontWeight: 700,
                    borderRadius: 2,
                    textTransform: "none",
                    mr: 2,
                  }}
                  onClick={() => setShowVerificationConfirm(true)}
                >
                  Request New Verification
                </Button>
              )}
              <Button
                variant="contained"
                disableElevation
                color="theme"
                sx={{
                  height: "100%",
                  fontSize: 16,
                  fontWeight: 700,
                  borderRadius: 2,
                  textTransform: "none",
                }}
                disabled={isLoading}
                onClick={verifyDocument}
              >
                Verify Document
              </Button>
            </Box>
          )}
        {isLoading && <LoaderCard />}
        {!isLoading && (
          <Fragment>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ height: 70 }}>
                    <TableCell sx={{ py: "12px", width: 120 }}>
                      <Typography variant="h2">No.</Typography>
                    </TableCell>
                    <TableCell sx={{ py: 0 }}>
                      <Typography variant="h2">Date & Time</Typography>
                    </TableCell>
                    <TableCell sx={{ py: 0 }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {statusList.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ py: "12px" }}>
                        <Typography variant="p1">
                          {requestData.itemsPerPage *
                            (paginationData?.page - 1) +
                            index +
                            1}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ py: 0 }}>
                        <Typography variant="p1">
                          {convertUTCtoLocalDateTime(item.created_at)}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ py: 0 }} align="right">
                        <Button
                          variant="contained"
                          disableElevation
                          color="theme"
                          onClick={() =>
                            navigate(
                              `/customers/details/${uid}/document_status/${item.uuid}`,
                              {
                                state: {
                                  full_name: data.full_name,
                                  dob: data.dob,
                                  nationality: data.nationality,
                                },
                              }
                            )
                          }
                          sx={{
                            fontSize: 14,
                            fontWeight: 400,
                            textTransform: "none",
                            maxWidth: "55px",
                            minWidth: "30px",
                            minHeight: "28px",
                            maxHeight: "28px",
                          }}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {!isLoading && statusList.length === 0 && <NoDataCard />}
            <PaginationTable
              page={paginationData?.page ? paginationData.page - 1 : 0}
              onSetPage={(page) => updateRequestData({ currentPage: page + 1 })} //page start with zero
              rowsPerPage={requestData.itemsPerPage}
              onSetRowsPerPage={(count) =>
                updateRequestData({ itemsPerPage: count, currentPage: 1 })
              }
              totalCount={
                paginationData?.total_count ? paginationData.total_count : 0
              }
            />
          </Fragment>
        )}
      </Stack>
      <ReminderAlert
        open={showVerificationConfirm}
        onClose={() => setShowVerificationConfirm(false)}
        onConfirm={onClickConfirmVerification}
        header={"Request New Verification"}
        reminder={"Are you sure, you want to proceed?"}
        closeText={"Cancel"}
        confirmText={"Proceed"}
        buttonColor="theme"
      />
    </Fragment>
  );
};
