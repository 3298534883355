import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";

const NoDataCard = (props) => {
  const { height, title, bgColor } = props;
  const theme = useTheme();
  return (
    <Box
      height={height}
      sx={{
        borderRadius: "16px",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: bgColor,
        display: "flex",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          mb: 2,
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

NoDataCard.propTypes = {
  height: PropTypes.any,
  title: PropTypes.string,
  bgColor: PropTypes.string,
};

NoDataCard.defaultProps = {
  height: "300px",
  title: "No Data Found",
  bgColor: "common.white",
};

export default NoDataCard;
