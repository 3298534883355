import { Button, Divider, Stack, Typography, useTheme } from "@mui/material";
import { LabelTextField } from "../../../components/fields/LabelTextField";
import { Fragment, useState, useEffect } from "react";

export const UnverifiedAccountDetails = (props) => {
  const { title, data } = props;
  const [userData, setUserData] = useState(data);

  const theme = useTheme();

  function formatDate(dateString) {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      const formattedDate = `${day}/${month}/${year}`;
      return formattedDate;
    } else {
      return "";
    }
  }

  return (
    <Fragment>
      <Stack
        direction={"column"}
        sx={{
          bgcolor: theme.palette.common.white,
          borderRadius: 3,
          p: 2,
          gap: 2,
        }}
      >
        <Typography variant="h2" sx={{ color: theme.palette.secondary.main }}>
          {title}
        </Typography>
        <Divider />
        <Stack direction={"column"} sx={{ mt: 1, gap: 3 }}>
          <LabelTextField
            label={userData?.last_name ?? "" !== "" ? "First Name" : "Name"}
            textInput={userData?.full_name}
            onTextChange={() => {}}
            disabled
          />
          {userData?.last_name !== null &&
            (userData?.last_name ?? "") !== "" && (
              <LabelTextField
                label={"Last Name"}
                textInput={userData?.last_name}
                onTextChange={() => {}}
                disabled
              />
            )}
          {userData?.dob !== null && (userData?.dob ?? "") !== "" && (
            <LabelTextField
              label={"Date of Birth"}
              textInput={formatDate(userData?.dob)}
              onTextChange={() => {}}
              disabled
            />
          )}
          {userData?.gender !== null && (userData?.gender ?? "") !== "" && (
            <LabelTextField
              label={"Gender"}
              textInput={userData?.gender}
              onTextChange={() => {}}
              disabled
            />
          )}
          {userData?.nationality !== null &&
            (userData?.nationality ?? "") !== "" && (
              <LabelTextField
                label={"Nationality"}
                textInput={userData?.nationality}
                onTextChange={() => {}}
                disabled
              />
            )}
          {userData?.national_id !== null &&
            (userData?.national_id ?? "") !== "" && (
              <LabelTextField
                label={"National ID No."}
                textInput={userData?.national_id}
                onTextChange={() => {}}
                disabled
              />
            )}
          {userData?.date_of_issue !== null &&
            (userData?.date_of_issue ?? "") !== "" && (
              <LabelTextField
                label={"Issue Date"}
                textInput={formatDate(userData?.date_of_issue)}
                onTextChange={() => {}}
                disabled
              />
            )}
          {userData?.date_of_expiry !== null &&
            (userData?.date_of_expiry ?? "") !== "" && (
              <LabelTextField
                label={"Expiry Date"}
                textInput={formatDate(userData?.date_of_expiry)}
                onTextChange={() => {}}
                disabled
              />
            )}
          {userData?.pin !== null && (userData?.pin ?? "") !== "" && (
            <LabelTextField
              label={"PIN"}
              textInput={userData?.pin}
              onTextChange={() => {}}
              disabled
            />
          )}
          {userData?.address1 !== null && (userData?.address1 ?? "") !== "" && (
            <LabelTextField
              label={"Address"}
              textInput={userData?.address1}
              onTextChange={() => {}}
              disabled
            />
          )}
          {userData?.place_of_birth !== null &&
            (userData?.place_of_birth ?? "") !== "" && (
              <LabelTextField
                label={"Place of Birth"}
                textInput={userData?.place_of_birth}
                onTextChange={() => {}}
                disabled
              />
            )}
          {userData?.place_of_issue !== null &&
            (userData?.place_of_issue ?? "") !== "" && (
              <LabelTextField
                label={"Place of Issue"}
                textInput={userData?.place_of_issue}
                onTextChange={() => {}}
                disabled
              />
            )}
          {userData?.father_name !== null &&
            (userData?.father_name ?? "") !== "" && (
              <LabelTextField
                label={"Father's Name"}
                textInput={userData?.father_name}
                onTextChange={() => {}}
                disabled
              />
            )}
          {userData?.profession !== null &&
            (userData?.profession ?? "") !== "" && (
              <LabelTextField
                label={"Profession"}
                textInput={userData?.profession}
                onTextChange={() => {}}
                disabled
              />
            )}
        </Stack>
      </Stack>
    </Fragment>
  );
};
