import { Stack, Switch, Typography, styled, useTheme } from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 22,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark"
            ? theme.palette.switch.activate
            : theme.palette.switch.activate,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18,
    height: 18,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.switch.deactivate
        : theme.palette.switch.deactivate,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const SwitchLabel = (props) => {
  const { status, onSwitchChange } = props;

  const theme = useTheme();

  const [checked, setChecked] = useState(status);

  const handleSwitchChange = () => {
    onSwitchChange(!checked);
    setChecked(!checked);
  };

  return (
    <Stack direction={"row"} alignItems={"center"} sx={{ gap: 1 }}>
      <IOSSwitch checked={checked} onChange={handleSwitchChange} />
      <Typography
        variant="p1"
        sx={{
          color: checked
            ? theme.palette.switch.activate
            : theme.palette.switch.deactivate,
        }}
      >
        {checked ? "Deactivate" : "Activate"}
      </Typography>
    </Stack>
  );
};

SwitchLabel.propTypes = {
  status: PropTypes.bool.isRequired,
  onSwitchChange: PropTypes.func.isRequired,
};
