import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { StdLabelPasswordTextField } from "../../components/fields/StdLabelPasswordTextField";
import { PasswordValidationCard } from "../../components/cards/PasswordValidationCard";
import {
  hasNumber,
  hasSpecialCharacter,
  hasUpperAndLowerCase,
  isCharacterInRange,
} from "../../utilities/extensions";
import { useState } from "react";

export const SetPasswordScreen = () => {
  const theme = useTheme();

  const [formData, setFormData] = useState({
    password: "",
    confirm_password: "",
    errors: { password: false, confirm_password: false },
  });

  const updateFormData = (key, value) => {
    setFormData((prev) => {
      const curr = { ...prev };
      curr[key] = value;
      curr.errors[key] = false;
      return curr;
    });
  };

  return (
    <Box
      justifyContent={"center"}
      alignItems={"center"}
      display={"flex"}
      sx={{
        width: "100vw",
        height: "100vh",
        bgcolor: theme.palette.background.main,
      }}
    >
      <Stack
        direction={"column"}
        sx={{
          p: 4,
          gap: 2,
          width: 450,
          bgcolor: theme.palette.common.white,
          borderRadius: 3,
        }}
      >
        <Typography variant="h1" textAlign={"center"}>
          Set Password
        </Typography>
        <StdLabelPasswordTextField
          label="Set Password"
          textInput={formData.password}
          onTextChange={(event) =>
            updateFormData("password", event.target.value)
          }
        />
        <Stack direction={"column"} sx={{ mt: 1, gap: 1 }}>
          <PasswordValidationCard
            description="Use 8 to 16 characters."
            selected={isCharacterInRange(formData.password, 8, 16)}
          />
          <PasswordValidationCard
            description="Use upper and lower case characters."
            selected={hasUpperAndLowerCase(formData.password)}
          />
          <PasswordValidationCard
            description="Use 1 or more numbers."
            selected={hasNumber(formData.password)}
          />
          <PasswordValidationCard
            description="Use 1 or more special characters."
            selected={hasSpecialCharacter(formData.password)}
          />
        </Stack>
        <StdLabelPasswordTextField
          label="Re-Enter Password"
          textInput={formData.confirm_password}
          onTextChange={(event) =>
            updateFormData("confirm_password", event.target.value)
          }
        />
        <Button
          variant="contained"
          disableElevation
          color="theme"
          sx={{
            mt: 2,
            width: "100%",
            height: 50,
            fontSize: 16,
            fontWeight: 700,
            textTransform: "none",
            borderRadius: 2,
          }}
        >
          Update
        </Button>
      </Stack>
    </Box>
  );
};
