import { Button, Dialog, Stack, Typography } from "@mui/material";
import { useToast, useAuth } from "../../context";
import { useAPI } from "../../hooks";
import PropTypes from "prop-types";
import { StdLabelPasswordTextField } from "../fields/StdLabelPasswordTextField";
import { PasswordValidationCard } from "../cards/PasswordValidationCard";
import {
  hasNumber,
  hasSpecialCharacter,
  hasUpperAndLowerCase,
  isCharacterInRange,
} from "../../utilities/extensions";
import { useState } from "react";

export const ChangePasswordAlert = (props) => {
  const { open, onClose, onSubmit } = props;

  const { api, urls } = useAPI();
  const { showToast } = useToast();
  const { logout } = useAuth();

  const [formData, setFormData] = useState({
    old_password: "",
    password: "",
    confirm_password: "",
    errors: { old_password: false, password: false, confirm_password: false },
  });
  const [apiCalling, setApiCalling] = useState(false);

  const onSubmitClick = () => {
    let invalid = false;

    if (!formData.old_password) {
      setFormData((prev) => {
        const curr = { ...prev };
        prev.errors.old_password = true;
        return curr;
      });
      invalid = true;
    }

    if (!formData.password) {
      setFormData((prev) => {
        const curr = { ...prev };
        prev.errors.password = true;
        return curr;
      });
      invalid = true;
    }

    if (!formData.confirm_password) {
      setFormData((prev) => {
        const curr = { ...prev };
        prev.errors.confirm_password = true;
        return curr;
      });
      invalid = true;
    }

    if (formData.password !== formData.confirm_password) {
      setFormData((prev) => {
        const curr = { ...prev };
        prev.errors.confirm_password = true;
        return curr;
      });
      invalid = true;
    }

    if (
      !invalid &&
      isCharacterInRange(formData.password, 8, 16) &&
      hasUpperAndLowerCase(formData.password) &&
      hasNumber(formData.password) &&
      hasSpecialCharacter(formData.password)
    ) {
      setApiCalling(true);
      const requestData = {
        old_password: formData.old_password,
        new_password: formData.password,
      };
      api
        .put(urls.changePassword, requestData)
        .then((res) => {
          showToast(res.data.message, "success");
          setApiCalling(false);
          onSubmit();
        })
        .catch(handleApiError);
    }
  };

  //error handling
  const handleApiError = (err) => {
    setApiCalling(false);
    if (err.response?.status === 401) {
      logout();
    } else {
      console.log("err", err);
      showToast(err.response?.data?.message, "error");
    }
  };

  const updateFormData = (key, value) => {
    setFormData((prev) => {
      const curr = { ...prev };
      curr[key] = value;
      curr.errors[key] = false;
      return curr;
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: { sx: { backgroundColor: "rgba(0, 0, 0, 0.2)" } },
      }}
    >
      <Stack
        direction={"column"}
        sx={{ width: 400, pt: 3, pl: 4, pr: 4, pb: 4, gap: 3 }}
      >
        <Typography variant="h1" textAlign={"center"}>
          Change Password
        </Typography>
        <StdLabelPasswordTextField
          label="Current Password"
          textInput={formData.old_password}
          onTextChange={(event) =>
            updateFormData("old_password", event.target.value)
          }
          error={formData.errors.old_password}
          helperText={
            formData.errors.old_password ? "Enter your current password" : null
          }
        />
        <StdLabelPasswordTextField
          label="Set Password"
          textInput={formData.password}
          onTextChange={(event) =>
            updateFormData("password", event.target.value)
          }
          error={formData.errors.password}
          helperText={formData.errors.password ? "Enter your password" : null}
        />
        <Stack direction={"column"} sx={{ mt: 1, gap: 1 }}>
          <PasswordValidationCard
            description="Use 8 to 16 characters."
            selected={isCharacterInRange(formData.password, 8, 16)}
          />
          <PasswordValidationCard
            description="Use upper and lower case characters."
            selected={hasUpperAndLowerCase(formData.password)}
          />
          <PasswordValidationCard
            description="Use 1 or more numbers."
            selected={hasNumber(formData.password)}
          />
          <PasswordValidationCard
            description="Use 1 or more special characters."
            selected={hasSpecialCharacter(formData.password)}
          />
        </Stack>
        <StdLabelPasswordTextField
          label="Re-Enter Password"
          textInput={formData.confirm_password}
          onTextChange={(event) =>
            updateFormData("confirm_password", event.target.value)
          }
          error={formData.errors.confirm_password}
          helperText={
            formData.errors.confirm_password
              ? formData.confirm_password === ""
                ? "Enter your confirm password"
                : "Password & Confirm password should match"
              : null
          }
        />
        <Button
          variant="contained"
          disableElevation
          color="theme"
          onClick={onSubmitClick}
          sx={{
            mt: 2,
            width: "100%",
            height: 50,
            fontSize: 16,
            fontWeight: 700,
            textTransform: "none",
            borderRadius: 2,
          }}
          disabled={apiCalling}
        >
          Update
        </Button>
      </Stack>
    </Dialog>
  );
};

ChangePasswordAlert.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
